import { Injectable } from '@angular/core';

import { FeatureToggleService } from '@wdpr/ra-angular-feature-toggle';

import { AdminService } from '../../admin-service/admin.service';
import { TogglesInterface } from '../../interfaces/toggles.interface';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class ToggleService {
    private toggles: {};
    private cookieName: string = '';

    constructor(
        private featureToggleService: FeatureToggleService,
        private adminService: AdminService,
        private cookieService: CookieService,
    ) {
        this.toggles = {};
    }

    getToggle(toggleName: string) {
        const toggleCookie = this.cookieName === '' ? '' : this.cookieService.get(this.cookieName);

        if (toggleCookie != null && toggleCookie !== '') {
            Object.assign(this.toggles, JSON.parse(toggleCookie));
        }

        return this.toggles[toggleName];
    }

    setToggles(toggles: {}) {
        this.toggles = toggles;
    }

    getToggles() {
        return this.toggles;
    }

    loadToggles(featureSet: string, useAdminSvc?: boolean, toggleName?: string) {
        this.cookieName = toggleName;

        if (useAdminSvc) {
            return this.loadTogglesFromAdminSvc(featureSet);
        }

        return this.loadTogglesFromVendomatic(featureSet);
    }

    loadTogglesFromVendomatic(featureSet: string) {
        return new Promise((resolve) => {
            this.featureToggleService.getToggles(featureSet).subscribe(
                toggles => {
                    this.setToggles(toggles);
                    resolve(toggles);
                },
                () => {
                    resolve({});
                }
            );
        });
    }

    loadTogglesFromAdminSvc(url: string) {
        return new Promise((resolve) => {
            this.adminService.getConfiguration(url).subscribe(
                (toggles: TogglesInterface) => {
                    this.setToggles(toggles);
                    resolve(toggles);
                },
                () => {
                    resolve({});
                }
            );
        });
    }

}
