export const HANDLE_ERRORS_CAST_CONSTANTS = {
    SESSION_MANAGER_URL: 'session/sales/',
    SPECIAL_OFFERS_URL: '/special-offers/',
    SAILING_AVAIL_URL: 'sailings-avail-params',
    MY_ID_LOGIN_URL_DEV: 'https://idp.myid-stg.disney.com/as/authorization.oauth2?' +
        'client_id=TWDC.DCL.CruiseSales.Latest&response_type=token&redirect_uri={redirectURL}&' +
        'nonce=APPLICATION_GENERATED_ONE_TIME_NONCE&scope=openid+profile+email+relationship+address+id',
    MY_ID_LOGIN_URL_PROD: 'https://idp.myid.disney.com/as/authorization.oauth2?' +
        'client_id=TWDC.DCL.CruiseSales.prod&response_type=token&redirect_uri={redirectURL}&' +
        'nonce=APPLICATION_GENERATED_ONE_TIME_NONCE&scope=openid+profile+email+relationship+address+id',
    SESSION_EXPIRED_MODAL: {
        LOGIN_BUTTON_LABEL: 'castSessionModal.loginButton',
        CANCEL_BUTTON_LABEL: 'castSessionModal.cancelButton',
        TITLE: 'castSessionModal.title',
        BODY: 'castSessionModal.body'
    },
    ENV_PROD: 'production',
    ERRORS_CODES: {
        NO_TOKEN: 'CAST_40001',
        INVALID_TOKEN: 'CAST_40002',
        NO_CAST: 'CAST_40003',
        FORBIDDEN: 'CAST_40004',
        ACCEPT_LOGIN: 'CAST_ACCEPT_LOGIN',
        NO_APPLIED_FOR_OFFER: 'DCL_MO_40001',
        CANCEL_LOGIN: 'CAST_CANCEL_LOGIN',
        DATA_NOT_MATCHED: 'CAST_DATA_NOT_MATCHED',
        MISSING_DATA: 'CAST_MISSING_DATA'
    },
    ERRORS_STATUS: {
        FORBIDDEN: 403,
        UNAUTHORIZED: 401
    }
};
