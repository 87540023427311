import { Injectable } from '@angular/core';

import { LOCALE } from './locale.constants';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    constructor() {}

    /**
     * Creates a locale string from both browser's language and region availaible or as a default
     * @returns string Locale. Eg. 'en-us'
     */
    getLocale(): string {
        return (navigator.languages && navigator.languages.length && navigator.languages[0])
        || navigator.language
        || LOCALE.DEFAULT_LOCALE;
    }

}
