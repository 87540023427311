import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { Location } from '@angular/common';

// Prevent stripping of trailing slash
Location.stripTrailingSlash = (url: string) => url;

const routes: Routes = [
  { path: ':lang/', component: AppComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
