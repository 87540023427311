import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter, map, concatMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import * as momentModule from 'moment';

import { CAST_USER_CONSTANTS } from './cast-user.constants';
import { CastMemberData } from '../interfaces/cast-user.interface';
import { CastUserOptions } from './interfaces/cast-user-options.interface';
import { CastUserRestService } from './rest/cast-user-rest.service';
import { CastUserSessionService } from './session/cast-user-session.service';
import { CheckOfferElibilityResponse } from '../interfaces/check-offer-eligibility-response.interface';
import { HandleErrorsCastService } from '../handle-errors/cast/handle-errors.cast.service';
import { HandleErrorsService } from '../handle-errors/main/handle-errors.service';

@Injectable()
export class CastUserService {
    private options: CastUserOptions;
    private moment = momentModule;

    constructor(
        private castUserRestService: CastUserRestService,
        private castUserSessionService: CastUserSessionService,
        private cookieService: CookieService,
        private handleErrorsCastService: HandleErrorsCastService,
        private handleErrorsService: HandleErrorsService,
        private location: Location,
        private router: ActivatedRoute
    ) {}

    /**
     * Call CastUserRestService to ser baseUrl
     * @param options base url and error state
     */
    setOptions(options: CastUserOptions): void {
        this.castUserRestService.enablePavasEndpoints(options.usePavasEndpoints);
        this.castUserRestService.setBaseUrl(options.baseUrl);
        this.options = options;
    }

    /**
     * Check if an user is currently booking a cast offer and is elegible for it,
     *  then save the response in sessionStorage
     * @param promoCode promo code to be validated
     * @param affiliation affiliation associated to the offer
     * @returns Observable that checks user eligibility for an offer
     */
    checkCastPromoCode(promoCode: string, affiliation: string): Observable<boolean> {
        let isElegible = false;

        return this.checkOfferEligibility(promoCode, affiliation)
            .pipe(
                concatMap(response => {
                    if (response.cast || response.stored) {
                        return this.getCastData()
                            .pipe(
                                map((castUser) => {
                                    if (castUser && !castUser.hireDate) {
                                        throw {
                                            status: CAST_USER_CONSTANTS.ERROR_STATUS.FORBIDDEN,
                                            error: {
                                                errorCode: CAST_USER_CONSTANTS.ERROR_CODES.MISSING_DATA
                                            }
                                        };
                                    }

                                    // if cast flag is coming it means the data is coming from service
                                    // and we have to save it in session storage
                                    if (response.cast) {
                                        castUser.affiliation = affiliation;
                                        castUser.promoCode = promoCode;
                                        this.castUserSessionService.saveCastData(castUser);
                                    }
                                    isElegible = true;

                                    return isElegible;
                                })
                            );
                    }

                    this.castUserSessionService.removeOfferData();

                    return of(isElegible);
                }),
                catchError(error => {
                    return this.handleErrorsService.handleServiceError(error, {
                        errorState: this.options.errorState,
                        myIdUrl: this.options.myIdUrl
                    }).pipe(
                        map(() => { throw false; })
                    );
                })
            );
    }

    /**
     * Get cast user data and save it in sessionStorage
     * @returns Observable with cast data
     */
    getCastData(): Observable<CastMemberData> {
        const castUser = this.castUserSessionService.getSessionCastData();
        const checkData = castUser && castUser.checkData;

        if (castUser && !checkData) {
            return of(castUser);
        } else {
            return this.castUserRestService.getCastData()
                .pipe(
                    map((response: CastMemberData) => {
                        if (checkData) {
                            if (this.isSameCastUser(castUser, response)) {
                                delete castUser.checkData;
                                response = castUser;
                            } else {
                                this.castUserSessionService.saveCastData(response);

                                throw {
                                    status: CAST_USER_CONSTANTS.ERROR_STATUS.FORBIDDEN,
                                    error: {
                                        errorCode: CAST_USER_CONSTANTS.ERROR_CODES.DATA_NOT_MATCHED
                                    }
                                };
                            }
                        }

                        this.castUserSessionService.saveCastData(response);

                        return response;
                    })
                );
        }
    }

    /**
     * Check if an user is elegible for an offer
     * @param promoCode promo code to be validated
     * @returns Observable that checks user eligibility for an offer
     * @param affiliation affiliation associated to the offer
     */
    checkOfferEligibility(promoCode: string, affiliation: string): Observable<CheckOfferElibilityResponse> {
        const castUser = this.castUserSessionService.getSessionCastData();

        if (promoCode && affiliation && affiliation.indexOf(CAST_USER_CONSTANTS.CAST_AFFILIATION_SUFFIX) >= 0) {
            if (castUser && promoCode === castUser.promoCode) {
                return of({
                    stored: true
                });
            } else {
                return this.castUserRestService.checkOfferEligibility(promoCode);
            }
        } else {
            return of({
                cast: false
            });
        }
    }

    /**
     * Determine if this is a cast user in order to add X-Disney-Internal-Is-Cast
     * @returns {boolean} returns true if the cast session data is both present and the affiliation includes _CAST
     */
    isCastUser(): boolean {
        const castUser: CastMemberData = this.castUserSessionService.getSessionCastData();

        return !!(castUser && castUser.affiliation &&
            castUser.affiliation.endsWith(CAST_USER_CONSTANTS.CAST_AFFILIATION_SUFFIX));
    }

    /**
     * Extract and remove access token from URL to save it as a acookie
     */
    initProccessMyIdData(): void {
        let castUser: CastMemberData;

        this.router.fragment.pipe(
            filter(fragment => fragment && fragment.indexOf(CAST_USER_CONSTANTS.CAST_COOKIE) >= 0),
            map(fragment => {
                const params = new URLSearchParams(fragment);

                return {
                    [CAST_USER_CONSTANTS.CAST_COOKIE]: params.get(CAST_USER_CONSTANTS.CAST_COOKIE),
                    [CAST_USER_CONSTANTS.EXPIRES_COOKIE]: params.get(CAST_USER_CONSTANTS.EXPIRES_COOKIE),
                };
            }),
        ).subscribe(params => {
            if (params[CAST_USER_CONSTANTS.CAST_COOKIE]) {
                const expirationTime = this.moment().add(
                    parseInt(params[CAST_USER_CONSTANTS.EXPIRES_COOKIE], 10) ||
                    CAST_USER_CONSTANTS.DEFAULT_TIME, 'seconds'
                ).toDate();

                castUser = this.castUserSessionService.getSessionCastData();

                this.cookieService.set(
                    CAST_USER_CONSTANTS.CAST_COOKIE,
                    params[CAST_USER_CONSTANTS.CAST_COOKIE],
                    expirationTime,
                    CAST_USER_CONSTANTS.PATH_COOKIE
                );

                if (castUser) {
                    castUser.checkData = true;
                    this.castUserSessionService.saveCastData(castUser);
                }

                this.location.replaceState(this.location.path(false));
            }
        });
    }

    /**
     * Wrapper handle cast error to send defined options
     * @param statusCode status code
     * @param errorCode error code that defines what kind error is coming
     * @returns Observable error after error has been handled
     */
    handleCastErrorWrapper(statusCode: number, errorCode: string): Observable<boolean> {
        return this.handleErrorsCastService.handleCastError(statusCode, errorCode, {
            errorState: this.options.errorState,
            myIdUrl: this.options.myIdUrl
        }).pipe(
            map(() => { throw false; })
        );
    }

    /**
     * Check old cast data stored against the new one coming from service to check if they matched
     * @param oldData
     * @param newData
     * @returns true if both data is the same, false otherwise
     */
    private isSameCastUser(oldData: CastMemberData, newData: CastMemberData): boolean {
        return oldData && newData &&
            oldData.employeeNumber &&
            newData.employeeNumber &&
            oldData.employeeNumber === newData.employeeNumber;
    }
}
