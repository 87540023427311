import { Directive, HostListener, Input } from '@angular/core';

import { DCLAnalyticsService } from '../services/analytics/analytics.service';

@Directive({
    selector: '[dclTrackLink]'
})
export class TrackLinkDirective {
    @Input() dclTrackLink: string;
    @Input() modelProps: object;

    constructor(private analyticsService: DCLAnalyticsService) { }

    @HostListener('click')
    onClick() {
        this.analyticsService.trackLink(this.dclTrackLink, this.modelProps);
    }
}
