import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    constructor() {}

    /**
     * @param item item name
     * @param shouldRemove Flag to determine if it should be removed from storage after retrieving value
     * @returns parsed sessionStorage data or null value
     */
    getValue<T>(item: string, shouldRemove = false): T | null {
        const data = this.getItem(item);

        if (data && shouldRemove) {
            this.removeItem(item);
        }

        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    }

    /**
     * @param item item name
     * @param value object value stored as string
     */
    setValue<T>(item: string, value: T): void {
        this.setItem(item, JSON.stringify(value));
    }

    /**
     * Returns the session storage value
     * @param key The session storage key
     */
    getItem(key: string) {
        return sessionStorage.getItem(key);
    }

    /**
     * Persists and item in the session storage
     * @param key The session storage key
     * @param value The session storage value
     */
    setItem(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    /**
     * Removes an item from the session storage
     * @param key The session storage key
     */
    removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }
}
