import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, first, take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, Observable, throwError } from 'rxjs';
import { get as getValue } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import {
  AvailabilityBodyParam,
  CruiseTile,
  FeaturedCruisesActionTileResponseEntity,
  FeaturedCruiseService,
  FeaturedCruisesResponseEntity,
  TileData } from '@wdpr/dcl-ui-components-library';
import { ANALYTICS_CONSTANTS } from '@app/services/analytics/analytics.constants';
import { CUSTOM_APP_IMG_PLACEHOLDER } from '@app/app.constants';
import { DEFAULT_APP_VALUES, ENDPOINTS } from '@app/app.constants';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';


@Component({
    selector: 'pixie-featured-product',
    templateUrl: './featured-product.component.html',
    styleUrls: ['./featured-product.component.scss'],
})

export class FeaturedProductComponent implements OnInit {
  availProductsBodyParams: AvailabilityBodyParam = {
    affiliations: [],
    filters: [],
  };
  cruisesTiles: CruiseTile;
  ctas: FeaturedCruisesActionTileResponseEntity[];
  customPlaceholder = CUSTOM_APP_IMG_PLACEHOLDER;
  featuresEndpoint = '/dcl-cruise-101-webapi/product-availability/available-features/';
  isActiveFeaturedCruiseTiles: boolean;
  responseData: FeaturedCruisesResponseEntity;
  buttonText = 'Explore All Cruises';
  featuredCruisesQuery;
  geolocation;
  isAnalyticsPageView = false;
  region;
  trackLinksBeforePageView = [];
  trackSeenModelProps: string;
  webApiUrl = '/dcl-apps-productavail-vas';

  constructor(
    private cookieService: CookieService,
    private featuredCruiseService: FeaturedCruiseService,
    private homepageAnalyticsService: HomepageAnalyticsService,
    private httpClient: HttpClient,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getGeolocationCookie();
    this.getFinalFeaturedCruisesTiles();
  }

  getGeolocationCookie() {
    const cookie = this.cookieService.get('GEOLOCATION_jar');
    if (cookie) {
      this.geolocation = cookie['region'];
      if (/^usa?$/i.test(cookie['countryisocode'])) {
        this.region = 'en_US';
      }
    }

    return cookie;
  }

  getFeaturedCruisesTiles(params: AvailabilityBodyParam): Observable<CruiseTile> {
    const featuredCruisesQuery = this.getFeaturedCruisesData(params);

    const featuredCruisesTilesQueries: Array<Observable<string | FeaturedCruisesResponseEntity>> = [
      this.translate.get('cruiseDestinationList.featured.priceStartFromCTA').pipe(first()),
        featuredCruisesQuery.pipe(first())
    ];

    return forkJoin(featuredCruisesTilesQueries).pipe(
        map((responses: [string, FeaturedCruisesResponseEntity]): CruiseTile => {
            const [priceStartFromTranslationValue, rawResponse] = responses;
            const adaptedResponse: CruiseTile = {
                title: (rawResponse && rawResponse.title) || '',
                tiles: rawResponse && rawResponse.ctas
                    ? rawResponse.ctas.map((rawTile: FeaturedCruisesActionTileResponseEntity) =>
                        this.featuredCruiseService.adaptFeaturedCruiseTile(
                            rawTile,
                            priceStartFromTranslationValue
                        )
                    )
                    : null
            };

            return adaptedResponse;
        }),
        catchError((error: HttpErrorResponse) => {
            return this.handleCruiseListingServiceError(error);
        })
    );
  }

  getFeaturedCruisesData(params: AvailabilityBodyParam): Observable<FeaturedCruisesResponseEntity> {
    const requestBody: AvailabilityBodyParam = {
        ...params,
        affiliations: params.affiliations,
        avoidPrice: true,
        currency: DEFAULT_APP_VALUES.currency,
        filters: params.filters || [],
        geolocation: this.geolocation || 'FL',
        partyMix: [{'accessible': false, 'adultCount': 2, 'childCount': 0, 'nonAdultAges': [], 'partyMixId': '0'}],
        region: this.region || 'en_US',
        storeId: DEFAULT_APP_VALUES.storeId,
    };

    delete requestBody.page;
    delete requestBody.pageHistory;
    delete requestBody.includeAdvancedBookingPrices;

    return this.httpClient.post<FeaturedCruisesResponseEntity>(`${this.webApiUrl}${ENDPOINTS.availableFeatures}`, requestBody);
  }

  /**
   * Retrieves Cruises Tiles from service
  */
  getFinalFeaturedCruisesTiles(): void {
    this.getFeaturedCruisesTiles(this.availProductsBodyParams)
        .pipe(take(1))
        .subscribe(
            (cruisesTiles: CruiseTile) => {
                this.cruisesTiles = cruisesTiles;
                this.setAnalyticsImpressionEventModel();
                this.isActiveFeaturedCruiseTiles = true;

                return cruisesTiles;
            }
        );
  }

  /**
   * links to respective tile detail page
   * @params tileData - Data from cruise tile selected
  */
  onFeaturedCruiseSelected(tileData: TileData): string {
    if (getValue(tileData, 'actions')) {
      let urlFriendlyIds = '';

      for (let i = 0; i < tileData.actions.length; i++ ) {
        const id = tileData.actions[i].urlFriendlyId;

        if (id) {
          if (i === 0) {
            urlFriendlyIds += id;
          } else {
            urlFriendlyIds += ',' + id;
          }
        }
      }

      this.trackAnalyticsClickEvent(tileData);

      if (urlFriendlyIds.length >= 1) {
        window.open('/cruises-destinations/list/#' + urlFriendlyIds, '_self');
      } else {
        window.open('/cruises-destinations/list', '_self');
      }

      return urlFriendlyIds;
    }
  }

  /**
     * Sets the model of ImpressionEvent for the trackSeen directive for analytics tracking
     */
  setAnalyticsImpressionEventModel() {
    this.trackSeenModelProps = JSON.stringify({
      linkId: ANALYTICS_CONSTANTS.FEATURED_CRUISES.IMPRESSION_LINK_ID,
      events:  ANALYTICS_CONSTANTS.FEATURED_CRUISES.IMPRESSION_EVENT,
      pageVariant: ANALYTICS_CONSTANTS.PAGE_VARIANT
    });
  }

  /**
     * Sends the model for ClickEvent to the analytics framework to be tracker
     * @params selectedTile - Data from the selected cruise tile
     */
  trackAnalyticsClickEvent(selectedTile: TileData) {
    const selectedTileNumber = this.cruisesTiles.tiles.indexOf(
      this.cruisesTiles.tiles.find(tile => tile.actions === selectedTile.actions)
    ) + 1;
    const moduleLinkId = `${ANALYTICS_CONSTANTS.FEATURED_CRUISES.MODULE_ID}`
        + `_${this.cruisesTiles.tiles.length}-FeaturedCruises-${selectedTileNumber}_Link`;

    const modelProps = {
      linkId: ANALYTICS_CONSTANTS.FEATURED_CRUISES.CLICK_LINK_ID,
      events: [ANALYTICS_CONSTANTS.FEATURED_CRUISES.CLICK_EVENT],
      pageVariant: ANALYTICS_CONSTANTS.PAGE_VARIANT,
      promoImpressionsList: `${ANALYTICS_CONSTANTS.FEATURED_CRUISES.CLICK_PARTIAL_PROMO_IMPRESSIONS_LIST}`
          + `_${this.cruisesTiles.tiles.length}`
          + `_${selectedTileNumber}`,
      facetsProgress: this.getAnalyticsTileFacetsProgress(selectedTile)
    };

    this.homepageAnalyticsService.trackLink(moduleLinkId);
    this.homepageAnalyticsService.trackLink(modelProps.linkId, modelProps);
  }

  /**
     * Returns an array of objects formatted for analytics facetsProgress based on the actions of a cruise tile
     * @params tile - Tile from which to map the actions list
     */
  getAnalyticsTileFacetsProgress(tile: TileData) {
    return tile.actions.map(tileAction => ({name: `${tileAction.type}:${tileAction.filterValue.split(';')[0]}`}));
  }

  /**
   * Sends Analytics Tracklink when Explore All Cruises button is clicked
   */
  onExploreAllCruisesSelected(): void {
    const analyticsData = {
      linkId: ANALYTICS_CONSTANTS.EXPLORE_ALL_LINK_ID,
      pageVariant: ANALYTICS_CONSTANTS.PAGE_VARIANT
    };

    this.homepageAnalyticsService.trackLink(analyticsData.linkId, analyticsData);
  }

  /**
     * Handles the http response for cruise listing service errors and rethrows an error of type CruiseListingServiceError
     * @param error The http error response
     * @returns The cruise service listing error including the initial http response
     * plus any additional handling info.
     */
  private handleCruiseListingServiceError(error: HttpErrorResponse) {
      const errorCode = getValue(error, 'error.errorCode') || getValue(error, 'error.message.errorCode');
      const cruiseListingServiceError = {
          httpErrorResponse: error,
          isCastError: false
      };

      return throwError(cruiseListingServiceError);
  }

}
