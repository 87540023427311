import { Component, OnInit, Input } from '@angular/core';
import { spinnerContentInAnimation } from './loading-content.animation';

@Component({
    selector: 'dcl-loading-content',
    templateUrl: './loading-content.component.html',
    styleUrls: ['./loading-content.component.scss'],
    animations: [spinnerContentInAnimation]
})
export class LoadingContentComponent implements OnInit {
    @Input() disabledExitOutAnimation: boolean = true;
    @Input() showBackdrop: boolean = false;
    @Input() showSpinner: boolean = false;
    @Input() spinnerAriaLabel: string;
    @Input() spinnerLabel: string;
    @Input() spinnerLabelAlign: string;
    @Input() spinnerSize: string;
    @Input() useWavingSpinner: boolean;
    @Input() spinnerCustomClass: string = '';

    constructor() { }

    ngOnInit() { }
}
