import { _throw } from 'rxjs/observable/throw';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { empty } from 'rxjs/observable/empty';
import { LoggerService } from '@wdpr/ra-angular-logger';

import { AgencyAuthToken } from '../interfaces/agency-auth-token.interface';
import { AgencyLookup } from '../interfaces/agency-lookup.interface';
import { TRAVEL_AGENT_CONSTANTS } from '../travel-agent.constants';
import { TravelAgentRestService } from '../rest/travel-agent-rest.service';
import { TravelAgentService } from '../travel-agent.service';
import { Share } from '../../decorators/share.decorator';
import { WindowRef } from '../../window-ref/window-ref.service';
import { of } from 'rxjs';

@Injectable()
export class TASessionTimeOutService {
    private constants = TRAVEL_AGENT_CONSTANTS;

    constructor(
        private cookieService: CookieService,
        private travelAgentService: TravelAgentService,
        private travelAgentRestService: TravelAgentRestService,
        private logger: LoggerService,
        private windowRef: WindowRef
    ) { }

    /**
     * Getter that defines if the the Travel Agent Session Time To Live is alive
     * based in the Travel-Agent-Session-TL value
     * @returns boolean returned by isTASessionAlive using the SESSION_TL as criteria
     */
    get isSessionTTLAlive(): boolean {
        const sessionTTL = this.cookieService.get(this.constants.COOKIES.SESSION_TTL);

        return this.isTASessionAlive(sessionTTL);
    }

    /**
     * Getter that defines if the OAuth Token is expired or not
     * @returns boolean returned by isAuthTokenAlive using TOKEN_START_TIME and TOKEN_EXPIRATION
     * as criteria
     */
    get isOAuthTokenAlive(): boolean {
        const authTokenStartTime = Number(this.cookieService.get(this.constants.COOKIES.TOKEN_START_TIME));
        const authTokenExpirationTime = Number(this.cookieService.get(this.constants.COOKIES.TOKEN_EXPIRATION));

        return this.isAuthTokenAlive(authTokenStartTime, authTokenExpirationTime);
    }

    /** Check if Travel-Agent-Session-Time is still alive
     * @param SESSION_TTL Travel-Agent-Session-Time
     * @returns Validation TASessionAlive result
     */
    isTASessionAlive(sessionTTL: string): boolean {
        const currentTime = new Date();

        return currentTime.valueOf() < Number(sessionTTL);
    }

    /**
     * Check if TAuthToken is still alive
     * @param authTokenStartTime timestamp of the authToken initialization time
     * @param authTokenExpirationTime seconds of the authToken expiration time (example: 3600)
     * @returns Validation AuthTokenAlive result
     */
    isAuthTokenAlive(authTokenStartTime: number, authTokenExpirationTime: number): boolean {
        const currentTime = new Date();
        const timeAuthTokenExpiration = new Date(authTokenStartTime);

        timeAuthTokenExpiration.setSeconds(timeAuthTokenExpiration.getSeconds() + authTokenExpirationTime);

        return currentTime.valueOf() < timeAuthTokenExpiration.valueOf();
    }

    /**
     * Restart the session time cookie
     */
    restartTravelAgentSessionTime(): void {
        const cookieConstants = this.constants.COOKIES;
        const { path, domain } = cookieConstants.COOKIE_OPTIONS;
        const expires = new Date();

        expires.setMinutes(expires.getMinutes() + this.constants.SESSION_MIN);
        this.cookieService.delete(cookieConstants.SESSION_TTL, path, domain);
        this.cookieService.set(cookieConstants.SESSION_TTL, String(expires.getTime()), expires, path, domain);
    }

    /**
     * Refresh TA data cookies
     * @param ticketData travel agent info, it is stored on TICKET_DATA_JAR cookie
     * @returns Observable for agency refresh cookies process
     */
    agencyRefresh(ticketData: string): Observable<null> {
        let taInfo: { phone: string; partnerId: string };

        try {
            taInfo = JSON.parse(ticketData);

            return this.travelAgentRestService.getAuthToken().pipe(
                tap((resp: AgencyAuthToken) => this.travelAgentService.storeAuthToken(resp)),
                concatMap(() =>
                    this.travelAgentRestService
                        .agencyLookup({
                            phoneNumber: taInfo.phone,
                            affiliationId: taInfo.partnerId,
                        }).pipe(tap((resp: AgencyLookup) => this.travelAgentService.storeSessionGuid(resp)))
                ),
                catchError((error) => _throw(error)),
                concatMap((resp) => empty())
            );
        } catch (error) {
            this.logger.log(error);

            return _throw(error);
        }
    }

    /**
     * Function that provides the necessary HTTP requests when it is needed to
     * refresh the OAuth Token.
     * This function it is decorated with Share so it can be listened by multiple
     * subscribers with an unique observable and helps to avoid innecessary requests
     * @returns Observable with the required requests
     */
    @Share()
    refreshOAuthToken(): Observable<Object> {
        const ticketData = this.cookieService.get(this.constants.COOKIES.TICKET_DATA);
        const defaultErrorMsg = { redirect: true };
        let authToken$: Observable<AgencyAuthToken>;
        let travelAgentInfo: { phone: string; partnerId: string };
        let agencyLookup$: Observable<AgencyLookup>;
        let result$: Observable<Object>;

        try {
            travelAgentInfo = JSON.parse(ticketData);
            authToken$ = this.travelAgentRestService.getAuthToken()
                .pipe(
                    tap((response) => this.travelAgentService.storeAuthToken(response))
                );
            agencyLookup$ = this.travelAgentRestService.agencyLookup({
                phoneNumber: travelAgentInfo.phone,
                affiliationId: travelAgentInfo.partnerId
            }).pipe(
                tap(response => this.travelAgentService.storeSessionGuid(response))
            );
            result$ = authToken$.pipe(
                concatMap(response => {
                    return agencyLookup$;
                }),
                catchError(error => {
                    this.logger.log(error);

                    return of(defaultErrorMsg);
                })
            );
        } catch (error) {
            this.logger.log(error);

            result$ = of(defaultErrorMsg);
        }

        return result$;
    }

    /**
     * Check if the current session of the ta is timeout
     * @returns Promise SessionTimeOut result
     */
    isSessionTimeOut(): void {
        const cookieConstants = this.constants.COOKIES;
        const sessionTTL = this.cookieService.get(cookieConstants.SESSION_TTL);
        const authTokenStartTime = Number(this.cookieService.get(cookieConstants.TOKEN_START_TIME));
        const authTokenExpirationTime = Number(this.cookieService.get(cookieConstants.TOKEN_EXPIRATION));
        const ticketData = this.cookieService.get(cookieConstants.TICKET_DATA);

        if (this.isTASessionAlive(sessionTTL)) { // Check if the travel agent session time is valid
            // Check if the auth token time is expired and refresh token
            if (!this.isAuthTokenAlive(authTokenStartTime, authTokenExpirationTime)) {
                this.agencyRefresh(ticketData).toPromise()
                    .then(() => false)
                    .catch((error) => this.redirectToLogout());
            }

            this.restartTravelAgentSessionTime();
        } else {
            // If both (travel agent session and auth token) expires redirect to logout page
            this.redirectToLogout();
        }
    }

    redirectToLogout() {
        this.windowRef.nativeWindow.location.replace(`${this.constants.ENDPOINTS.LOGOUT}`);
    }

}
