<div class="dcl-loading-content" [@.disabled]="disabledExitOutAnimation">
    <dcl-loading-spinner
        class="loading-spinner"
        [ngClass]="spinnerCustomClass"
        [ariaLabel]="spinnerAriaLabel"
        [size]="spinnerSize"
        [label]="spinnerLabel"
        [labelAlign]="spinnerLabelAlign"
        [showSpinner]="showSpinner"
        [showBackdrop]="showBackdrop"
        [useWavingSpinner]="useWavingSpinner"
        aria-live="assertive"
        aria-atomic="true">
    </dcl-loading-spinner>
    <div [@spinnerContentIn]
        *ngIf="!showSpinner">
        <ng-content></ng-content>
    </div>
</div>
