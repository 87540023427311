export const defaultConfig = {
    ENVIRONMENT_CONSTANT: {
        NG_DEBUG_MODE_ENABLED: true,
        NG_DEBUG_LOG_ENABLED: true
    },
    webApiUrl: 'https://latest.disneycruise.disney.go.com/dcl-cruise-101-webapi/sailing-res/',
    baseUrl: 'https://latest.disneycruise.disney.go.com',
    fontFace: {
        cssUrl: 'https://secure.cdn1.wdpromedia.com/media/pep/live/media/site/css/f11876-fontFace.css',
        jsUrl: 'https://secure.cdn1.wdpromedia.com/media/pep/live/media/site/js/dfd173-fontFaceObserverBundle.min.js'
    },
    siteId: 'dcl',
    syndicatedLayout: {
        baseUrl: 'https://latest.disneycruise.disney.go.com',
        header: {
            path: '/syndicated/content/header',
            siteConfigs: {
                dcl: {
                    version: 3
                }
            }
        },
        footer: {
            path: '/syndicated/content/footer',
            legalPath: '/syndicated/content/legal',
            siteConfigs: {
                dcl: {
                    version: 3
                }
            }
        }
    },
    durationIsLoggedInCookie: 5,
    toggles: {
        dataFromWebApi: true
    }
};
