import { Injectable } from '@angular/core';
import { WindowSizeService } from '@app/shared/services/window-size/window-size.service';
import { WindowSizeEnum } from '@app/shared/services/window-size/window-size.enum';

@Injectable({
    providedIn: 'root'
})
export class RedirectionService {
    constructor(private windowSizeService: WindowSizeService) {}

    redirectToUrl(event, isOnFullscreen: boolean, getCtaUrl: () => string): boolean {
        if (!isOnFullscreen) {
            // avoid redirection if it's media-engine autoplay play/pause button
            if (!event.target.classList?.contains('me-video-play-pause')) {
                window.location.href = getCtaUrl();
            }

            return false;
        } else {
            // If the user is on fullscreen, we don't want to redirect them to the CTA URL
            // me-fullscreen-exit is the class that is added to the media-engine close fullscreen button
            return !event.target.classList?.contains('me-fullscreen-exit');
        }
    }

    getCtaUrl(ctasMobile: object[], ctasDesktop: object[]): string {
        if (this.windowSizeService.getDevice() === WindowSizeEnum.Mobile) {
            return ctasMobile[1]['ctaURL'];
        } else {
            return ctasDesktop[1]['ctaURL'];
        }
    }
}
