import { Component, OnInit, Input } from '@angular/core';

import { spinnerExitOutAnimation } from './loading-spinner.animation';

@Component({
    selector: 'dcl-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    animations: [spinnerExitOutAnimation]
})
export class LoadingSpinnerComponent implements OnInit {
    /**
     * Label to be read with screen readers
     * Role alert when is needed
     */
    @Input() alertRole: boolean;
    @Input() ariaLabel: string;
    @Input() ariaLabelHidden: boolean = true;
    /**
     * Spinner's size, it is large size by default.
     * it receives small or medium
     */
    @Input() size: string;
    /**
     * Only use when exit out animation is needed,
     * otherwise use ngIf directly where the component is used
     */
    @Input() showSpinner: boolean = true;
    @Input() showBackdrop: boolean = false;
    @Input() label: string;
    @Input() labelAlign: string;
    @Input() useWavingSpinner: boolean = false;

    constructor() { }

    ngOnInit() {
        this.ariaLabel = this.ariaLabel || this.label;
    }
}
