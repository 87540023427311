import { OptionItem } from './../../options-groups/interfaces/option-item.interface';
import { OptionsGroup } from './../../options-groups/interfaces/options-group.interface';
import { QUICK_QUOTE_CONSTANTS } from './../quick-quote.constants';
import { RawFilter } from './../interfaces/raw-filter.interface';

const constants = QUICK_QUOTE_CONSTANTS;
/**
 * this function gets a RawFilter data when its type is 'more-filter'
 * in order to map and save it like a optionsGroup object
 *
 *  Example incoming Raw filter data:
 *     'halloween;filterType=theme': {
 *       name: 'Halloween on the High Seas',
 *       id: 'halloween;filterType=theme',
 *       type: 'theme',
 *       enabled: [
 *           'newyork;filterType=city',
 *           'portcanaveral;filterType=city',
 *           'sandiego;filterType=city',
 *           '2020-09;filterType=date',
 *           '2020-10;filterType=date',
 *           'bahamascruises;filterType=destination',
 *           'bermudacruises;filterType=destination',
 *           'canadacruises;filterType=destination',
 *           'caribbeancruises;filterType=destination',
 *           'mexicocruises;filterType=destination',
 *           'disneycastawaycay;filterType=island',
 *           'nights_1_to_3;filterType=night',
 *           'nights_4;filterType=night',
 *           'nights_5_to_6;filterType=night',
 *           'nights_7;filterType=night',
 *           'disneydream;filterType=ship',
 *           'disneyfantasy;filterType=ship',
 *           'disneymagic;filterType=ship',
 *           'disneywonder;filterType=ship'
 *       ]
 *   }
 *
 * Example output data
 * {
 *   id: 'halloween;filterType=theme',
 *   labelKey: 'quickQuote.filterLabels.theme',
 *   renderPosition: 1,
 *   type: 'theme',
 *   options: [{
 *       disabled: false,
 *       id: 'halloween;filterType=theme',
 *       label: 'Halloween on the High Seas',
 *       selected: false
 *   }]
 * }
 *
 * @param filter Raw filter data
 * @param array this is the array where we will set the data already processed
 */
export function moreFiltersMapper(rawFilter: RawFilter, array: OptionsGroup[]): void {
    if (rawFilter && rawFilter.type) {
        const filter = array.find((option: OptionsGroup) => option && option.type === rawFilter.type);
        const rawFilterName = getSpecialtyName(rawFilter);
        const filterOption: OptionItem = {
            label: rawFilterName,
            selected: false,
            disabled: false,
            id: rawFilter.id,
            urlFriendlyId: rawFilter.urlFriendlyId
        };

        if (filter) {
            filter.options.push(filterOption);
        } else {
            const optionsGroups = {
                type: rawFilter.type,
                labelKey: constants.filters['more-filters'].wrapperLabel[rawFilter.type],
                options: [],
                id: rawFilter.id,
                hiddenGroup: rawFilter.id.indexOf(
                    `filterType=${constants.filters['more-filters'].groupKey.newItineraries}`
                ) !== -1
            };

            optionsGroups.options.push(filterOption);
            array.push(optionsGroups);

            array.sort((option1: OptionsGroup, option2: OptionsGroup) => {
                const position1 = constants.filters['more-filters'].positionDisplayed[option1.type];
                const position2 = constants.filters['more-filters'].positionDisplayed[option2.type];

                if (position1 !== undefined && position2 !== undefined) {
                    return position1 > position2 ? 1 : -1;
                } else {
                    return -1;
                }
            });
        }
    }
}

/**
 * This function receives the RawFilterData object.
 * Then takes its ID which is used to get the value from the constant's file and finally,
 * returns the translation key, name or id associated to the provided RawFilter data.
 *
 * @param rawFilter is the the RawFilterData
 * @returns with the value from the constant values for specialty names.
 */
export function getSpecialtyName(rawFilter: RawFilter): string {
    const extractedID = rawFilter.id.substring(0, rawFilter.id.indexOf(';'));

    if (isDynamicFilter(rawFilter.type)) {
        return constants.translationKeys.dynamicFilter
            .replace('{{filterType}}', rawFilter.type)
            .replace('{{key}}', extractedID);
    }

    return constants.specialtyNames[extractedID] || rawFilter.name || extractedID;
}

/**
 * check if a filter belongs to a dynamic module filters
 * @returns true if the value is founded
 */
function isDynamicFilter(filterType: string): boolean {
    return !!constants.allowedDynamicFilterId[filterType];
}
