import { Component, Input, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { get } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';

@Component({ template: '' })
export abstract class StoryAbstractComponent implements OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('site-id') siteId;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input() data;
  storyData: any = {
    sectionHeader: {},
    blockGroups: [],
    dynamicStyles: null
  };
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('story-order') storyOrderingPreset;
  isMobile = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('desktop-mobile') desktopOrMobile;

  constructor(protected sanitizer: DomSanitizer, protected cookieService: CookieService) {
    this.cookieService = cookieService;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && this.siteId && this.desktopOrMobile) {
      this.init();
    }
  }

  init() {
    this.storyData = {
      id: this.data.generalPurposeStrings.storyName,
      order: this.data.generalPurposeStrings.order,
      templateName: this.data.generalPurposeStrings.template,
      profile: this.data.generalPurposeStrings.profile,
      affiliation: this.data.generalPurposeStrings.affiliation,
      blockGroups: this.getTemplateBlockGroups(this.data),
      sectionHeader: this.getStorySectionHeader(this.data),
      // hideIfCastawayClubMember: this.data.generalPurposeStrings.hideIfCastawayClubMember && guestIsCastawayClubMember
    };
  }

  abstract getTemplateBlockGroups(storyData);

  /*
   * Gets story section header value from story ordering preset (if p13n or story
   * ordering preset cookie set), otherwise defaulting to the producer-specified order
   * from the CMS.
   */
  getStorySectionHeader(storyData) {
    let storySectionHeader = null;

    // TODO: this first case can probably be removed
    if (this.storyOrderingPreset &&
      this.storyOrderingPreset[storyData.generalPurposeStrings.storyName] &&
      this.storyOrderingPreset[storyData.generalPurposeStrings.storyName].sectionHeader) {
      // StoryOrderingPreset
      storySectionHeader =
        this.storyOrderingPreset[storyData.generalPurposeStrings.storyName].sectionHeader;
    } else if (!this.isMobile &&
      storyData.generalPurposeStrings.sectionHeaderLabel &&
      storyData.generalPurposeStrings.sectionHeaderAlignment) {
      // Desktop CMS
      storySectionHeader = {
        'label': storyData.generalPurposeStrings.sectionHeaderLabel,
        'alignment': storyData.generalPurposeStrings.sectionHeaderAlignment
      };
    } else if (this.isMobile &&
      storyData.generalPurposeStrings.mobileSectionHeaderLabel &&
      storyData.generalPurposeStrings.mobileSectionHeaderAlignment) {
      // Mobile CMS
      storySectionHeader = {
        'label': storyData.generalPurposeStrings.mobileSectionHeaderLabel,
        'alignment': storyData.generalPurposeStrings.mobileSectionHeaderAlignment
      };
    }

    return storySectionHeader;
  }



  // Creates linkId for scrolling on page
  createAnalyticsTrackSeen(storyData, index, analyticsLinkId?) {
    let linkIdCreator = this.siteId.toUpperCase() +
      '_' + 'Module' +
      '_' + storyData.generalPurposeStrings.order +
      '_' + storyData.generalPurposeStrings.storyName +
      '_' + storyData.generalPurposeStrings.template +
      '_' + index;
    if (analyticsLinkId) {
      linkIdCreator += '_' + analyticsLinkId;
    }

    return JSON.stringify({
      linkId: linkIdCreator,
      trackingType: 'customLink'
    });
  }

  /*
   * Construct CSS classes for a story block background
   * @param {Object} story block data
   * @param {String} data type to pull background
   * @returns {String} CSS classes
   */
  getCSSSectionClasses(blockData) {
    let classes = '';
    if (!blockData) {
      return '';
    }
    classes += get(blockData, 'sections.classes', false) ? blockData.sections.classes : '';
    classes += get(blockData, 'sections.backgroundColor', false) ?
      ' background-color-' + blockData.sections.backgroundColor.substr(1) :
      '';
    classes += get(blockData, 'sections.backgroundColorHover', false) ?
      ' background-color-hover-' + blockData.sections.backgroundColorHover.substr(1) :
      '';
    classes += get(blockData, 'sections.fontColor', false) ?
      ' font-color-' + blockData.sections.fontColor.substr(1) :
      '';
    classes += get(blockData, 'media.cinemagraphMedia1', false) ? ' has-pixie-player' : '';

    return classes;
  }

  // Creates linkId for on click card
  getStoryAnalyticslinkId(storyData, index, analyticsLinkId?) {
    return '&lid=' +
      this.siteId.toUpperCase() +
      '_' + 'Module' +
      '_' + storyData.generalPurposeStrings.order +
      '_' + storyData.generalPurposeStrings.storyName +
      '_' + storyData.generalPurposeStrings.template +
      '_' + index +
      (analyticsLinkId ? `_${analyticsLinkId}` : '') +
      '_Link';
  }

  /*
   * Construct Image block for a story block
   * @param {Object} CMS urlAlt data
   * @param {String} CSS class data
   *      Passed in classes such as zoom-on-hover or Bootstrap visibility classes
   * @param {Boolean} Use or not the storyBlockImageFilter (story-block-image.filter.js). Default is true.
   * @returns {Object} Image template
   */
  getImageTemplate(urlAlt, cssClasses, useStoryBlockImageFilter?): ImageTemplateType {
    const css = cssClasses ? cssClasses : '';

    const useFilter = typeof useStoryBlockImageFilter === 'undefined' || useStoryBlockImageFilter ? true : false;

    return {
      'type': 'image',
      'classes': css,
      // TODO: what does this filter do
      // 'src': useFilter ? $filter('storyBlockImage')(urlAlt.url, vm.isMobile) : urlAlt.url,
      'src': urlAlt.url,
      'altText': urlAlt.alt,
      'visibleClasses': !window.location.search.includes('loadAllImages') && !!cssClasses.match(/(^|\s)visible-\S*/g)
    };
  }

  /*
   * Construct Text block for a story block
   * @param {Object} CMS SquareCard data
   * @param {Bool} isSubtitle data
   * @param {String} CSS class data
   * @returns {Object} Image template
   */
  getTextTemplate(squareCard, isSubtitle = false, css = '') {
    // If type string just return template
    if (typeof(squareCard) === 'string') {
        return {
            'type': 'text',
            'classes': css,
            'text': squareCard
        };
    }
    // Check for subtitle and if true then return a subtitle
    if (isSubtitle === true) {
        if (squareCard.sections.subtitle) {
            return {
                'type': 'text',
                'classes': 'subtitle ' + css,
                'text': squareCard.sections.subtitle
            };
        } else {
            return;
        }
    }

    return {
        'type': 'text',
        'classes': 'title ' + css,
        'text': squareCard.sections.title
    };
  }

  /*
   * Construct button for a story block
   * @param {Object} CMS SquareCard data
   * @param {String} CSS class data
   * @returns {Object} Text Block template
   */
  getCtaTemplate(squareCard, text, css = '') {
    // Check for CTA and if true then return a CTA
    if (text === 1) {
      if (squareCard.cta1Text) {
          return {
              'type': 'text',
              'classes': css,
              'text': squareCard.cta1Text,
              'href': squareCard.cta1Link,
          };
      }
    } else if (text === 2) {
      if (squareCard.cta2Text) {
          return {
              'type': 'text',
              'classes': css,
              'text': squareCard.cta2Text,
              'href': squareCard.cta2Link,
          };
      }
    } else {
      return;
    }
  }

  /*
    * Construct Icon block for a story block
    * @param {Object} Icon properties
    * @param {String} CSS classes for the Icon
    * @returns {Object} Icon template
    */
  getIconTemplate(icon, cssClasses) {
    const css = cssClasses ? cssClasses : '';

    return {
        'type': 'icon',
        'classes': css,
        'icon': icon
    };
  }

  /*
    * Construct Wrapper block for a story block
    * @param {Array} Items to be wrapped
    * @param {String} CSS classes for the wrapper itself
    * @returns {Object} Wrapper template
    */
   getWrapperTemplate(items, cssClasses) {
    const css = cssClasses ? cssClasses : '';

    return {
        'type': 'wrapper',
        'classes': css,
        'items': items
    };
  }

  stripImageName(imageObject) {
    if (imageObject) {
        const startList = imageObject.url.split('/'),
            start = startList[startList.length - 1];

        return start.split('.jpg')[0];
    } else {
        return '';
    }
  }

  /*
    * Construct Title block for a story block
    * @param {Object} CMS SquareCard data
    * @param {String} title CSS class data
    * @param {String} subtitle CSS class data
    * @returns {Object} Link template
    */
  getTitleTemplate(squareCard, titleClasses = false, subtitleClasses = '') {
    return {
        'type': 'title',
        'classes': 'title-container ' + this.getTitleCSS(squareCard.sections),
        'items': [
            this.getTextTemplate(squareCard, titleClasses),
            this.getTextTemplate(squareCard, true, subtitleClasses)
        ].filter(item => item) // remove falsy items
    };
  }

  getTitleCSS(blockData) {
    let classes = '';
    if (!blockData) {
        return ' text-top text-left';
    }
    if (blockData.titleAlignment) {
        // Check for bottom for vertical alignment
        if (blockData.titleAlignment.indexOf('bottom') !== -1) {
            classes += ' text-bottom';
        } else {
            classes += ' text-top';
        }
        // Check for left and right otherwise center for text-align
        if (blockData.titleAlignment.indexOf('center') !== -1) {
            classes += ' text-center';
        } else if (blockData.titleAlignment.indexOf('right') !== -1) {
            classes += ' text-right';
        } else {
            classes += ' text-left';
        }
    } else {
        return ' text-top text-left';
    }

    return classes;
  }

  /*
    * Construct Pixie Player iframe inside a story block
    * @param {Object} CMS Card data
    * @returns {Object} Pixie Player template
    */
  getPixiePlayerTemplate(pixiePlayerCard) {
    const cinSrc = encodeURIComponent(get(pixiePlayerCard, 'media.cinemagraphMedia1.url', '')),
        frameSrc = '/features/pixie?id=' + cinSrc,
        alt = get(pixiePlayerCard, 'media.cinemagraphMedia1.alt', ''),
        link = get(pixiePlayerCard, 'link', '');

    return {
        'href': link,
        'alt': alt,
        'type': 'pixie-player',
        'frameSrc': this.sanitizer.bypassSecurityTrustHtml(
            '<iframe src="' + frameSrc + '"></iframe>'
        )
    };
  }

  getDynamicStyles(classes: string) {
    const backgroundColorMatch = classes.match(/background-color-((\w|\S)*)/);
    const backgroundHoverColorMatch = classes.match(/background-color-hover-((\w|\S)*)/);
    const fontColorMatch = classes.match(/font-color-((\w|\S)*)/);
    let styles = '';

    if (backgroundColorMatch) {
        const backgroundColor = backgroundColorMatch[1];
        styles += this.getBackgroundColorStyles(backgroundColor);
    }

    if (backgroundHoverColorMatch) {
        const backgroundHoverColor = backgroundHoverColorMatch[1];
        styles += this.getBackgroundHoverColorStyles(
            backgroundHoverColor
        );
    }

    if (fontColorMatch) {
        const fontColor = fontColorMatch[1];
        styles += this.getFontColorStyles(fontColor);
    }

    styles = styles.length ? `<style>${styles}</style>` : styles;

    return this.sanitizer.bypassSecurityTrustHtml(styles);
  }

  getBackgroundColorStyles(color) {
    return `
      .background-color-${color} {
        background-color: #${color};
        border-color: #${color};
      }
    `;
  }

  getBackgroundHoverColorStyles(color) {
    return `
      .background-color-hover-${color}:hover,
      .background-color-hover-${color}.focus {
        background-color: #${color};
        border-color: #${color};
      }
    `;
  }

  getFontColorStyles(color) {
    return `
      #stories-container .font-color-${color} a,
      #stories-container .font-color-${color} p,
      #stories-container .font-color-${color} h3 {
        color: #${color};
      };
    `;
  }

}

export interface ImageTemplateType {
  type?: string;
  classes?: string;
  src?: string;
  altText?: string;
  visibleClasses?: boolean;
}
