import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs/Observable';
import { get } from 'lodash';

import { DeviceService } from '../../device/device.service';
import { ModalService } from '../services/modal.service';
import { ViewChildProperties } from '../../interfaces/view-child-properties.interface';
import { KeyboardKeys } from '../../utils/keyboard/keyboard-keys';

@Component({
    selector: 'dcl-base-modal',
    templateUrl: './base-modal.component.html',
    styleUrls: ['./base-modal.component.scss']
})
export class DclBaseModalComponent implements OnInit, AfterViewInit {
    @ViewChild('heading', { static: false } as ViewChildProperties) focusableHeading: ElementRef;
    isButtonDisabled: boolean = false;
    disableScroll: boolean;
    isIos: boolean;
    timeOut = 100;
    titleClassConfig: object;
    actionsContainerClassConfig: object;
    baseModalConfig: object;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private dialogRef: MatDialogRef<DclBaseModalComponent>,
        private deviceService: DeviceService,
        private modalService: ModalService
    ) { }

    ngOnInit() {
        this.isIos = this.deviceService.isIos();
        this.buildConfig();
        this.buildBaseModalConfig();
        this.buildTitleClassConfig();
        this.buildActionsContainerClassConfig();
    }

    ngAfterViewInit() {
        // This remove the scroll in ios modals, when the content doesn't need scroll
        if (this.isIos) {
            this.modalService.removeIosScroll();
        }

        if (!this.data.showBaymaxHeader) {
            this.focusTitle();
        }
    }

    /**
     * Builds and sets default values for the necesary configuration properties to be used in
     * this component
     */
    buildConfig(): void {
        this.data.actionsSize = get(this.data, 'actionsSize', '');
        this.data.bodyContent = get(this.data, 'bodyContent', '');
        this.data.bodyFontSize = get(this.data, 'bodyFontSize', '');
        this.data.dclLogoRed = get(this.data, 'dclLogoRed', false);
        this.data.fullTopSeparator = get(this.data, 'fullTopSeparator', false);
        this.data.isTitleLogo = get(this.data, 'isTitleLogo', false);
        this.data.showLogoAndTitle = get(this.data, 'showLogoAndTitle', false);
        this.data.titleAlignment = get(this.data, 'titleAlignment', '');
        this.data.titleFontSize = get(this.data, 'titleFontSize', '');
    }

    /**
     * Builds the class configuration for the section dcl-base-modal in order to bind it
     * to the NgClass directive
     */
    buildBaseModalConfig(): void {
        this.baseModalConfig = {
            'no-title': !this.data.title
        };

        if (!!this.data.bodyContent) {
            this.baseModalConfig = {
                ...this.baseModalConfig,
                [this.data.bodyContent]: true
            };
        }
    }

    /**
     * Builds the class configuration for title element in order to bind it
     * to the NgClass directive
     */
    buildTitleClassConfig(): void {
        this.titleClassConfig = {
            'logo-title': this.data.isTitleLogo || this.data.showLogoAndTitle,
            'dcl-logo-red': this.data.dclLogoRed
        };

        if (this.data.titleAlignment) {
            this.titleClassConfig = {
                ...this.titleClassConfig,
                [this.data.titleAlignment]: true
            };
        }
    }

    /**
     * Builds the class configuration for the actions container element
     * in order to bind it to the NgClass directive
     */
    buildActionsContainerClassConfig(): void {
        const actionsAlignment = get(this.data, 'actionsAlignment');
        const actionsBorder = get(this.data, 'bodyBottomSeparator');

        this.actionsContainerClassConfig = {};

        if (!!actionsAlignment) {
            this.actionsContainerClassConfig[actionsAlignment] = true;
        }

        if (actionsBorder) {
            this.actionsContainerClassConfig['bordered'] = actionsBorder;
        }
    }

    /**
     * Close modal
     */
    close(): void {
        this.dialogRef.close();

        // When the modal is opened in ios, the baymax header is added to body
        // so if the modal is closed in ios, the baymax is added to modal again
        // that way the modal closes with the baymax inside
        if (this.data.showBaymaxHeader && this.isIos) {
            this.modalService.addBaymaxHeaderToModal();
        }
    }

    /**
     * close modal by default or execute custom function
     * @param button button that was clicked
     */
    customFunction(button): void {
        if (button.action) {
            if (button.executeAfterClose) {
                this.dialogRef.afterClosed().subscribe(() => {
                    this.execute(button);
                });
                this.close();
            } else {
                this.execute(button).then(() => {
                    this.close();
                });
            }
        } else {
            this.close();
        }
    }

    /**
     * Return a promise in case you need to execute the action function
     * after closing the button on the modal
     * @param button
     * @returns Promise that defines when the custom function was executed
     */
    execute(button): Promise<Boolean> {
        return new Promise((resolve) => {
            const isObservable = button.action();

            if (isObservable instanceof Observable) {
                button.spinner = true;
                this.isButtonDisabled = true;
                isObservable.subscribe((res) => {
                    button.spinner = false;
                    this.isButtonDisabled = false;
                    resolve(true);
                });

                return;
            }
            resolve(false);
        });
    }

    /**
     * Adds focus to the h1 title of the modal
     */
    focusTitle() {
        // This timeOut is for screen reader in IOS devices
        setTimeout(() => {
            this.focusableHeading.nativeElement.focus();
        }, this.timeOut);
    }

    /**
     * Allow close the modal using Escape
     */
    @HostListener('document:keydown', ['$event'])
    onKeyDownHandler(event: KeyboardEvent) {
        if (this.data.useEscape && event.key === KeyboardKeys.Escape ) {
            this.close();
        }
    }
}
