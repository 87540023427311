<header class="baymax-header"
    [ngClass]="{'is-modal': isModal, 'is-not-modal': !isModal}"
    dclStickyBar
    [isBarInModal]="isModal"
    [container]="parent"
    [animationDelay]="animationDelay"
    [elemWithScroll]="parentWithScroll"
    [activeTransition]="activeStickyTransition"
    (emitStickyBarScrollEvent)="emitFromStickyEvent($event)">
    <!-- CLOSE BUTTON -->
    <button *ngIf="isCloseBtn" class="dcl-link close-button" (click)="onIconClick()">
        <i class="close-icon di di-close-reversed">
            <span class="sr-only">{{ 'close-button.close' | translate }}</span>
        </i>
    </button>
    <!-- BACK BUTTON -->
    <button *ngIf="!isCloseBtn" class="dcl-link back-button" (click)="onIconClick()">
        <i class="back-icon di di-previous">
            <span class="sr-only">{{ 'back-button.back' | translate }}</span>
        </i>
    </button>
    <h1 class="baymax-header-title" [innerHtml]="title | translate"></h1>
</header>
