import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appStoryFadeIn]'
})
export class StoryFadeInDirective implements AfterViewInit {

  el: HTMLElement;
  inViewObserver: IntersectionObserver;
  initialClass = 'story-block-display-initial';
  animationClass = 'story-block-display-animation';

  constructor(private element: ElementRef) {
    this.el = element.nativeElement;
  }

  ngAfterViewInit(): void {
    this.el.classList.add(this.initialClass);
    this.inViewObserver = new IntersectionObserver(this.intersectionCheck.bind(this), {
      rootMargin: '20px',
    });
    this.inViewObserver.observe(this.el);
  }

  intersectionCheck(entries) {
    if (entries[0].isIntersecting) {
      this.inViewObserver.unobserve(this.el);
      this.triggerCSSAnimation();
    }
  }

  triggerCSSAnimation() {
    const classList = this.el.classList;
    classList.remove(this.initialClass);
    classList.add(this.animationClass);
  }

  isValidDeepLink(): boolean {
    const deepLink = new URLSearchParams(window.location.search).get('story');

    return !!(deepLink && document.getElementById(deepLink));
  }

}
