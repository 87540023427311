import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { defaultConfig } from './config.constant';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config: Object;

    constructor(private http: HttpClient) { }

    load(baseHref: string, useStatic: boolean) {
        return new Promise((resolve, reject) => {
            if (useStatic) {
                this.http.get(baseHref + '/config/').subscribe(config => {
                    this.config = config;
                    resolve(config);
                }, err => {
                    console.log('config error', err);
                    reject(err);
                });
            } else {
                this.config = defaultConfig;
                resolve(this.config);
            }
        });
    }

    getValue(key: string) {
        // Get value of config key:
        return this.config[key];
    }
}
