import { Injectable } from '@angular/core';

import { GrayoutService } from './grayout.service';
import { QuickQuoteService } from './quick-quote.service';
import { QUICK_QUOTE_CONSTANTS } from '../quick-quote.constants';

@Injectable()
export class NewItinerariesV3BannerService {
    private filters: string;
    private defaultFilter: string = QUICK_QUOTE_CONSTANTS.filters['more-filters'].groupKey.newItineraries;

    constructor(
        private grayOutService: GrayoutService,
        private quickQuoteService: QuickQuoteService
    ) { }

    /**
     * Initialize New Itineraries Banner filters
     * @param filters urlFriendly comma separated filters, new-itineraries by default
     */
    setFilterBannerType(filters: string = this.defaultFilter): void {
        this.filters = this.quickQuoteService.getValidFilters(filters.replace(/[#/]/g, '')).join();
    }

    /**
     * Behaviours when click on New Itineraries Banner
     */
    onClick(): void {
        this.quickQuoteService.clearAllFilters(true);
        this.quickQuoteService.selectUrlFriendlyFilters(this.filters, true);
        this.grayOutService.checkGrayout();
        // TODO: we should add analytics trackEvent
    }

    /**
     * Retrieve the filter new-itineraries available for the banner
     *
     * @returns Array filters values list
     */
    getFilterValue(): string[] {
        return this.quickQuoteService.getFilterOptions(this.filters);
    }
}
