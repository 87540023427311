import { Component, OnInit, Renderer2, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'css-loader',
    template: ''
})
export class CssLoaderComponent implements OnInit {
    @Input() src: string;

    constructor(
        private renderer: Renderer2,
        private element: ElementRef
    ) { }

    ngOnInit() {
        if (this.src) {
            this.loadCss(this.src);
        }
    }

    loadCss(src: string): void {
        const cssLink = this.renderer.createElement('link');
        cssLink.rel = 'stylesheet';
        cssLink.href = src;
        this.renderer.appendChild(this.element.nativeElement, cssLink);
    }

}
