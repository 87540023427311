export const ANALYTICS_CONSTANTS = {
    FEATURED_CRUISES: {
        MODULE_ID: 'DCL_Module_5_FeaturedCruises',
        IMPRESSION_LINK_ID: 'DCL_FeaturedCruises_5_Impression',
        IMPRESSION_EVENT: 'promoImpression',
        CLICK_LINK_ID: 'DCL_FeaturedCruises_5_Click',
        CLICK_EVENT: 'internalAdClick',
        CLICK_PARTIAL_PROMO_IMPRESSIONS_LIST: 'DCL_FeaturedCruises_HomePage'
    },
    PAGE_VARIANT: 'Homepage',
    EXPLORE_ALL_LINK_ID: 'DCL_FeaturedCruises_ExploreMoreButton'
};
