import { Directive, ElementRef, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { Subscription } from 'rxjs/Subscription';

import { DEBOUNCE_TIME, IOS_DELAY } from './sticky-bar.constants';
import { DeviceService } from '../device/device.service';
import { ScrollEventType } from '../interfaces/sticky-bar.interface';

@Directive({
    selector: '[dclStickyBar]'
})
export class StickyBarDirective implements AfterViewInit, OnDestroy {
    @Input() isBarInModal: boolean;
    @Input() container: string;
    @Input() animationDelay: number;
    @Input() elemWithScroll: HTMLElement;
    @Input() activeTransition = true;
    @Output() emitStickyBarScrollEvent = new EventEmitter<ScrollEventType>();

    private lastScrollTop = 0;
    private topY = 0;
    private height = 0;
    private stickyDebounceScroll: Subscription;
    private stickyDebounceResize: Subscription;
    private isIos: boolean;
    private selector;

    constructor(
        private elem: ElementRef,
        private device: DeviceService
    ) { }

    ngAfterViewInit() {
        this.stickyInit();
    }

    ngOnDestroy() {
        this.elem.nativeElement.remove();
        this.stickyDebounceScroll.unsubscribe();
        this.stickyDebounceResize.unsubscribe();
    }

    /**
     * @description inits sticky directive and events
     */
    stickyInit(): void {
        this.isIos = this.device.isIos();
        this.elem.nativeElement.style.transform = 'translateY(0)';
        this.elem.nativeElement.style.width = '100%';

        if (this.isBarInModal && this.isIos) {
            setTimeout(() => {
                document.body.appendChild(this.elem.nativeElement);
            }, this.animationDelay || IOS_DELAY);
        }

        if (this.container) {
            this.selector = document.querySelector(this.container);
        } else if (this.elemWithScroll) {
            this.selector = this.elemWithScroll;
        } else {
            this.selector = document;
        }

        // Fix for iOS scroll issue
        this.stickyDebounce();
    }

    /**
     * @description Method to emit the scroll event
     * @param data ScrollEventType
     */
    emitEventWhenScroll(data: ScrollEventType) {
        this.emitStickyBarScrollEvent.emit(data);
    }

    /**
     * @description calculates if element should hide or show with scroll position
     * @param {Object} target
     */
    stickyOnScoll(target): void {
        const scrollTop = target.scrollingElement ?
            target.scrollingElement.scrollTop :
            target.scrollY || target.scrollTop;
        const deltaY = scrollTop - this.lastScrollTop;
        const scrollData: ScrollEventType = {
            down: false,
            height: this.elem.nativeElement.getBoundingClientRect().height,
            top: 0
        };

        if (deltaY > 0) {
            // Scroll down
            scrollData.down = true;
            this.topY = this.topY - deltaY;

            if (this.activeTransition) {
                this.elem.nativeElement.style.transition = 'none';
            }

            if (!this.height) {
                this.height = this.elem.nativeElement.offsetHeight;
            }

            if (this.topY < -this.height) {
                this.topY = -this.height;
            }
        } else {
            // Scroll up
            scrollData.down = false;
            this.topY = 0;

            if (this.activeTransition) {
                this.elem.nativeElement.style.transition = 'transform 0.2s';
            }
        }

        scrollData.top = this.topY;
        this.elem.nativeElement.style.transform = `translateY(${this.topY}px)`;
        this.lastScrollTop = scrollTop < 0 ? 0 : scrollTop;

        this.emitEventWhenScroll(scrollData);
    }

    /**
     * @description prevents scroll function to be called again until DEBOUNCE_TIME passed
     */
    stickyDebounce() {
        this.stickyDebounceScroll = fromEvent(this.selector, 'scroll')
            .pipe(
                debounceTime(DEBOUNCE_TIME),
                map(event => event['target'])
            )
            .subscribe(target => {
                this.stickyOnScoll(target || this.selector);
            });

        this.stickyDebounceResize = fromEvent(window, 'resize')
            .pipe(
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe(() => {
                this.elem.nativeElement.style.transform = `translateY(0px)`;
            });
    }
}
