import { Filter } from '../interfaces/filter.interface';
import { GridConfig } from '../../grid-picker/interfaces/grid-config.interface';
import { GridContent } from '../../grid-picker/interfaces/grid-content.interface';
import { ImagePicker } from '../../images-picker/image-picker.interface';
import { OptionsGroup } from './../../options-groups/interfaces/options-group.interface';
import { QUICK_QUOTE_CONSTANTS } from './../quick-quote.constants';
import { SelectedFilter } from '../interfaces/selected-filter.interface';
import { SelectedFiltersModel } from '../interfaces/selected-filters-model.interface';

/**
 * Clears all filters for the filter type provided
 * @param filterType filter type
 * @param model filter model
 */
export const clearByTypeUtil = (filterType: string, model: Filter[]): void => {
    const filter = model.find((filterModel: Filter) => filterModel.type === filterType);

    clearFiltersSwitch(filterType, filter.data);

    filter.value = filter.modalValue = QUICK_QUOTE_CONSTANTS.filters[filterType].defaultValue;

    // clear PrivateIsland when clear Desctination group
    if (filterType === QUICK_QUOTE_CONSTANTS.filters['destination'].id) {
        const privateIslandModel = model.find(
            (filterModel: Filter) => filterModel.type === QUICK_QUOTE_CONSTANTS.filters['privateIsland'].id
        );

        if (privateIslandModel) {
            clearFiltersSwitch(
                QUICK_QUOTE_CONSTANTS.filters['privateIsland'].id,
                privateIslandModel.data
            );
        }
    }
};

/**
 * Clear selected filters model by type
 * @param filterType filter type
 * @param selectedFilters selected filters model
 * @param model filter model
 */
export const clearSelectedFiltersByTypeUtil = (
    filterType: string,
    selectedFilters: SelectedFiltersModel,
    model: Filter[]
): void => {
    const moreFiltersIndex =  model.findIndex((item: Filter) => item.type === 'more-filters');
    let privateIslandIdx;
    const defaultValue = [];
    const selectedFiltersIndex = QUICK_QUOTE_CONSTANTS.filters[filterType].renderOrder;

    if (selectedFiltersIndex === moreFiltersIndex) {
        // For more filters option, for each item we need to set an empty array
        (selectedFilters[selectedFiltersIndex] as SelectedFilter[][]).forEach(
            (moreFilterGroup, index: number) => selectedFilters[selectedFiltersIndex][index] = []
        );
    } else {
        // An empty array is the default value for each filter, except more filters option
        selectedFilters[selectedFiltersIndex] = defaultValue;
    }

    // special validation for subgroup
    if (filterType === QUICK_QUOTE_CONSTANTS.filters['destination'].id) {
        privateIslandIdx = model.findIndex((item: Filter) => item.type === 'privateIsland');

        if (model[privateIslandIdx]) {
            selectedFilters[privateIslandIdx] = [];
        }
    }
};

/**
 * this function clears all the filters in the model also
 * clears the selected values for the subtitles in the quick quote
 * leaving the default value for each one
 * @param model
 * @param selectedFilters
 */
export const clearAllUtil = (model: Filter[], selectedFilters: SelectedFiltersModel): void => {
    model.forEach((filter: Filter) => {
        clearFiltersSwitch(filter.type, filter.data);
        clearSelectedFiltersByTypeUtil(filter.type, selectedFilters, model);
    });
};

/**
 * this function clear all the content for OptionsGroup module
 *
 * @param data content to be clear
 */
function clearMoreFilters(data: OptionsGroup[]): void {
    data.forEach((group: OptionsGroup) => {
        group.options.map(option => option.selected = false);
    });
}

/**
 * this function clear all the content for Grid module
 *
 * @param data content to be clear
 */
function clearDatesFilters(data: GridConfig[]): void {
    data.forEach((dateFilter: GridConfig) => {
        dateFilter.contentData.forEach((month: GridContent) => {
            month.isSelected = false;
        });
    });
}
/**
 * this function clear the imagePicker content,
 * even if it is a city or destination filter
 *
 * @param data array with the content
 */
function clearImagesFilters(data: ImagePicker[]): void {
    data.forEach((imageFilter: ImagePicker) => {
        imageFilter.selected = false;
    });
}

/**
 * this function choose which the method use to clear
 * using an specific type for it
 *
 * @param filterType type of content
 * @param data content to be clear,
 * this content could be one of this types
 * GridConfig[], ImagePicker[], OptionsGroup[]
 */
function clearFiltersSwitch(filterType: string, data: {}[]): void {
    switch (filterType) {
        case QUICK_QUOTE_CONSTANTS.filters['date'].id:
            clearDatesFilters(<GridConfig[]>data);

            break;

        case QUICK_QUOTE_CONSTANTS.filters['privateIsland'].id:
        case QUICK_QUOTE_CONSTANTS.filters['destination'].id:
        case QUICK_QUOTE_CONSTANTS.filters['city'].id:
            clearImagesFilters(<ImagePicker[]>data);

            break;

        case QUICK_QUOTE_CONSTANTS.filters['more-filters'].id:
            clearMoreFilters(<OptionsGroup[]>data);

            break;
    }
}
