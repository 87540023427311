import { Injectable } from '@angular/core';
import { CdnService } from '../cdn-service/cdn.service';

/**
 * Ported over from universa-layout
 */

@Injectable({
  providedIn: 'root'
})
export class CdnBaseService {

  baseUrl;

  constructor(private cdnService: CdnService) { }

  getCDNUrl(url) {
    if (!this.baseUrl) {
      this.baseUrl = this.getCDNBase();
    }
    if (this.baseUrl !== '/') {
      url = this.baseUrl + this.stripBaseUrl(url);
    }

    return url;
  }

  getCDNBase() {
    let base = this.cdnService.getCDNUrl('/');
    const baseArray = base.match(/(https|http):\/\/[a-zA-Z0-9\.\-:]*/g);
    if (baseArray && baseArray.length > 0) {
      base = baseArray[0];
    }

    return base;
  }

  stripBaseUrl(url) {
    const newUrl = url.replace(/(https|http):\/\/[a-zA-Z0-9\.\-:]*/g, '').trim();

    return newUrl;
  }
}
