import { Component, OnInit } from '@angular/core';
import { StoryAbstractComponent } from '../shared/story-abstract.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'pixie-vpg',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./vpg.component.scss']
})
export class VpgComponent extends StoryAbstractComponent implements OnInit {
  constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
    super(sanitizer, cookieService);
  }
  authTokenPath = '/profile-api/authentication/get-client-token/';
  guestAffiliationsUrl = '/api/wdpro/profile-service/v4/guests/[SWID]/affiliations?site=DCL';
  earlyBookingLevels = [
    'SILVER',
    'GOLD',
    'PLATINUM',
    'TAEarlyBookingBanner'
  ];

  ngOnInit(): void {
    this.fetchAuthToken();
  }

  fetchAuthToken() {
      fetch(this.authTokenPath)
      .then(resp => resp.json())
      .then(data => {
          const token = data.access_token;

          if (token) {
            console.log('vpg:token success', token);
            const swidCookie = encodeURIComponent(this.cookieService.get('SWID'));

            if (swidCookie) {
              console.log('vpg:swid:', swidCookie);
              const guestAffiliationsPath = this.guestAffiliationsUrl.replace('[SWID]', swidCookie);

              console.log('vpg:guestAffiliationsPath:', guestAffiliationsPath);

              fetch(guestAffiliationsPath, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'BEARER ' + token
                }
              })
              .then(resp => resp.json())
              .then(affilData => {
                console.log('vpg:success getting affiliations');

                if (affilData && affilData.entries && affilData.entries.length) {
                  console.log('vpg:affiliations data', affilData);

                  const foundEarlyBookingAffil = affilData.entries.find(affil => (
                    affil.affiliationType === 'CASTAWAYCLUB' && this.earlyBookingLevels.indexOf(affil.level) > -1)
                  );

                  if (foundEarlyBookingAffil) {
                      console.log('vpg:early booking affiliation found:', foundEarlyBookingAffil.level);
                      // hide banner
                      this.storyData = {
                        sectionHeader: {},
                        blockGroups: [],
                        dynamicStyles: null
                      };
                  } else {
                      console.log('vpg:no early booking affiliation found');
                  }
                } else {
                    console.log('vpg:no guest affiliations found');
                }
              }).catch(e => console.log('vpg:fetch affiliations error', e));

            } else {
              console.log('vpg:swid not found');
            }

          } else {
              console.log('vpg:invalid access token');
          }
      })
      .catch(error => {
          console.log('vpg:token error:', error);
      });
  }

  getTemplateBlockGroups(storyData) {
    const {items, itemsWrapped} = this._generateDCLBannerBlockItems(storyData);

    const templateBlockGroups = [{
      classes: 'banner',
      anchorName: 'dcl-looker-standard-banner-block-group-1-anchor',
      blocks: [{
        analyticsTrackSeen: this.createAnalyticsTrackSeen(storyData, 1),
        classes: 'banner story-block-1 story-block-row ',
        items,
        itemsWrapped
      }]
    }];

    return templateBlockGroups;

  }

  /*
   * Construct DCL Banner template block items
   * @param {Object} Story data
   * @returns {Array} Generated items
   */
  _generateDCLBannerBlockItems(storyData) {
    const links = [];
    const bgRegex = /(^| )background( |$)/;

    if (storyData.modules.length > 0 && storyData.modules[0].descriptions) {
      storyData.modules[0].descriptions.forEach((link, index) => {
        links.push({
          'type': 'link',
          'classes': 'banner-link',
          'href': link.webLinks.link1.href,
          'analyticsLinkId': this.getStoryAnalyticslinkId(
            storyData,
            index,
            link.webLinks.link1.analyticsLinkId
          ),
          'items': [
            this.getIconTemplate({
                icon: link.webfont.htmlCharacter,
                title: link.webLinks.link1.title,
                htmlentity: link.webfont.htmlCharacter
              },
              'banner-link-icon'
            ),
            this.getTextTemplate(link.webLinks.link1.title, false, 'banner-link-text')
          ]
        });
      });
    }

    const items = [
      this.getImageTemplate(
        storyData.modules[0].media.dclBannerBackgroundDesktop,
        'banner-desktop-bg background hidden-xs hidden-sm visible-md visible-lg',
        false
      ),
      this.getImageTemplate(
        storyData.modules[0].media.dclBannerBackgroundMobile,
        'banner-mobile-bg background visible-xs visible-sm hidden-md hidden-lg',
        false
      ),
      this.getImageTemplate(storyData.modules[0].media.shipImage, 'ship-image'),
      this.getTextTemplate(storyData.modules[0].sections.title, false, 'banner-title'),
      this.getTextTemplate(
        storyData.modules[0].sections.subtitle,
        false,
        'banner-subtitle hidden-xs hidden-sm visible-md visible-lg'
      ),
      this.getTextTemplate(
        storyData.modules[0].sections.mobileSubtitle,
        false,
        'banner-subtitle visible-xs visible-sm hidden-md hidden-lg'
      ),
      this.getWrapperTemplate(links, 'banner-links-wrapper')
    ];

    const itemsWrapped = {
      background: [
        items[0],
        items[1]
      ],
      foreground: [
        items[2],
        items[3],
        items[4],
        items[5],
        items[6]
      ]
    };

    return {items, itemsWrapped};
  }

}
