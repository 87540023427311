<section class="dcl-base-modal" [ngClass]="baseModalConfig">
    <!-- Baymax header embedded mode -->
    <dcl-baymax-header
        class="dcl-baymax-header"
        *ngIf="data.showBaymaxHeader"
        [title]="data.baymaxHeaderTitle || data.title"
        [isCloseBtn]="false"
        [isModal]="true"
        [parent]="'.mat-dialog-container'"
        (iconEvent)="close()">
    </dcl-baymax-header>
    <span class="sr-only" translate>baseModal.startModal</span>
    <div class="header-section" *ngIf="!data.showBaymaxHeader">
        <div class="title-container" *ngIf="data.title"
            [ngClass]="titleClassConfig">
            <h1 class="title" #heading tabindex="0"
                [ngClass]="data.titleFontSize"
                [innerHTML]="data.title | translate">
            </h1>
            <div class="logo" role="img" [attr.aria-label]="data.title | translate"></div>
        </div>
        <button *ngIf="data.isClose"
            class="close-button teal-theme"
            (click)="close()">
            <i class="close-icon di di-close-reversed">
                <span class="sr-only">{{ 'baseModal.close' | translate }}</span>
            </i>
        </button>
    </div>

    <div class="description">
        <hr class="separator"
            aria-hidden="true" *ngIf="!data.showBaymaxHeader && data.title">
        <div class="title-container" *ngIf="data.title && data.showLogoAndTitle"
            [ngClass]="data.titleAlignment">
            <h1 class="title"tabindex="0"
                [ngClass]="data.titleFontSize"
                [innerHTML]="data.title | translate">
            </h1>
        </div>
        <div [ngClass]="[data.bodyFontSize]"
            [innerHtml]="data.body | translate">
        </div>
    </div>

    <ng-template [ngIf]="data.buttons && data.buttons.length">
        <div class="actions"
            [ngClass]="actionsContainerClassConfig">
            <ng-template ngFor [ngForOf]="data.buttons" let-button>
                <dcl-loading-content
                    class="loading-content"
                    [ngClass]="button.ngClass"
                    [spinnerSize]="'small'"
                    [showSpinner]="button.spinner">
                    <wdpr-button
                        class="buttons"
                        [ngClass]="data.actionsSize"
                        [disabled]="isButtonDisabled"
                        [theme]="button.theme"
                        [dclTrackLink]="button.analyticsTrackLink"
                        [modelProps]="button.analyticsModelProps"
                        (click)="customFunction(button)">
                        <span>{{ button.text | translate }}</span>
                    </wdpr-button>
                </dcl-loading-content>
            </ng-template>
        </div>
    </ng-template>
</section>
