export const CAST_USER_CONSTANTS = {
    STORAGE_KEY: 'cast_data',
    CAST_COOKIE: 'access_token',
    EXPIRES_COOKIE: 'expires_in',
    PATH_COOKIE: '/',
    DEFAULT_TIME: 3599,
    CAST_AFFILIATION_SUFFIX: '_CAST',
    ERROR_STATUS: {
        FORBIDDEN: 403
    },
    ERROR_CODES: {
        DATA_NOT_MATCHED: 'CAST_DATA_NOT_MATCHED',
        MISSING_DATA: 'CAST_MISSING_DATA'
    },
    UI_PAGES_CODES: {
        PRE_CART: 2
    },
    SPECIAL_OFFERS: {
        US_REGION: 'US',
        INTL_REGION: 'INTL,'
    },
    ENDPOINTS: {
        CAST_INFO: 'cruise-sales-service/cast-info',
        CHECK_OFFER_ELIGIBILITY: 'cruise-sales-service/marketing-offers/{region}/{marketingOfferId}/eligible/',
        CAST_INFO_PAVAS: 'cast-info/',
        CHECK_OFFER_ELIGIBILITY_PAVAS:
            'marketing-offers/eligible/?marketingOfferId={marketingOfferId}&region={region}'
    }
};
