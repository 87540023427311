import { AfterViewChecked, Directive } from '@angular/core';
import { CdnBaseService } from '../services/cdn-base-service/cdn-base.service';

@Directive({
  selector: '[appUniversalResources]'
})
export class UniversalResourcesDirective implements AfterViewChecked {

  started = false;
  timeoutMs = 100;
  timeoutMaxTries = 100; // 10 secs
  headEl = document.getElementsByTagName('head')[0];
  baseUrl;
  defaultUniversalCSSURL = 'https://secure.cdn1.wdpromedia.com/media/layout/universal.css';
  defaultUniversalJSURL = 'https://secure.cdn1.wdpromedia.com/media/layout/universal.js';

  constructor(private cdnBaseService: CdnBaseService) { }

  ngAfterViewChecked(): void {
    if (!this.started) {
      this.started = true;
      this.attempInject();
    }
  }

  /**
   * If juery does not exist, try again.
   */
  attempInject() {
    this.injectUniversal();
  }

  /**
   * injectUniversal
   *
   * Inject universal stylesheet and script
   */
  injectUniversal() {
    return new Promise((resolve, reject) => {
        const head: HTMLElement = document.getElementsByTagName('head')[0],
          link = document.createElement('link'),
          script = document.createElement('script');

        let useCdn = true,
          universalCSSURL = '',
          universalJSURL = '';

        // set cache to last 5 minutes = 300000 ma
        const date = new Date();
        const dateTime = Math.round(date.getTime() / 300000);
        const cacheBust = '?time=' + dateTime;

        // Universal stylesheet
        universalCSSURL = this.cdnBaseService.getCDNUrl(this.defaultUniversalCSSURL) + cacheBust;

        if (universalCSSURL.indexOf('wdpromedia-le.disney.go.com') > 0) {
            useCdn = false;
            universalCSSURL = this.defaultUniversalCSSURL;
        }

        link.rel = 'stylesheet';
        link.href = universalCSSURL;
        head.appendChild(link);

        // Universal script
        script.type = 'text/javascript';
        script.async = true;

        if (!useCdn) {
            universalJSURL = this.defaultUniversalJSURL;
        } else {
            universalJSURL = this.cdnBaseService.getCDNUrl(this.defaultUniversalJSURL);
        }

        script.src = universalJSURL + cacheBust;
        script.addEventListener('load', resolve);
        script.addEventListener('error', reject);
        script.addEventListener('abort', reject);
        document.body.appendChild(script);
    });
  }

}
