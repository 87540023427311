import { Injectable } from '@angular/core';

import { AnalyticsDataModel } from '../interfaces/analytics-data-model.interface';
import { Filter } from '../interfaces/filter.interface';
import { GrayoutService } from './grayout.service';
import { OptionsGroup } from '../../options-groups/interfaces/options-group.interface';
import { OptionItem } from '../../options-groups/interfaces/option-item.interface';
import { QuickQuoteService } from './quick-quote.service';
import { QUICK_QUOTE_CONSTANTS } from '../quick-quote.constants';

/**
 * This service handle the "New Itineraries" banner filter option behaviors
 * The banner is placed in the QQ and could be placed in a SPA page (ie: Cruise Listing Page)
 * @see tech solution https://mywiki.disney.com/display/DCLADBU/Advanced+Booking+Tech+Solutions
 *
 */
@Injectable()
export class NewItinerariesBannerService {

    /**
     * Holder that represent the the filter of "Wish Ship"
     */
    disneyWishShipFilter: OptionItem;

    /**
     * Group type where the banner is palced in the QQ
     */
    groupType = QUICK_QUOTE_CONSTANTS.filters['more-filters'].id;

    /**
     * Determine if the banner is available to use
     *
     * @typeParam boolean - True when isDclAdvancedBooking toggle is ON and "disney-wish" filter exists
     */
    isAvailable = false;

    /**
     * Holder for the filter ID of the banner
     */
    private filterId = ';filterId=urlFriendlyId';

    /**
     * AnalyticsDataModel with default Analytics information
     * - "trackLinkID" is taken from filter information
     */
    private analyticsDataModel: AnalyticsDataModel = {
        trackLinkID: QUICK_QUOTE_CONSTANTS.analytics.cruiseFiltersLink +
            QUICK_QUOTE_CONSTANTS.analytics[this.groupType].filterButtonLink +
            '_' +
            QUICK_QUOTE_CONSTANTS.analytics[this.groupType]['new-sailings']
    };

    constructor(
        private quickQuoteService: QuickQuoteService,
        private grayoutService: GrayoutService
    ) {}

    /**
     * Initialize new-sailings filter group/options as a banner instead of a option-group
     * This happens in the QQ because we don´t want to lose the benefits
     * of filter option (greyout, url, cookies, other) and for that
     * we needed it as a "Filter", using the property "hiddenGroup",
     * that hide the "filter group/option" and use data/behaviour for the banner
     *
     * @param Filter   the 'more-filters' group
     */
    init(filterData: Filter): void {
        if (
            !this.disneyWishShipFilter &&
            this.quickQuoteService.isDclAdvancedBooking() &&
            filterData.type === this.groupType
        ) {
            // cache the ship group info to use it subsequence when click on banner happens
            this.disneyWishShipFilter = this.getDisneyWishFilter(filterData);

            if (this.disneyWishShipFilter) {
                if (!this.isAvailable) {
                    this.filterId = this.disneyWishShipFilter.urlFriendlyId +
                        QUICK_QUOTE_CONSTANTS.filters.preSelectedFiltersSuffix;
                }

                this.isAvailable = true;
            }
        }
    }

    /**
     * Behaviours when click on New Sailing banner
     * BRs for this special filter (as Banner)
     *   1. Clicking this filter will clear any/all previously applied filters.
     *   2. Filter will gray out any other filters that do not apply - such as months, destinations.
     *
     * @param analyticData An object with key/value properties that wants to be updated/define when click happens
     */
    onClick(analyticsData: AnalyticsDataModel | {}): void {
        const itemFilterChanged = {
            filterType: this.groupType,
            id: this.disneyWishShipFilter.id,
            isSelected: true,
            urlFriendlyId: this.disneyWishShipFilter.urlFriendlyId,
            groupType: QUICK_QUOTE_CONSTANTS.filters['more-filters'].groupKey.ship
        };

        this.quickQuoteService.clearAllFilters(true);
        this.disneyWishShipFilter.selected = true;
        this.quickQuoteService.changedFilterItem(itemFilterChanged);
        this.quickQuoteService.mergeIntoSelectedFilters();
        this.grayoutService.checkGrayout();
        this.quickQuoteService.updateCookieValueFromSelectedFilters();
        this.quickQuoteService.updateURLFromCookieValue();

        // update/define analytics props
        this.trackAnalytics({ ...this.analyticsDataModel, ...analyticsData });
    }

    /**
     * Retrive the filters IDs list availables for the banner
     *
     * @returns Array filters IDs list
     */
    getFilters(): Array<string> {
        return [
            this.disneyWishShipFilter.id
        ];
    }

    /**
     * Retrive the filter ID for the banner
     *
     * @returns string
     */
    getFilterId(): string {
        return this.filterId;
    }

    /**
     * Retrive the filters values list availables for the banner
     *
     * @returns Array filters values list
     */
    getFiltersValues(): Array<string> {
        return this.quickQuoteService.getFiltersValues([this.disneyWishShipFilter.id]);
    }

    /**
     * Determine if the banner filter is selected
     *
     * @returns boolean true if the filter applied to the banner is selected; otherwise it is false
     */
    isSelected(): boolean {
        return this.disneyWishShipFilter && this.disneyWishShipFilter.selected;
    }

    /**
     * Determine the key to be added at the end of the filters applied, should be:
     *  Advance:[The title of the advance booking if they have the filter selected]
     *
     * Notes:
     * phase 1 (Disney Wish filter is used)
     *   - Advance: Disney Wish
     * phase 2 (New Sailings filter is used)
     *   - Advance: New Sailings
     *
     * @return string  the analytics key/value if filter is selected; otherwise empty string
     */
     getAnalyticsAdvBookingFilterValue(): string {
        let filterValue = '';

        if (this.isAvailable && this.isSelected()) {
            filterValue = QUICK_QUOTE_CONSTANTS.analytics.advBooking.prefix +
                QUICK_QUOTE_CONSTANTS.ships['disney-wish'].name;
        }

        return filterValue;
    }

    /**
     * Find the disney-wish filter option
     *
     * @param filters the group filters
     * @returns the "disney-wish" OptionItem
     */
     private getDisneyWishFilter(filters: Filter): OptionItem {
        const shipGroup = filters.data
            .find(
                (item: OptionsGroup) => item.type === QUICK_QUOTE_CONSTANTS.filters['more-filters'].groupKey.ship
            ) as OptionsGroup;

        return shipGroup.options.find(
            (item: OptionItem) => item.urlFriendlyId === QUICK_QUOTE_CONSTANTS.ships['disney-wish'].urlFriendlyId
        );
    }

    /**
     * Tack the analytics event
     *
     * @param analyticsDataModel analytics model to track the event
     */
    private trackAnalytics(analyticsDataModel: AnalyticsDataModel): void {
        this.quickQuoteService.trackAnalyticsEvent(analyticsDataModel);
    }
}
