import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateSizeClass'
})
export class TranslateSizeClassPipe implements PipeTransform {
  transform(value: string = ''): string {
    if (value === '') {
      return '';
    }

    return this.translateSizeClass(value);
  }

  translateSizeClass(cssClasses: ReplacementMapType): ReplacementMapCssClassType {
    const replacementMap: Record<ReplacementMapType, ReplacementMapCssClassType> = {
      // BLOCK STYLES
      // Desktop
      'one-sixth-desktop': 'col-md-2',
      'one-third-desktop': 'col-md-4',
      'half-desktop': 'col-md-6',
      'two-thirds-desktop': 'col-md-8',
      'full-desktop': 'col-md-12',
      // Cross Promo only
      'vertical': 'vertical col-md-2',
      'square': 'square col-md-4',
      'rectangle': 'rectangle col-md-6',
      'narrow': 'narrow col-md-8',
      // Cross Promo and Dvic Offer only
      'banner': 'banner col-md-12',
      // Mobile
      'half-mobile-landscape': 'col-sm-6'
    };

    for (const i in replacementMap) {
      if (replacementMap.hasOwnProperty(i)) {
        cssClasses = cssClasses.replace(i, replacementMap[i]);
      }
    }

    return cssClasses;
  }
}

const replacementMapTypes = [
  'one-sixth-desktop',
  'one-third-desktop',
  'half-desktop',
  'two-thirds-desktop',
  'full-desktop',
  'vertical',
  'square',
  'rectangle',
  'narrow',
  'banner',
  'half-mobile-landscape',
];
type ReplacementMapType = typeof replacementMapTypes[number];

const replacementMapCssClasses = [
  'col-md-2',
  'col-md-4',
  'col-md-6',
  'col-md-8',
  'col-md-12',
  'vertical col-md-2',
  'square col-md-4',
  'rectangle col-md-6',
  'narrow col-md-8',
  'banner col-md-12',
  'col-sm-6',
];
type ReplacementMapCssClassType = typeof replacementMapCssClasses[number];
