import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';
import * as momentModule from 'moment';

import { Child } from '../interfaces/child.interface';
import { EventService } from '../../services/event-service/event.service';
import { NonAdultAge, PartyMix } from '../../interfaces/party-mix.interface';
import { PartySize } from '../interfaces/party-size.interface';
import { ReservationIdService } from '../../services/reservation-id/reservation-id.service';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { Stateroom } from '../interfaces/stateroom.interface';
import { StateroomErrors } from '../interfaces/stateroom-errors.interface';
import { StateroomLimit } from '../interfaces/stateroom-limit.interface';
import { TRAVEL_PARTY_CONSTANTS } from './../travel-party.constants';
import { TravelAgentService } from '../../travel-agent/travel-agent.service';
import { WindowRef } from './../../window-ref/window-ref.service';
import { URLService } from '../../url/url.service';
import { ToggleService } from '../../services/toggle/toggle.service';
import { ConfigService } from '../../services/config/config.service';


@Injectable({
    providedIn: 'root'
})
export class TravelPartyService {
    getStateroomErrorsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    staterooms: Stateroom[] = [];
    stateroomsSaved: Stateroom[] = [];
    constants = TRAVEL_PARTY_CONSTANTS;
    maxStaterooms: number = this.constants.maxAmountOfStateroom;
    moment = momentModule;
    errors = [];
    promoCode = '';
    disneyPlusMaxGuest = 0;
    dclDisneyPlusOfferToggle = false;

    private isDclShipAdaPhase2: boolean;
    private disableAccessibleRoom: boolean;
    private stateroomLimit: StateroomLimit;

    constructor(
        private cookieService: CookieService,
        private eventService: EventService,
        private reservationIdService: ReservationIdService,
        private sessionStorageService: SessionStorageService,
        private travelAgentService: TravelAgentService,
        private urlService: URLService,
        private windowRef: WindowRef,
        private toggleService: ToggleService,
        private configService: ConfigService,
        private translateService: TranslateService,
    ) {
        this.retrieveTravelPartyData();
        try {
            this.disneyPlusMaxGuest = this.configService.getValue('disneyPlusMaxGuest');
            this.dclDisneyPlusOfferToggle = this.toggleService.getToggle('dclDisneyPlusOffer');
        } catch (error) {
            console.log('Error retrieving disneyPlusMaxGuest and dclDisneyPlusOfferToggle config: ', error);
        }
    }

    /**
     * Validates and retrieves if there's data persisted in localstorage, if not in cookie party_mix
     * and sets the staterooms
     * or adds a stateroom if it opens for the first time the travel party
     *
     * data: (The actual partyMix data as an array of staterooms)
     * [
     *    isDefault: (is this partyMix clean? Has the guest modified it?)
     *    accessible: (is this partymix in this stateroom requesting accessible)
     *    adultCount: (the total number of adults for this stateroom)
     *    childCount: (the total number of children for this stateroom)
     *    nonAdultAges: (an array of objects defining age per child)[]
     *    partyMixId: (is the id of each stateroom)
     * ]
     */
    retrieveTravelPartyData(): void {
        const infoUrl = this.urlService.getUrlParams();
        const isTravelAgentLoggedIn = this.travelAgentService.isTravelAgentLoggedIn();

        if (infoUrl.get('dtaUrl') && isTravelAgentLoggedIn) {
            this.setStaterooms(this.createTravelPartyFromUrl(infoUrl));
            this.updateTravelPartyStorage();
        } else {
            const data = this.windowRef.nativeWindow.localStorage ?
                this.windowRef.nativeWindow.localStorage.getItem(this.constants.cookie.name) :
                this.cookieService.get(this.constants.cookie.name);

            if (data) {
                this.setStaterooms(JSON.parse(data));
            } else {
                this.addNewStateroom();
            }
        }

        this.initStateroomsSaved();
    }

    /**
     * Create a partymix from url data
     * @param infoUrl url string
     * @returns array of 1 stateroom
     */
    createTravelPartyFromUrl(infoUrl: URLSearchParams): Stateroom[] {
        return [{
            isDefault: false,
            accessible: false,
            adultCount: Number(infoUrl.get('numberOfAdults')),
            childCount: Number(infoUrl.get('numberOfChildren')),
            nonAdultAges: this.mapTravelPartyChildrenFromUrl(infoUrl),
            partyMixId: '0'
        }];
    }

    /**
     * Create a travel party from party mix
     * @param partyMix the party mix data
     * @returns array of 1 stateroom
     */
    createTravelPartyFromPartyMix(partyMix: PartyMix, primaryGuest21Birthdate: string) {
        const reservationId = this.reservationIdService.getId();
        let staterooms: Stateroom[] = this.getPartyMixByReservation(reservationId);

        if (!staterooms || !staterooms.length) {
            staterooms = this.getStateroomFromPartyMix(partyMix);
            staterooms[0].primaryGuest21Birthdate = primaryGuest21Birthdate;
        }

        this.setStaterooms(staterooms);
        this.persistData();
    }

    /**
     * Removes the reservation's party mix from the session storage
     */
    removeReservationPartyMix() {
        const reservationId = this.reservationIdService.getId();
        const storageKey = this.getReservationPartyMixStorageKey(reservationId);

        this.sessionStorageService.removeItem(storageKey);
    }

    /**
     * Extracts the staterooms from the party mix
     * @param partyMix The reservation's party mix
     */
    getStateroomFromPartyMix(partyMix: PartyMix): Stateroom[] {
        const nonAdultAges: Child[] = [];
        const partyMixNonAdultAges = partyMix.nonAdultAges;

        if (partyMixNonAdultAges && Array.isArray(partyMixNonAdultAges)) {
            partyMixNonAdultAges.forEach((nonAdultAge: NonAdultAge) => {
                const age = nonAdultAge.age || this.constants.infantAge;
                const ageUnit = nonAdultAge.age ? this.constants.yearLabel : this.constants.monthLabel;

                nonAdultAges.push({
                    isDefault: false,
                    age,
                    staticValue: {
                        age,
                        ageUnit
                    }
                });
            });
        }

        return [{
            isDefault: false,
            accessible: partyMix.accessible,
            adultCount: partyMix.adultCount,
            childCount: partyMix.childCount,
            nonAdultAges: nonAdultAges,
            partyMixId: '0',
            adultAges: partyMix.adultAges
        }];
    }

    /**
     * Create a nonAdultAges array from url data
     * @param infoUrl url string
     * @returns array of nonAdultAges
     */
    mapTravelPartyChildrenFromUrl(infoUrl: URLSearchParams): Child[] {
        const numberOfChildren = Number(infoUrl.get('numberOfChildren'));
        const nonAdultAges: Child[] = [];

        for (let childCount = 1; childCount <= numberOfChildren; childCount++) {
            const age = Number(infoUrl.get(`child${childCount}`));

            let setStaticValue = { age, ageUnit: this.constants.yearLabel };

            if (age === 0) {
                setStaticValue = { age : this.constants.infantAge, ageUnit: this.constants.monthLabel };
            }

                nonAdultAges.push({
                    isDefault: false,
                    age,
                    staticValue: setStaticValue
                });

        }

        return nonAdultAges;
    }

    /**
     * Initialize the staterooms pre-selected/saved
     * Useful to restore the state of the staterooms
     * in case that we want to "reset" the staterooms modifications
     */
    initStateroomsSaved(): void {
        this.mergeIntoStateroomSaved();
    }

    /**
     * Add a new stateroom
     */
    addNewStateroom(): void {
        this.staterooms.push({
            isDefault: true,
            accessible: false,
            adultCount: 2,
            childCount: 0,
            nonAdultAges: [],
            partyMixId: this.staterooms.length.toString()
        });
    }

    /**
     * Verifies if the staterooms list length is more than one to be able to remove stateroom selected from list
     */
    canRemoveStateroom(): boolean {
        return this.staterooms.length > 1;
    }

    /**
     * Verifies if the staterooms list length is less than the max number of staterooms it can add
     */
    canAddStateroom(): boolean {
        return this.staterooms.length < this.maxStaterooms;
    }

    /**
     * Removes stateroom received by index number
     * @param stateroomIndex is the index of the stateroom to remove
     */
    removeStateroom(stateroomIndex: number): void {
        this.staterooms.splice(stateroomIndex, 1);
        this.staterooms.forEach((stateroom: Stateroom, index: number) => stateroom.partyMixId = index.toString());
        this.eventService.sendNextItem({
            name: this.constants.travelPartyError,
            value: this.getStateroomsWithErrors().length > 0
        });
    }

    /**
     * Restore the temporary saved staterooms state
     */
    resetStaterooms(): void {
        this.mergeIntoStateroom();
    }

    /**
     * Retrieves all the staterooms
     */
    getStaterooms(): Stateroom[] {
        return this.staterooms;
    }

    /**
     * Sets the staterooms property
     */
    setStaterooms(staterooms: Stateroom[]): void {
        this.staterooms = staterooms;
    }

    /**
     * Sets the staterooms property
     * @param {Array.<Stateroom>}
     */
    setStateroomsSaved(staterooms: Stateroom[]): void {
        this.stateroomsSaved = staterooms;
    }

    /**
     * Retrieves max number of staterooms allowed
     */
    getMaxStaterooms(): number {
        return this.maxStaterooms;
    }

    /**
     * Returns the ages that are Infant
     * @param stateroom stateroom which needs to validate if it has infant ages selected
     */
    getInfantAges(stateroom: Stateroom): number {
        const totalInfants = stateroom.nonAdultAges.filter(child => {
            return child.staticValue.ageUnit === this.constants.monthLabel;
        });

        return totalInfants.length;
    }

    /**
     * Returns the amount of child age's of Infants, 1 and 2
     * @param stateroom
     */
    getAmountOfChildsPerAdult(stateroom: Stateroom): number {
        const childAgesOneAndTwo = stateroom.nonAdultAges.filter(
            (child) => {
                return (child.age === 1 || child.age === this.constants.maxInfantAge);
            }).length;

        return this.getInfantAges(stateroom) + childAgesOneAndTwo;
    }

    /**
     * Update stateroom
     * @param stateroom stateroom with the new changes
     * @param stateroomIndex index of stateroom that will be updated
     */
    updateStateroom(stateroom: Stateroom, stateroomIndex: number): void {
        this.staterooms[stateroomIndex] = stateroom;
        this.getStateroomErrorsSubject.next(true);
    }

    /**
     * Enables ADA2 stateroom error validation
     */
    enableADA2TPErrors() {
        this.staterooms.forEach(stateroom => stateroom.enableShowADAError = this.isDclShipAdaPhase2);
    }

    /**
     * Returns all the staterooms that have errors
     */
    getStateroomsWithErrors(): Stateroom[] {
        return this.staterooms.filter((stateroom) => {
            return (
                (
                    (this.getAmountOfChildsPerAdult(stateroom) > this.constants.maxInfantsPerAdult) &&
                    stateroom.adultCount === 1
                ) ||
                ((stateroom.adultCount + stateroom.childCount) > this.constants.maxOfGuestsPerStateroom) ||
                (
                    stateroom.enableShowADAError &&
                    stateroom.accessible &&
                    !stateroom.accessibleType
                ) ||
                (
                    this.validateDisneyPlusOfferErrors(stateroom)
                )
            );
        });
    }

    /**
     * Returns the errors of the stateroom received by param
     * @param stateroom stateroom that needs to be validated if it has errors
     * @param isModFlow indicates if it is a modify flow
     */
    validateStateroomErrors(stateroom: Stateroom, isModFlow?: boolean): StateroomErrors {
        const errors = {
            acceptedAccessibleRoom: null,
            infantRestriction: null,
            infantsPerAdultRestriction: null,
            maxGuestsPerStateroom: null,
            onlyOneAdultWarning: null,
            primaryUnderAgeWarning: null,
            disneyPlusMaxGuestRestriction: null
        };

        errors.infantRestriction = this.getInfantAges(stateroom) > 0;
        errors.infantsPerAdultRestriction =
            (this.getAmountOfChildsPerAdult(stateroom) > this.constants.maxInfantsPerAdult) &&
            stateroom.adultCount === 1;
        errors.maxGuestsPerStateroom =
            stateroom.adultCount + stateroom.childCount > this.constants.maxOfGuestsPerStateroom;
        errors.onlyOneAdultWarning =
            stateroom.adultCount === 1 && stateroom.childCount === 0;
        errors.primaryUnderAgeWarning = isModFlow &&
            this.primaryGuestError(stateroom.primaryGuest21Birthdate, stateroom.nonAdultAges);
        errors.acceptedAccessibleRoom = this.isDclShipAdaPhase2 && stateroom.accessible && !stateroom.accessibleType;
        errors.disneyPlusMaxGuestRestriction = this.validateDisneyPlusOfferErrors(stateroom);

        return errors;
    }

    /**
     * Set the special offer id
     * @param promoCode Offer Id
     */
    setPromoCode(promoCode: string): void {
        this.promoCode = promoCode;
    }

    /**
     * Return true if:
     * the maximum of guests is greater than 4 and the toggle dclDisneyPlusOffer is on
     * @param stateroom stateroom
     * @returns {boolean} is enabled
     */
    validateDisneyPlusOfferErrors(stateroom: Stateroom): boolean {
        return this.dclDisneyPlusOfferToggle &&
            this.promoCode === this.constants.disneyPlusOfferId &&
            (stateroom.adultCount + stateroom.childCount) > this.disneyPlusMaxGuest;
    }

    /**
     * Creates the nonAdultAges array for the stateroom received in param
     * @param stateroom stateroom
     */
    createChildrenArray(stateroom: Stateroom): void {
        stateroom.nonAdultAges.push({
            isDefault: true,
            age: this.constants.maxNonAdultAge,
            staticValue: {
                age: this.constants.maxNonAdultAge,
                ageUnit: this.constants.yearLabel
            }
        });
    }

    /**
     * Deletes a dropdown from the stateroom's nonAdultAges list
     * @param stateroom
     */
    deleteAgeSelector(stateroom: Stateroom): void {
        let newArray = [];

        newArray = [...stateroom.nonAdultAges];
        newArray.splice(-1, 1);
        stateroom.nonAdultAges = newArray;
    }

    /**
     * A helper function to give us the age options needed for the age select-box
     * return the options with values and labels for ages
     */
    ageOptionsGenerator(): Object[] {
        const ages = [];
        let labels = [];

        labels = this.optionsGenerator(1, this.constants.maxNonAdultAge);
        ages.push({
            key: 0,
            value: this.translateService.instant("stateroom.infantLabel")
        });

        labels.forEach((label) => {
            ages.push({
                key: label,
                value: label
            });
        });

        return ages;
    }

    /**
     * A helper function to give us the options needed for the select-boxes
     * @param from where it begans
     * @param to until the last number
     */
    optionsGenerator(from: number, to: number): Object[] {
        let prepend;

        prepend = prepend || [];
        for (let i = from; i <= to; i++) {
            prepend.push(i);
        }

        return prepend;
    }

    /**
     * Sets data of travel party into the localstorage if exists, if not in cookie party_mix
     */
    persistData(): void {
        const reservationId = this.reservationIdService.getId();

        if (reservationId) {
            this.saveReservationPartyMix(reservationId);
        } else {
            const serializedTravelParty = JSON.stringify(this.staterooms);

            if (this.windowRef.nativeWindow.localStorage) {
                this.windowRef.nativeWindow.localStorage.setItem(this.constants.cookie.name, serializedTravelParty);
            } else {
                this.cookieService.set(this.constants.cookie.name, serializedTravelParty, null, '/');
            }
        }
    }

    /**
     * Validates before persisting data into localStorage/cookie if there's errors
     * Won't 'Apply Cruises' and data won't persist until errors are fixed
     */
    updateTravelPartyStorage(): boolean {
        const validateNoErrors = !this.getStateroomsWithErrors().length;

        if (validateNoErrors) {
            this.persistData();
        }

        return validateNoErrors;
    }

    /**
     * Maps the travel party to send to request
     * Removes properties isDefault and staticValue objects of nonAdultAges Array
     * Example: [{
     *    accessible: false,
     *    adultCount: 2,
     *    childCount: 1
     *    nonAdultAges: [{ age: 8, ageUnit: "YEAR" }]
     *    partyMixId: "0"
     * }]
     */
    mapTravelParty(): PartyMix[] {
        const mappedStaterooms = this.staterooms.map((stateroom) => {
            const stateroomCopied: PartyMix = _.pick(stateroom, [
                'accessible',
                'accessibleType',
                'adultCount',
                'childCount',
                'ageGroups',
                'nonAdultAges',
                'adultAges',
                'partyMixId'
            ]);
            const nonAdultAges = [];

            stateroomCopied.nonAdultAges.forEach((child, index) => {
                const age = {};
                age['age'] = stateroom.nonAdultAges[index].staticValue.age;
                age['ageUnit'] = stateroom.nonAdultAges[index].staticValue.ageUnit;
                nonAdultAges.push(age);
            });
            stateroomCopied.nonAdultAges = [...nonAdultAges];

            return stateroomCopied;
        });

        return mappedStaterooms;
    }

    /**
     * Return the total amount of guests in all staterooms including adults and children
     * @returns {number} amount of guests (adults and children) travel party
     */
    getTravelPartyCount(): number {
        return this.getStaterooms().reduce((count, stateroom) => {
            return count += stateroom.adultCount + stateroom.childCount;
        }, 0);
    }

    /**
     * Copy the values from stateroomSaved to staterooms
     */
    mergeIntoStateroom(): void {
        this.setStaterooms(_.cloneDeep(this.stateroomsSaved));
    }

    /**
     * Copy the values from staterooms to stateroomSaved
     */
    mergeIntoStateroomSaved(): void {
        this.setStateroomsSaved(_.cloneDeep(this.staterooms));
    }

    /**
     * Set value for disableAccessibleRoom
     * @param value to know if accessible room should be disabled
     */
    setDisableAccessibleRoomValue(value: boolean) {
        this.disableAccessibleRoom = value;
    }

    /**
     * Get value for disableAccessibleRoom
     * @returns flag to know if accessible room option should be disabled
     */
    getDisableAccessibleRoomValue(): boolean {
        return this.disableAccessibleRoom;
    }

    /**
     * Set value for isDclShipAdaPhase2
     * @param value DCL Ship ADA Phase 2 toggle's value
     */
    setIsDclShipAdaPhase2Value(value: boolean) {
        this.isDclShipAdaPhase2 = value;
    }

    /**
     * Get value for isDclShipAdaPhase2
     * @returns flag representing DCL Ship ADA Phase 2 toggle's value
     */
    getIsDclShipAdaPhase2Value(): boolean {
        return this.isDclShipAdaPhase2;
    }

    /**
     * Returns the party size
     * @returns party size
     */
    getPartySize(): PartySize {
        let totalParty = 0;
        let totalAdults = 0;
        let totalChildren = 0;
        let totalInfants = 0;

        this.getStaterooms().forEach(stateroom => {
            totalParty += stateroom.adultCount + stateroom.childCount;
            totalAdults += stateroom.adultCount;
            totalChildren += stateroom.childCount;
            totalInfants += this.getInfantAges(stateroom);
        });

        return {
            totalStaterooms: this.getStaterooms().length,
            totalParty,
            totalAdults,
            totalChildren,
            totalInfants
        };
    }

    /**
     * Returns the analytics string for the party size
     * @returns analytics string
     */
    getAnalyticsPartySize(): string {
        const partySize = this.getPartySize();
        const indicators = this.constants.analytics.indicators;
        const adultsString = `${partySize.totalAdults}${indicators.adults}`;
        const childrenString = `${partySize.totalChildren}${indicators.children}`;
        const infantsString = `${partySize.totalInfants}${indicators.infants}`;
        const stateroomsString = `${partySize.totalStaterooms}${indicators.staterooms}`;

        return `${partySize.totalParty}:${adultsString}:${childrenString}:${infantsString}:${stateroomsString}`;
    }

    /**
     * Returns an array of children ages to be sent to analytics model
     * @param stateroom current stateroom
     */
    getAnalyticsChildrenAges(stateroom: Stateroom): Array<number> {
        const children = stateroom.nonAdultAges;

        return children.map((child: Child) => child.age);
    }

    /**
     * Reset accessible toggle with given value and save data in localstorage to persist the new value
     * @param value new value for accessible property
     */
    resetAccessibilityToggle(value: boolean) {
        this.getStaterooms().forEach((stateroom: Stateroom) => {
            stateroom.accessible = value;
        });
        this.persistData();
    }

    /**
     * Returns if the primary guest is under 21 and there is a child in party mix
     * @param adults Array with adults in party mix
     * @param children Array with children in party mix
     * @returns result primary guest error validation
     */
    primaryGuestError(primaryGuest21Birthdate: string, children: Child[]): boolean {
        return children.length > 0 && this.moment(primaryGuest21Birthdate, 'YYYY-MM-DD').isAfter(this.moment());
    }

    /**
     * Set value for stateroomLimit
     * @param stateroomLimit
     */
    setStateroomLimit(stateroomLimit: StateroomLimit): void {
        this.stateroomLimit = stateroomLimit;
    }

    /**
     * Delete stateroomLimit
     */
    deleteStateroomLimit(): void {
        delete this.stateroomLimit;
    }

    /**
     * Get the stateroomLimit object if the limit has been reached
     * @returns stateroomLimit when the limit has been reached, null in other case
     */
    getStateroomLimitValidated(): StateroomLimit {
        if (this.stateroomLimit && this.getStaterooms().length >= this.stateroomLimit.maxStaterooms) {
            return this.stateroomLimit;
        }

        return null;
    }

    /**
     * Get a default party mix
     * @param partyMix current party mix
     * @returns party mix with ages set
     */
    getDefaultPartyMix(partyMix: Stateroom): PartyMix {
        const defaultPartyMix = {
            accessible: partyMix.accessible,
            adultCount: partyMix.adultCount,
            childCount: partyMix.childCount,
            nonAdultAges: [],
            adultAges: [],
            partyMixId: partyMix.partyMixId
        };

        if (partyMix.adultCount) {
            for (let i = 0; i < partyMix.adultCount; i++) {
                defaultPartyMix.adultAges.push({
                    ...this.constants.defaultPartyMixAges.adult,
                    isPrimaryGuest: i === 0
                });
            }
        }

        if (partyMix.nonAdultAges && partyMix.nonAdultAges.length) {
            defaultPartyMix.nonAdultAges = partyMix.nonAdultAges;
        } else if (partyMix.childCount) {
            for (let i = 0; i < partyMix.childCount; i++) {
                defaultPartyMix.nonAdultAges.push({
                    ...this.constants.defaultPartyMixAges.child
                });
            }
        }

        return defaultPartyMix;
    }

    /**
     * Returns the reservation's party mix from the session storage
     * @param reservationId The reservation id
     */
    private getPartyMixByReservation(reservationId: Number): Stateroom[] {
        const storageKey = this.getReservationPartyMixStorageKey(reservationId);
        const data = this.sessionStorageService.getItem(storageKey);

        try {
            return JSON.parse(data);
        } catch (e) {
            return [];
        }
    }

    /**
     * Returns the storage key according to the reservation id
     * @param reservationId The reservation id
     */
    private getReservationPartyMixStorageKey(reservationId: Number): string {
        return `${this.constants.cookie.reservationName}${reservationId}`;
    }

    /**
     * Save the reservation's party mix in the session storage
     * @param reservationId The reservation id
     */
    private saveReservationPartyMix(reservationId: Number) {
        const serializedTravelParty = JSON.stringify(this.staterooms);
        const storageKey = this.getReservationPartyMixStorageKey(reservationId);

        this.sessionStorageService.setItem(storageKey, serializedTravelParty);
    }
}
