import { HttpEvent, HttpHandler, HttpInterceptor,
    HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import { AuthTokenService } from '@services/auth-token/auth-token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    env: string;

    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let numOfTries = 0;

        if (req.url.endsWith('homepage-banner-toggles.json') || req.url.endsWith('available-features/')) {
            // don't do `withCredentials: true`, it causes CORS errors on cdn.
            return next.handle(req);
        } else if (!req.url.match('productavail-vas') || req.url.match('auth')) {
            // Dont call /authz while doing /authz
            // infinite loop FTW
            req = req.clone({
                withCredentials: true
            });

            return next.handle(req);
        } else {
            // All api calls should include the auth cookie
            const auth = this.injector.get(AuthTokenService);

            return auth.getAuthToken().pipe(
                mergeMap(() => {
                    req = req.clone({
                        withCredentials: true
                    });

                    return next.handle(req).pipe(
                        tap(() => {}, (err: any) => {
                            if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403 || err.status === 404)) {
                                // if for any reason a 401 or 403 happens
                                // remove the auth token to ask for a new one
                                if (numOfTries < 5) {
                                    auth.removeTokenCookie();
                                }
                                numOfTries++;
                            }
                        })
                    );
                })
            );
        }
    }
}

export const AuthHttpInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
};
