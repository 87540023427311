import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { of } from 'rxjs/observable/of';
import { CookieService } from 'ngx-cookie-service';
import isEmpty from 'lodash-es/isEmpty';
import { GeoLocationConfig } from './geolocation.config';
import { GeoLocationInfo } from './geolocation.info';

export const GEO_LOCATION_CONFIG = new InjectionToken<string>('GEO_LOCATION_CONFIG');

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  private url: string;
  private geoServiceSubject;
  private geoDataCache;

  constructor(
    @Inject(GEO_LOCATION_CONFIG) private config: any,
    private httpClient: HttpClient,
    private cookieService: CookieService,

  ) {
    this.url = this.config.URL;
  }

  public getGeoCode(): Observable<any> {
    /**
     * GIT-34734
     * Temp solution, remove once hkdl moves to using COOKIE_AKA cookie name
     */
    const akaCookieName = this.config.COOKIE_AKA_HKDL || this.config.COOKIE_AKA;

    const cookieAka = this.cookieService.get(akaCookieName);
    const cookieJar = this.cookieService.get(this.config.COOKIE_JAR);

    if (!isEmpty(cookieAka)) {
      console.log('geolocation using aka', akaCookieName);

      return this.getGeoFromAkamaiCookie(cookieAka);
    } else if (!isEmpty(cookieJar)) {
      console.log('geolocation using jar', this.config.COOKIE_JAR);

      return this.getGeoFromCookie(cookieJar);
    } else {
      console.log('geolocation using url', this.url);

      if (this.geoDataCache) {
        console.log('geo: return the cached geo data');

        return of(this.geoDataCache);
      } else if (this.geoServiceSubject) {
        console.log('geo: return geo subject');

        return this.geoServiceSubject;
      } else {
        console.log('geo: create geo subject, call geo endpoint, return geo subject');
        this.geoServiceSubject = new Subject();
        this.httpClient.get<GeoLocationInfo>(this.url).subscribe((geodata) => {
          console.log('geo: received geo data, caching calling next on geo subject');
          this.geoDataCache = geodata;
          this.geoServiceSubject.next(geodata);
        });

        return this.geoServiceSubject;
      }
    }
  }

  private getGeoFromCookie(cookie: string): Observable<GeoLocationInfo> {
    const geoip: GeoLocationInfo = JSON.parse(unescape(cookie));

    return of(geoip);
  }

  private getGeoFromAkamaiCookie(cookie: string) {
    const geoip: GeoLocationInfo = JSON.parse(unescape(cookie));
    // lc to match existing format
    geoip.countryIsoCode = geoip.countryisocode = geoip.country.toLowerCase();

    // use iso->country map if it exists
    geoip.country = this.config.ISO_COUNTRY_MAP[geoip.country] || geoip.country;

    // use region map if it exists
    geoip.region = this.config.REGION_MAP[geoip.region] || geoip.region;

    // convert Akamai format: 'Los Angeles' to `los+angeles'
    geoip.metro = geoip.metro.toLowerCase().replace(' ', '+');

    // Akamai sends ranges/lists of zip codes
    // example: `91501-91508+91510+91521-91523+91526`
    // only get the first zip code
    const match = geoip.zipCode.match(/^\d+/);
    if (match) {
        geoip.zipCode = match[0];
    }

    return of(geoip);
  }

}

