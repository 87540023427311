import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Filter } from '../interfaces/filter.interface';
import { GridContent } from '../../grid-picker/interfaces/grid-content.interface';
import { GridConfig } from '../../grid-picker/interfaces/grid-config.interface';
import { ImagePicker } from '../../images-picker/image-picker.interface';
import { OptionsGroup } from '../../options-groups/interfaces/options-group.interface';
import { OptionItem } from '../../options-groups/interfaces/option-item.interface';
import { QuickQuoteService } from './quick-quote.service';
import { QUICK_QUOTE_CONSTANTS } from '../quick-quote.constants';
import { RawFilter } from '../interfaces/raw-filter.interface';
import { SelectedFilter } from '../interfaces/selected-filter.interface';

@Injectable({
    providedIn: 'root'
})
export class GrayoutService {
    constants = QUICK_QUOTE_CONSTANTS;

    constructor(private quickQuoteService: QuickQuoteService) {}

    /**
     * Checks the filters selected to enable or disable the others filters
     */
    checkGrayout(): void {
        let finalEnabledList: string[];
        const tempSelectedFilters = this.quickQuoteService.getTempFiltersSelected();
        const specialFilter = this.quickQuoteService.getSpecialFilter();

        if (specialFilter) {
            finalEnabledList = specialFilter.enabled || [];
        }

        if (_.flattenDeep(tempSelectedFilters).length) {
            finalEnabledList = finalEnabledList || [];

            tempSelectedFilters.forEach((filterGroup: (SelectedFilter | SelectedFilter[])[], index: number) => {
                // (has arrays as subgroups (ie: more filters)
                if (filterGroup.some(Array.isArray)) {
                    // more filters
                    filterGroup.forEach((moreFilterGroup: SelectedFilter[]) => {
                        finalEnabledList = this.getEnabledList(finalEnabledList, moreFilterGroup, true);
                    });
                } else {
                    // date, destination and city filters
                    finalEnabledList = this.getEnabledList(finalEnabledList, filterGroup as SelectedFilter[], false);
                }
            });
        }

        this.disableFilters(finalEnabledList);
    }

    /**
     * Retrives the enabled filter id list based on the filter group provided
     * @param finalEnabledList final enabled filters ids list
     * @param filterGroup filters group
     * @param isMoreFilters flag to know if the filter group is a more filters group
     * @returns enabled filters ids
     */
    getEnabledList(finalEnabledList: string[], filterGroup: SelectedFilter[], isMoreFilters: boolean): string[] {
        let mergedList = [];
        const filtersData = this.quickQuoteService.getFiltersData();

        if (isMoreFilters) {
            filterGroup.forEach((filter: SelectedFilter) => {
                mergedList = [
                    ...mergedList,
                    ...filtersData[filter.id].enabled,
                    ...this.getFiltersByType(filter.groupType)
                ];
            });
        } else {
            filterGroup.forEach((filter: SelectedFilter) => {
                mergedList = [
                    ...mergedList,
                    ...filtersData[filter.id].enabled,
                    ...this.getFiltersByType(filter.type)
                ];
            });
        }

        if (!finalEnabledList.length) {
            finalEnabledList = mergedList;
        } else if (mergedList.length > 0) {
            finalEnabledList = finalEnabledList.filter(filterId => mergedList.includes(filterId));
        }

        return finalEnabledList;
    }

    /**
     * Returns the filter ids with the filter type provided
     * @param filterType Filter type
     * @returns Filters ids list
     */
    getFiltersByType(filterType: string): string[] {
        const relatedFilters: string[] = [];
        const filtersData = this.quickQuoteService.getFiltersData();

        _.forIn(filtersData, (rawFilter: RawFilter) => {
            if (rawFilter.type === filterType) {
                relatedFilters.push(rawFilter.id);
            }
        });

        return relatedFilters;
    }

    /**
     * Enables or disables filters from the model based on the filter ids list provided
     * @param filtersIds Filters id list
     */
    disableFilters(filtersIds: string[]): void {
        const filtersModel = this.quickQuoteService.getModel();

        filtersModel.forEach((filter: Filter) => {
            switch (filter.type) {
                case this.constants.filters['date'].id:
                    filter.data.forEach((gridConfig: GridConfig) => {
                        gridConfig.contentData.forEach((gridContent: GridContent) => {
                            gridContent.isDisabled = !!filtersIds && !filtersIds.includes(gridContent.id);
                        });
                    });

                    break;
                case this.constants.filters['destination'].id:
                case this.constants.filters['privateIsland'].id:
                case this.constants.filters['city'].id:
                    filter.data.forEach((imagePicker: ImagePicker) => {
                        imagePicker.disabled = !!filtersIds && !filtersIds.includes(imagePicker.id);
                    });

                    break;
                case this.constants.filters['more-filters'].id:
                    filter.data.forEach((optionsGroup: OptionsGroup) => {
                        optionsGroup.options.forEach((optionItem: OptionItem) => {
                            optionItem.disabled = !!filtersIds && !filtersIds.includes(optionItem.id);
                        });
                    });

                    break;
            }
        });
    }
}
