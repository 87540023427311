import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { HeroImageModule } from '../hero-image/hero-image.module';
import { ImagesPickerComponent } from './images-picker.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        HeroImageModule,
    ],
    declarations: [
        ImagesPickerComponent
    ],
    exports: [
        ImagesPickerComponent
    ]
})

export class ImagesPickerModule { }
