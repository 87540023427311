import { Injectable } from '@angular/core';

import { CAST_USER_CONSTANTS } from './../cast-user.constants';
import { CastMemberData } from './../../interfaces/cast-user.interface';
import { WindowRef } from './../../window-ref/window-ref.service';

@Injectable()
export class  CastUserSessionService {
    constructor(
        private windowRef: WindowRef
    ) {}

    /**
     * Save cast user data in session storage
     * @param castUser data to be saved
     */
    saveCastData(castUser: CastMemberData): void {
        this.windowRef.nativeWindow.sessionStorage.setItem(
            CAST_USER_CONSTANTS.STORAGE_KEY,
            JSON.stringify(castUser)
        );
    }

    /**
     * Get cast user data from session storage
     * @returns stored cast data
     */
    getSessionCastData(): CastMemberData {
        let castUser: CastMemberData;

        try {
            castUser = JSON.parse(
                this.windowRef.nativeWindow.sessionStorage.getItem(CAST_USER_CONSTANTS.STORAGE_KEY)
            );
        } catch (error) {
            castUser = null;
        }

        return castUser;
    }

    /**
     * Remove promoCode, affiliation and hireDate from stored cast data
     */
    removeOfferData(): void {
        const data = this.getSessionCastData();

        if (data) {
            delete data.promoCode;
            delete data.affiliation;
            delete data.hireDate;

            this.saveCastData(data);
        }
    }
}
