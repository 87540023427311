import { ImagePicker } from '../../images-picker/image-picker.interface';
import { Media } from '../../interfaces/media.interface';
import { RawFilter } from '../interfaces/raw-filter.interface';

/**
 * Maps the raw filter data to an image filter model
 *
 * Example:
 * Raw filter data:
 * '2102;entityType=port-of-call;destination=dcl;filterType=city': {
 *      name: 'Barcelona, Spain',
 *      id: '2102;entityType=port-of-call;destination=dcl;filterType=city',
 *       type: 'city',
 *        enabled: [
 *            '2020-05;filterType=date',
 *            '2020-06;filterType=date',
 *            '2020-07;filterType=date',
 *            '133826;entityType=cruise-destination;destination=dcl;filterType=destination',
 *            '5-6;filterType=night',
 *            '7;filterType=night',
 *            '2945;entityType=ship;destination=dcl;filterType=ship'
 *        ],
 *        media: {
 *            url: 'url',
 *            type: 'jpg',
 *            title: 'Sagrada Familia church in Barcelona, surrounded by trees and lush foliage',
 *            transcodeTemplate: 'transcodeTemplate',
 *            alt: 'Sagrada Familia church in Barcelona, surrounded by trees and lush foliage',
 *            fontName: null,
 *            hexCharacter: null,
 *            htmlCharacter: null
 *        }
 *    }
 *
 * Final Output:
 *  {
 *        media: {
 *            mediaProductDesktopHero: {
 *                url: 'url',
 *                type: 'jpg,
 *                title: 'Sagrada Familia church in Barcelona, surrounded by trees and lush foliage',
 *                transcodeTemplate: 'transcodeTemplate',
 *                alt: 'Sagrada Familia church in Barcelona, surrounded by trees and lush foliage'
 *            },
 *            mediaProductMobilehero: {
 *                url: 'url',
 *                type: 'jpg,
 *                title: 'Sagrada Familia church in Barcelona, surrounded by trees and lush foliage',
 *                transcodeTemplate: 'transcodeTemplate',
 *                alt: 'Sagrada Familia church in Barcelona, surrounded by trees and lush foliage'
 *            }
 *        },
 *        selected: false,
 *        disabled: false,
 *        legend: 'Barcelona, Spain
 *    };
 *
 * @param filter Raw filter data
 * @param array Final array where the new image filter will store
 */
export function imagesFilterMapper(filter: RawFilter, array: ImagePicker[]) {
    if (filter.id && filter.name) {
        let heroImageMedia: Media;

        if (filter.media) {
            const mediaData = {
                url: filter.media.url,
                type: filter.media.type,
                title: filter.media.title,
                transcodeTemplate: filter.media.transcodeTemplate,
                alt: filter.media.alt
            };

            heroImageMedia = {
                mediaProductDesktopHero: mediaData,
                mediaProductMobilehero: mediaData
            };
        }

        const imageFilter = {
            media: heroImageMedia,
            focused: false,
            selected: false,
            disabled: false,
            legend: filter.name,
            id: filter.id,
            urlFriendlyId: filter.urlFriendlyId
        };

        array.push(imageFilter);
    }
}
