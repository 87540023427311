import { NgModule } from '@angular/core';

import { DclClickOutsideDirective } from './click-outside.directive';

@NgModule({
    declarations: [
        DclClickOutsideDirective
    ],
    exports: [
        DclClickOutsideDirective
    ]
})
export class DclClickOutsideModule { }
