import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CastUserModule, TravelAgentModule, ReservationIdService, ToggleService } from '@wdpr/dcl-ui-components-library';
import { CorrelationModule } from '@wdpr/ra-angular-correlation';
import { ErrorHandlerModule } from '@wdpr/ra-angular-error-handler';
import { LoggerModule } from '@wdpr/ra-angular-logger';

import { ConfigService } from './config.service';
import { TravelAgentInterceptor } from '@core/interceptors/travel-agent.interceptor';

import { FEATURE_TOGGLE_CONFIG } from '@wdpr/ra-angular-feature-toggle';

@NgModule({
    imports: [
        CastUserModule,
        CommonModule,
        CorrelationModule,
        ErrorHandlerModule,
        LoggerModule,
        TravelAgentModule
    ],
    providers: [
        ConfigService,
        {
            provide: 'CORRELATION_CONFIG',
            useValue: {
                wdprConversationCookieName: 'Conversation_UUID',
                wdprConversationHeader: 'X-Conversation-Id',
                wdprCorrelationHeader: 'X-Correlation-Id',
                wdprEndpointFilter: '(/error/?|homepage-banner-toggles\.json)'
            }
        },
        TravelAgentInterceptor,
        {
            provide: 'FEATURE_TOGGLE_CONFIG',
            useValue: FEATURE_TOGGLE_CONFIG
        },
        ReservationIdService,
        ToggleService
    ]
})
export class CoreModule { }
