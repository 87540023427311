import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';

@Directive({
  selector: '[appTrackSeen]'
})
export class TrackSeenDirective implements AfterViewInit {
  @Input() trackSeenSendModel: boolean;
  el: HTMLElement;
  inViewObserver: IntersectionObserver;

  constructor(
    private element: ElementRef,
    private homepageAnalyticsService: HomepageAnalyticsService
  ) {
    this.el = element.nativeElement;
  }

  ngAfterViewInit(): void {
    this.inViewObserver = new IntersectionObserver(this.intersectionCheck.bind(this));
    this.inViewObserver.observe(this.el);
  }

  intersectionCheck(entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting) {
      this.inViewObserver.unobserve(this.el);
      const attrLinkId = this.el.getAttribute('wdpr-analytics-track-seen');
      console.log('Check: ', attrLinkId);
      if (attrLinkId) {
        try {
          const parsedLinkId = JSON.parse(attrLinkId);
          const modelProps = this.trackSeenSendModel ? parsedLinkId : {};
          this.homepageAnalyticsService.trackLink(parsedLinkId.linkId, modelProps);
        } catch (e) {
          console.error('TrackSeen - Error with linkId', e);
        }
      }
    }
  }

}
