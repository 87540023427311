import { Injectable } from '@angular/core';

import { AnalyticsDataModel } from '../interfaces/analytics-data-model.interface';
import { BannerFilter } from '../interfaces/banner-filter.interface';
import { Filter } from '../interfaces/filter.interface';
import { GrayoutService } from './grayout.service';
import { GridConfig } from '../../grid-picker/interfaces/grid-config.interface';
import { QUICK_QUOTE_CONSTANTS } from '../quick-quote.constants';
import { QuickQuoteService } from './quick-quote.service';

/**
 * This service handle the "New Itineraries" banner filter option behaviors
 * The banner is placed in the QQ and could be placed in a SPA page (ie: Cruise Listing Page)
 * This is a version 2, the filters are comming from translation key
 */
@Injectable()
export class NewItinerariesV2BannerService {
    private available = false;

    /**
     * urlFriendlyId list
     */
    private bannerfilterIds: string[];

    /**
     * Represent a cache for the filters to be changed and its quick-quote filter content
     */
    private bannerfilters: BannerFilter[];

    /**
     * AnalyticsDataModel with default Analytics information
     * - "trackLinkID" is taken from filter information
     */
    private analyticsDataModel: AnalyticsDataModel = {
        trackLinkID: QUICK_QUOTE_CONSTANTS.analytics.cruiseFiltersLink +
            QUICK_QUOTE_CONSTANTS.analytics[QUICK_QUOTE_CONSTANTS.filters['more-filters'].id].filterButtonLink +
            '_' +
            QUICK_QUOTE_CONSTANTS.analytics[QUICK_QUOTE_CONSTANTS.filters['more-filters'].id]['new-sailings']
    };

    constructor(
        private grayoutService: GrayoutService,
        private quickQuoteService: QuickQuoteService
    ) {}

    /**
     * Determine the key to be added at the end of the filters applied, should be:
     *  Advance:[The title of the advance booking if they have the filter selected]
     *
     * Notes:
     * phase 1 (Disney Wish filter is used)
     *   - Advance: Disney Wish
     * phase 2 (New Sailings filter is used)
     *   - Advance: New Sailings
     *
     * @return string  the analytics key/value if filter is selected; otherwise empty string
     */
    getAnalyticsAdvBookingFilterValue(): string {
        let filterValue = '';

        if (this.isAvailable) {
            filterValue = QUICK_QUOTE_CONSTANTS.analytics.advBooking.prefix +
                QUICK_QUOTE_CONSTANTS.analytics.advBooking.phase2;
        }

        return filterValue;
    }

    /**
     * Retrive the filters values list availables for the banner
     *
     * @returns Array filters values list
     */
    getFiltersValues(): Array<string> {
        return this.quickQuoteService.getFiltersValues(
            this.bannerfilters.map((filter) => filter.id)
        );
    }

    /**
     * Initialize New Itineraries Banner filters
     * @param filterData the quick-quote filter groups
     * @param bannerfilterIds the banner filters, urlFriendlyId list
     */
    init(filterData: Filter[], bannerfilterIds: string[]): void {
        if (filterData && bannerfilterIds) {
            this.bannerfilterIds = bannerfilterIds;
            this.bannerfilters = [];
            this.available = true;

            // cache the filter data to use it subsequence when click on banner happens
            filterData.forEach((filter) => {
                switch (filter.type) {
                    case QUICK_QUOTE_CONSTANTS.filters.date.id:
                        this.addDateTypeFilters(filter.data as GridConfig[]);
                        break;
                    default:
                        break;
                }
            });
        }
    }

    /**
     * Determine if the banner is available to use
     * @returns {boolean} - True when is available
     */
    get isAvailable(): boolean {
        return this.available;
    }

    /**
     * Determine if the banner filter is selected
     *
     * @returns boolean true if the filter applied to the banner is selected; otherwise it is false
     */
    isSelected(): boolean {
        return this.bannerfilters && this.bannerfilters.some(f => f.filterChanged.isSelected);
    }

    /**
     * Behaviours when click on New Itineraries Banner
     */
    onClick(analyticsData: AnalyticsDataModel | {}): void {
        this.quickQuoteService.clearAllFilters(true);
        this.bannerfilters.forEach((filter) => {
            this.selectQuickQuoteFilter(filter);
            this.quickQuoteService.changedFilterItem(filter.filterChanged);
        });
        this.quickQuoteService.mergeIntoSelectedFilters();
        this.grayoutService.checkGrayout();
        this.quickQuoteService.updateCookieValueFromSelectedFilters();
        this.quickQuoteService.updateURLFromCookieValue();

        // update/define analytics props
        this.trackAnalytics({ ...this.analyticsDataModel, ...analyticsData });
    }

    /**
     * Add Date type filters to bannerfilters cache
     * @param filters
     */
    private addDateTypeFilters(filters: GridConfig[]): void {
        filters.forEach((filter) => {
            filter.contentData.forEach((filterContent) => {
                if (this.bannerfilterIds.includes(filterContent.urlFriendlyId)) {
                    this.bannerfilters.push({
                        id: filterContent.id,
                        filter: filterContent,
                        filterChanged: {
                            filterType: QUICK_QUOTE_CONSTANTS.filters.date.id,
                            id: filterContent.id,
                            isSelected: filterContent.isSelected,
                            urlFriendlyId: filterContent.urlFriendlyId
                        },
                        disabled: filterContent.isDisabled
                    });
                }
            });
        });
    }

    /**
     * Mark a banner filter as selected
     * @param bannerFilter
     */
    private selectQuickQuoteFilter(bannerFilter: BannerFilter): void {
        bannerFilter.filter.isSelected = true;
        bannerFilter.filterChanged.isSelected = true;
    }

    /**
     * Tack the analytics event
     *
     * @param analyticsDataModel analytics model to track the event
     */
    private trackAnalytics(analyticsDataModel: AnalyticsDataModel): void {
        this.quickQuoteService.trackAnalyticsEvent(analyticsDataModel);
    }
}
