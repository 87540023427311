import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AdvancedBookingBannerComponent } from './advanced-booking-banner.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        AdvancedBookingBannerComponent
    ],
    exports: [
        AdvancedBookingBannerComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AdvancedBookingBannerModule { }
