import {Injectable} from "@angular/core";
import {WindowSizeEnum} from "@app/shared/services/window-size/window-size.enum";

@Injectable({
    providedIn: 'root'
})
export class WindowSizeService {
    private readonly mobileMaxWidth = 767;
    private readonly tabletMaxWidth = 1023;

    constructor() {}

    getDevice(): WindowSizeEnum {
        const width = window.innerWidth;
        if (width <= this.mobileMaxWidth) {
            return WindowSizeEnum.Mobile;
        } else if (width <= this.tabletMaxWidth) {
            return WindowSizeEnum.Tablet;
        } else {
            return WindowSizeEnum.Desktop;
        }
    }

}
