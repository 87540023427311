import * as momentImported from 'moment';

import { GridConfig } from '../../grid-picker/interfaces/grid-config.interface';
import { GridContent } from '../../grid-picker/interfaces/grid-content.interface';
import { RawFilter } from '../interfaces/raw-filter.interface';
import { QUICK_QUOTE_CONSTANTS } from '../quick-quote.constants';

/**
 * Maps the raw data to a Grid Config model for a date type filter
 *
 * Example:
 * Raw filter data:
 * '2020-05;filterType=date': {
 *        name: '2020-09',
 *        id: '2020-09;filterType=date',
 *        type: 'date',
 *        enabled: [
 *            '2102;entityType=port-of-call;destination=dcl;filterType=city',
 *            '2229;entityType=port-of-call;destination=dcl;filterType=city',
 *            '13516;entityType=port-of-call;destination=dcl;filterType=city',
 *            '556;entityType=port-of-call;destination=dcl;filterType=city',
 *        ],
 *        isEarlyBooking: false
 *    }
 *
 * Final Output:
 *    {
 *        label: '2020',
 *        id: '2020',
 *        contentData: [
 *            {
 *                content: 'Sep',
 *                contentAriaLabel: 'September',
 *                id: '2020-09;filterType=date',
 *                isSelected: false,
 *                isDisabled: false
 *            }
 *        ]
 *    }
 *
 * @param date Raw date type filter data
 * @param array Final array where the new date filter will store
 */
export function datesFilterMapper(date: RawFilter, array: GridConfig[], locale?: string) {
    if (date && date.id && date.name) {
        const moment = momentImported;

        if (locale) {
            moment.locale(locale);
        } else {
            moment.locale(QUICK_QUOTE_CONSTANTS.cookie.defaultLocale);
        }

        const momentDate = moment(date.name, 'YYYY-MM');        
        const formattedYear = momentDate.format('YYYY');
        let formattedMonth = momentDate.format('MMM');
        const formattedFullMonth = momentDate.format('MMMM');
        const foundFilter = array.find(filterItem => filterItem.id === formattedYear);

        formattedMonth = formattedMonth.replace(/\.$/, '');
        formattedMonth = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);

        if (foundFilter) {
            const month = getContentData(date, formattedMonth, formattedFullMonth);

            foundFilter.contentData.push(month);
        } else {
            const year = {
                label: formattedYear,
                id: formattedYear,
                contentData: [
                    getContentData(date, formattedMonth, formattedFullMonth)
                ]
            };

            array.push(year);
        }
    }
}

/**
 * Gets content data
 * @param dateParam Dates object received as param from the main function
 * @param formattedMonth Value that will be assigned to the content
 * @param formattedFullMonth Value that will handle the full name to use it on aria labels
 * @returns Content data
 */
function getContentData(dateParam: RawFilter, formattedMonth: string, formattedFullMonth: string): GridContent {
    return {
        content: formattedMonth,
        contentAriaLabel: formattedFullMonth,
        id: dateParam.id,
        isSelected: false,
        isDisabled: false,
        isEarlyBooking: dateParam.isEarlyBooking,
        urlFriendlyId: dateParam.urlFriendlyId
    };
}
