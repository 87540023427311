const COOKIES = {
    clr: 'clr',
    dclCruiseListingRefactor: 'dclCruiseListingRefactor',
    dfmDeactivate: 'dfmDeactivate',
    dfmShowDory: 'dfmShowDory',
    dfmShowDwarves: 'dfmShowDwarves',
    durationIsLoggedInCookie: 'durationIsLoggedInCookie',
    facetFilter: 'facetFilter',
    isLoggedIn: 'isLoggedIn',
    publicTokenCookieName: 'paPublicTokenExpireTime',
    rememberme: 'rememberme',
    SWID: 'SWID',
    useMockData: 'useMockData',
    myIdToken: 'access_token'
};

const COOKIES_VALUE = {
    clr: 'true',
    dclCruiseListingRefactor: 'true',
    dfmDeactivate: 'true'
};

const CUSTOM_APP_IMG_PLACEHOLDER = 'dcl-apps-productavail-spa/assets/images/img_placeholder_logo.svg';

const DEFAULT_APP_VALUES = {
    affiliations: [],
    currency: 'USD',
    language: 'en',
    pageNumber: 0,
    portOfCall: 'filteredPort',
    region: 'INTL',
    storeId: 'DCL'
};

const ENDPOINTS = {
    affiliations: 'guests/{swid}/affiliations',
    authenticationStatus: '/profile-api//authentication/status/',
    availableFeatures: '/available-features/',
    availableProducts: 'available-products',
    availableSailings: 'available-sailings',
    config: 'config/',
    errorPage: 'errorPage',
    filters: 'quick-quote-filter-options',
    healthMonitor: 'health-monitor/',
    marketingOffers: 'marketing-offers',
    whatsIncludedModal: 'whats-included-modal;type=urlFriendly',
    logout: '/travel-agent-logout/',
    utilsConfig: '/utils/quickquote-config/'
};

const PAGE_KEY_TEMPLATE = {
    PROVIDE: 'PAGE_KEY_TEMPLATE',
    USE_VALUE: [
        'siteId',
        'site',
        'url',
        'locale'
    ]
};

const PAGE_KEY_URL_ENDPOINT = {
    PROVIDE: 'PAGE_KEY_URL_ENDPOINT',
    USE_VALUE: '/dcl-apps-productavail-spa/page-key/'
};

const SEO_OPTIONS = {
    PROVIDE: 'SEO_OPTIONS',
    USE_TRANSLATE: false,
    SERVER_PATH: '/dcl-apps-productavail-spa/seo'
};

const STATEROOM_TYPE_TITLES = {
    INSIDE: 'Inside',
    OUTSIDE: 'Oceanview',
    VERANDAH: 'Verandah',
    SUITE: 'Concierge'
};

const API_CONSTANTS = {
    AUTHZ_ENDPOINT: 'authz/private',
    BUNDLE_ENDPOINT: '/language-bundles'
};

const TIME = {
    MILLISECONDS: 1000,
    SECONDS: 60
};

const LOCALE = {
    AKAMAI_COOKIE: 'localeCookie_jar_aka',
    DEFAULT_LOCALE: 'en-us'
};

const CRUISE_DETAILS_PAGE_URL = '{origin}{pathname}{sailingId}/{productName}/{sailingName}/{stateroomType}';

const SESSION_MANAGER = 'sessionManagerURL';

const HEADERS = {
    contentType: 'Content-Type',
    myIdToken: 'X-Disney-Internal-MyId-Token',
    authorizarion: 'Authorization',
    agencyId: 'X-Disney-Internal-AgencyId'
};

const TOGGLES = {
    dclCastMigration: 'dclCastMigration',
    dclTACommerce: 'dclTACommerce'
};

const ENVIRONMENTS = {
    production: 'prd'
};

const AFFILIATION_TYPES = {
    CASTAWAY: 'CASTAWAYCLUB'
};

const FILTERED_REQUESTS_TIMEOUT = 'config|en.json|authz|toggles|health-monitor|page-key';

const DEFAULT_SOCIAL_TAGS = {
    dcl: {
        pageTitle: "Cruises, Family Cruises & Disney Vacations | Disney Cruise Line",
        url: "https://disneycruise.disney.go.com/",
        siteName: "Disney Cruise Line",
        description: "Discover what sets Disney Cruise Line apart. Explore award-winning family cruises featuring world-class entertainment, modern amenities and legendary service to worldwide ports of call in The Bahamas, the Caribbean, Alaska, Europe, Mexico, Canada and more.",
        type: "website",
        image: "https://cdn1.parksmedia.wdprapps.disney.com/vision-dam/digital/parks-platform/parks-global-assets/disney-cruise-line/campaign/magic-in-a-bottle/disney-cruise-line-magic-in-a-bottle-DIQB2922101H-video.jpg",
        twitterCard: "summary_large_image",
        twitterSite: "@DisneyCruise",
    },
    hkdl: {
        pageTitle: "Hong Kong Disneyland Resort | Official Site | Hong Kong Disneyland Hotels",
        url: "https://www.hongkongdisneyland.com/",
        siteName: "Hong Kong Disneyland",
        description: "Visit Hong Kong Disneyland official website to buy park tickets, view and book hotel special offers, and find information about Hong Kong Disneyland attractions, entertainment, and more.",
        type: "website",
        image: "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/vision-dam/digital/hkdl-platform/hkdl-standard-assets/finder_hk/ja-intl/system/images/hkdl-dpe-hero-web-01.jpg",
        twitterCard: "summary_large_image",
    },
    wdw: {
        pageTitle: "Walt Disney World Resort near Orlando, Florida – Official Site",
        url: "https://disneyworld.disney.go.com/",
        siteName: "Walt Disney World",
        description: "Welcome to Walt Disney World Resort in Orlando, FL. Enjoy exciting theme parks, resorts, dining and more. Plan your magical family vacation now!",
        type: "website",
        image: "https://cdn1.parksmedia.wdprapps.disney.com/media/layout/syndicated/prod/assets/logos/wdw.svg",       
        twitterCard: "summary_large_image",
        twitterSite: "@WaltDisneyWorld"
    },
    dlr: {
        pageTitle: "Disneyland Resort in Anaheim, California – Official Site",
        url: "https://disneyland.disney.go.com/",
        siteName: "Disneyland Resort",
        description: "Welcome to the Disneyland Resort—the Happiest Place on Earth, where magical memories are made. Enjoy 2 incredible theme parks, meet favorite characters and ride thrilling attractions.",
        type: "website",
        image: "https://cdn1.parksmedia.wdprapps.disney.com/media/layout/syndicated/prod/assets/logos/dlr.svg",
        twitterCard: "summary_large_image",
        twitterSite: "@Disneyland"
    }
};

export {
    AFFILIATION_TYPES,
    API_CONSTANTS,
    COOKIES_VALUE,
    COOKIES,
    CRUISE_DETAILS_PAGE_URL,
    CUSTOM_APP_IMG_PLACEHOLDER,
    DEFAULT_APP_VALUES,
    ENDPOINTS,
    ENVIRONMENTS,
    HEADERS,
    LOCALE,
    PAGE_KEY_TEMPLATE,
    PAGE_KEY_URL_ENDPOINT,
    SEO_OPTIONS,
    SESSION_MANAGER,
    STATEROOM_TYPE_TITLES,
    TIME,
    TOGGLES,
    FILTERED_REQUESTS_TIMEOUT,
    DEFAULT_SOCIAL_TAGS
};
