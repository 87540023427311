export class GenesysChatConstants {
    public static readonly AGENCY_ID_COOKIE = 'AgencyId';
    public static readonly CHAT_LABEL = 'Live Chat';
    public static readonly CUSTOM_FORM = {
        wrapper: '<table></table>',
        inputs: []
    };
    public static readonly CUSTOM_FORM_INPUTS = [
        {
            id: 'cx_webchat_form_firstname',
            name: 'firstname',
            maxlength: '100',
            placeholder: '@i18n:webchat.ChatFormPlaceholderFirstName',
            label: '@i18n:webchat.ChatFormFirstName',
            value: ''
        },
        {
            id: 'cx_webchat_form_lastname',
            name: 'lastname',
            maxlength: '100',
            placeholder: '@i18n:webchat.ChatFormPlaceholderLastName',
            label: '@i18n:webchat.ChatFormLastName',
            value: ''
        },
        {
            id: 'cx_webchat_form_email',
            name: 'email',
            maxlength: '100',
            placeholder: 'Required',
            label: '@i18n:webchat.ChatFormEmail',
            value: ''
        },
        {
            id: 'cx_webchat_form_subject',
            name: 'Subject',
            maxlength: '100',
            placeholder: 'Subject',
            type: 'select',
            label: 'Subject',
            options: [
                { text: 'Information or New Reservation', value: 'Sales' },
                { text: 'Existing Reservation', value: 'Service' },
            ]
        },
        {
            id: 'cx_webchat_transcript',
            name: 'Send_Chat_Transcript',
            type: 'checkbox',
            label: 'Send Transcript',
            wrapper: '<tr><th>{label}</th><td>{input}</td></tr>',
            value: ''
        }
    ];
    public static readonly CX_BUS = 'CXBus';
    public static readonly CX_BUS_SCRIPT = 'cxbus.min.js';
    public static readonly DEFAULT_I18N = {
        en: {
            webchat: {
                ChatTitle: 'Chat with us',
                ChatStarted: 'Thank you for choosing to chat with us. A Disney Cruise Line Cast Member will be with ' +
                    'you shortly. If you are having challenges logging into your reservation, please call our ' +
                    'Website Resolution Team at 800-951-3532.',
                ChatEnded: 'Thank you for contacting Disney Cruise Line!',
                AgentConnected: 'You are now chatting with <%Agent%>',
                BotConnected: 'You are now chatting with <%Bot%>',
                SupervisorConnected: 'You are now chatting with <%Agent%>',
                ChatFormSubmit: 'Submit'
            }
        }
    };
    public static readonly DEFAULT_THEME = 'dcl';
    public static readonly GT_NAMESPACE = '_gt';
    public static readonly GUEST_INFO_COOKIE = 'rememberme';
    public static readonly NAMESPACE = '_genesys';
    public static readonly PLUGIN_NAME = 'WebChatPlugin';
    public static readonly PLUGIN_PATH = 'plugins';
    public static readonly TA_INFO_COOKIE = 'dclTravelAgentInfo';
    public static readonly THEMES = {
        'dcl': 'cx-theme-dcl'
    };
    public static readonly TRAVEL_AGENT_VALUES = {
        callerTypeTA: 'Travel Agent',
        spCustomerSegmentTA: 'srcAgent',
        taIDType: 'IATA'
    };
    public static readonly WIDGETS_CORE = 'widgets-core';
    public static readonly NO_GUEST_WARN = 'Genesys chat failed to get guest info';
    public static readonly USER_INFO_MAP =  new Map([
        [ 'firstname', 'name' ],
        [ 'lastname', 'lastName' ]
    ]);
    public static readonly GENESYS_DATA_EVENT = 'window:genesys.additionalData';
}
