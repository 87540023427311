export const CRUISE_TILES_CONSTANTS = {
    cruiseLink: '/cruises-destinations/list/',
    ANALYTICS: {
        IMPRESSION_LINK_ID: 'DCL_FeaturedCruises_Impression',
        IMPRESSION_EVENT: 'promoImpression',
        CLICK_LINK_ID: 'DCL_FeaturedCruises_Click',
        CLICK_EVENT: 'internalAdClick',
        CLICK_PARTIAL_PROMO_IMPRESSIONS_LIST: 'DCL_FeaturedCruises_CruiseListing',
        PRODUCT_TYPE: 'cruise',
        STORE_ID: 'DCL'
    },
    imageTile: {
        size: ['desktop', 'mobile']
    },
    a11y: {
        tileDescription: 'featuredCruiseTiles.a11y.tileDescription'
    }
};
