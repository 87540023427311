<div class="backdrop" *ngIf="showBackdrop"></div>
<div class="{{ labelAlign }}"
    [ngClass]="{ 'container-label': label, 'backdrop-spinner': showBackdrop }">
    <!-- Loading Spinner -->
    <div *ngIf="!useWavingSpinner && showSpinner"
        class="loading-spinner"
        [ngClass]="size"
        [@spinnerExitOut]
        [attr.aria-label]="ariaLabel">
    </div>
    <!-- DCL Waving Page Loader -->
    <div
        *ngIf="useWavingSpinner && showSpinner"
        class="waving-spinner"
        [attr.aria-label]="ariaLabel">
    </div>
    <div class="label"
        *ngIf="showSpinner && label"
        [ngClass]="{ 'small-label': size === 'small' }"
        aria-hidden="true">
        {{ label | translate }}
    </div>
    <div *ngIf="showSpinner && ariaLabel && ariaLabelHidden"
        class="sr-only"
        [attr.role]="!!alertRole ? 'alert' : null">
        {{ ariaLabel | translate }}
    </div>
</div>
