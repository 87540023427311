import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BaseModalModule } from '../base-modal/base-modal.module';
import { CastUserSessionService } from './../cast-user/session/cast-user-session.service';
import { HandleErrorsCastService } from './cast/handle-errors.cast.service';
import { HandleErrorsService } from './main/handle-errors.service';
import { WindowRef } from '../window-ref/window-ref.service';

@NgModule({
    imports: [
        CommonModule,
        BaseModalModule
    ],
    providers: [
        CastUserSessionService,
        HandleErrorsService,
        HandleErrorsCastService,
        WindowRef
    ]
})
export class HandleErrorModule { }
