import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { flattenDeep, get } from 'lodash';

import { CruiseTileAction } from '../../featured-cruise-tiles/interfaces/cruise-tile-action.interface';
import { EventService } from './../../services/event-service/event.service';
import { EventType } from './../../services/interfaces/event-type';
import { GrayoutService } from './../../quick-quote/services/grayout.service';
import { ItemFilterChange } from './../../quick-quote/interfaces/item-filter-changed.interface';
import { OptionItem } from './../../options-groups/interfaces/option-item.interface';
import { QUICK_QUOTE_CONSTANTS } from './../../quick-quote/quick-quote.constants';
import { QuickQuoteService } from './../../quick-quote/services/quick-quote.service';

@Injectable({
    providedIn: 'root'
})
export class ContextualFilterService {
    contextualFilterType: string;
    quickQuoteConstants = QUICK_QUOTE_CONSTANTS;

    constructor(
        private eventService: EventService,
        private grayoutService: GrayoutService,
        private quickQuoteService: QuickQuoteService
    ) {}

    /**
     * Subscribe at eventService in order to get a filter optionGroup from quick quote service
     */
    subscribeFilterOptionGroup(): void {
        this.eventService.sendNextItem({
            name: this.contextualFilterType,
            value: this.quickQuoteService.getOptionGroupFiltersByType(this.contextualFilterType)
        });
    }

    /**
     * Get the optionGroup observable from eventService
     * @returns observable of the optionGroupFilter
     */
    getOptionGroupFiltersByType(): Observable<EventType> {
        return this.eventService.getSubject(this.contextualFilterType);
    }

    /**
     * Call all the processes needed for the current selected filter in order to define its state
     * @param filterOptionItem filter selected to be modified
     * @param filtersApplied current filters applied
     */
    filterOptionItemChanged(
        filterOptionItem: OptionItem,
        filtersApplied: string[],
        action?: CruiseTileAction
    ): void {
        this.grayoutService.checkGrayout();
        this.addOrRemoveFilterOption(filterOptionItem, filtersApplied, action);
        this.quickQuoteService.mergeIntoSelectedFilters();
        this.quickQuoteService.updateCookieValueFromSelectedFilters();
        this.quickQuoteService.updateURLFromCookieValue();
    }

    /**
     * Modify the filter selected according to the action made by user add/remove
     * @param filterOptionItem - filter to be modified
     * @param filtersApplied - current filters applied
     * @param action - Action applied from a particular featured cruise option
     */
    addOrRemoveFilterOption(filterOptionItem: OptionItem, filtersApplied: string[], action?: CruiseTileAction): void {
        const actionType = get(action, 'type');
        const itemFilterChange: ItemFilterChange = {
            filterType: this.getFilterType(action, actionType) || this.quickQuoteConstants.filters['more-filters'].id,
            groupType: actionType || this.contextualFilterType,
            id: filterOptionItem.id,
            isSelected: filterOptionItem.selected,
            urlFriendlyId: filterOptionItem.urlFriendlyId
        };
        // each filter in the array has a different model that's the why it cant be typed with any specific type
        const temporalFilterSelected = flattenDeep(this.quickQuoteService.getTempFiltersSelected())
            .find(filter => filter.urlFriendlyId === itemFilterChange.urlFriendlyId);
        const appliedFilter = this.quickQuoteService.getSelectedFilterByID(
            itemFilterChange,
            filtersApplied
        );

        if (temporalFilterSelected && !appliedFilter) {
            filterOptionItem.selected = true;
            itemFilterChange.isSelected = true;

            this.quickQuoteService.updateFilterLabelValuesFromSelectedFilters(
                this.quickQuoteService.getTempFiltersSelected()
            );
        } else if (!temporalFilterSelected && appliedFilter) {
            filterOptionItem.selected = false;
            itemFilterChange.isSelected = false;
            this.quickQuoteService.changedFilterItem(itemFilterChange);
        } else {
            filterOptionItem.selected = !filterOptionItem.selected;
            itemFilterChange.isSelected = filterOptionItem.selected;
            this.quickQuoteService.changedFilterItem(itemFilterChange);
        }
    }

    /**
     * Gets filter type from group keys
     * @param actionFilter - Featured cruise action filter analyzed
     * @param actionType - Featured cruise action type analyzed
     * @returns Filter type
     */
    private getFilterType(actionFilter: CruiseTileAction, actionType: string): string {
        const moreFiltersGroup = this.quickQuoteConstants.filters['more-filters'];
        const nightGroupKey = actionType === moreFiltersGroup.groupKey.night;
        const themeGroupKey = actionType === moreFiltersGroup.groupKey.theme;
        const shipGroupKey = actionType === moreFiltersGroup.groupKey.ship;
        let filterType = null;

        if (nightGroupKey || themeGroupKey || shipGroupKey) {
            filterType = moreFiltersGroup.id;
        } else if (actionFilter) {
            filterType = actionFilter.type;
        }

        return filterType;
    }
}
