enum Languages {
    EU = 'en-eu',
    UK = 'en-gb'
}

enum Brands {
    DCL = 'dcl',
    DLR = 'dlr',
    HKDL = 'hkdl',
    WDW = 'wdw'
}

export {
    Languages,
    Brands
};
