import { NgModule } from '@angular/core';

import { TrackLinkDirective } from './track-link.directive';

@NgModule({
    declarations: [
        TrackLinkDirective
    ],
    exports: [
        TrackLinkDirective
    ]
})
export class TrackLinkModule { }
