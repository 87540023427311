import { Component, Input } from '@angular/core';
import { get } from 'lodash-es';
import { StoryAbstractComponent } from '../shared/story-abstract.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'pixie-crosspromo',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./crosspromo.component.scss']
})

export class CrosspromoComponent extends StoryAbstractComponent {
  @Input() analyticsTrackingEvent = (linkId, {}) => {};
  constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
    super(sanitizer, cookieService);
  }
  getTemplateBlockGroups(storyData) {
    const templateBlockGroups = [{
      'anchorName': 'cross-promo-narrow-block-group-1-anchor',
      'blocks': []
    }];

    const order = storyData.sourceOrder && storyData.sourceOrder.array ? storyData.sourceOrder.array : storyData.sourceOrder;

    for (let i = 0; i < order.length; ++i) {
      const keyValue = order[i];
      const sizeAndTypePieces = keyValue.indexOf('_') !== -1 ? keyValue.split('_') : [],
        size = sizeAndTypePieces[0] ? sizeAndTypePieces[0] : '',
        typeRaw = sizeAndTypePieces[1] ? sizeAndTypePieces[1] : '',
        type = typeRaw.replace(/[0-9]/g, ''),
        blockData = this._generateCrossPromoBlock(size, type, typeRaw, storyData, i);

      templateBlockGroups[0].blocks.push(blockData);
    }

    return templateBlockGroups;

  }

  /*
   * Construct Cross Promo block based on the size and type
   * @param {String} size (vertical/square/rectangle/narrow/banner)
   * @param {String} type (iconcard/hovercard)
   * @param {String} typeRaw (ex: iconcard1)
   * @param {Object} storyData (CMS Cross Promo block data)
   * @param {Integer} blockIndex (for analytics)
   * @returns {Object} properly formatted Cross Promo block data
   */
  private _generateCrossPromoBlock(size, type, typeRaw, storyData, blockIndex) {
    const blockName = size + '_' + typeRaw,
      section = 'descriptions.' + blockName;
    let itemsToAdd = [];
    const block = {
      'analyticsTrackSeen': this.createAnalyticsTrackSeen(
        storyData, blockIndex, get(storyData, section + '.analyticsLinkId', false)
      ),
      'classes': 'story-block-' + type + ' story-block-' + size + ' ' +
        this.getCSSSectionClasses(
          get(storyData, section, false)
        ),
      'personalizedLocationId': get(storyData, section +
        '.sections.personalizedLocationId',
        ''),
      items: [{
        'type': 'link',
        'classes': '',
        'href': storyData.descriptions[blockName].link,
        'target': get(storyData, `descriptions.${blockName}.target`, '_self'),
        'analyticsLinkId': this.getStoryAnalyticslinkId(storyData,
          blockIndex,
          storyData.descriptions[blockName].analyticsLinkId),
        'interstitial': storyData.descriptions[blockName].interstitial,
        'items': [],
        itemsWrapped: {
          background: [],
          foreground: []
        }
      }]
    };

    if (type === 'background-zoom') {
      itemsToAdd = this._generateCrossPromoBlockBackgroundZoom(storyData, section);
    } else if (type === 'foreground-zoom') {
      itemsToAdd = this._generateCrossPromoBlockForegroundZoom(storyData, section);
    }

    for (let i = 0; i < itemsToAdd.length; i++) {
      const item = itemsToAdd[i];
      let itemContent = get(storyData, item.contentKey, false);

      if (!itemContent) {
        continue;
      }

      itemContent = 'image' === item.type ?
        this.getImageTemplate(itemContent, item.classes) :
        this.getTextTemplate(itemContent, false, item.classes);

      // include wrapping logic here instead, it was in link function on angular 1.8 version
      // we are relying on `background` class being first item, which is definded in getStoryAnalyticslinkId() below
      if (item.classes.indexOf('background ') === 0) {
        block.items[0].itemsWrapped.background.push(itemContent);
      } else {
        block.items[0].itemsWrapped.foreground.push(itemContent);
      }
    }

    return block;
  }

  /*
   * Construct Cross Promo block with the background zoom effect
   * @param {Object} storyData (CMS Cross Promo block data)
   * @param {String} section
   * @returns {Object} properly formatted Cross Promo block data
   */
  private _generateCrossPromoBlockBackgroundZoom(storyData, section) {
    const itemsToAdd = [];

    if (get(storyData, section + '.media.backgroundDesktop', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundDesktop',
        classes: 'background hidden-xs visible-sm visible-md visible-lg ' +
          'zoom-on-hover'
      });
    }
    if (get(storyData, section + '.media.backgroundMobile', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundMobile',
        classes: 'background visible-xs hidden-sm hidden-md hidden-lg'
      });
    }
    if (get(storyData, section + '.media.icon', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.icon',
        classes: 'icon-img'
      });
    }
    if (get(storyData, section + '.sections.title', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.title',
        classes: 'title'
      });
    }
    if (get(storyData, section + '.sections.subtitle', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.subtitle',
        classes: 'subtitle'
      });
    }
    if (get(storyData, section + '.sections.linkText', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.linkText',
        classes: 'pseudo-link-text'
      });
    }
    if (get(storyData, section + '.media.media1', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.media1',
        classes: 'hidden-xs visible-sm visible-md visible-lg ' +
          'foreground-img'
      });
    }
    if (get(storyData, section + '.sections.disclaimer', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.disclaimer',
        classes: 'disclaimer'
      });
    }

    return itemsToAdd;
  }

  /*
   * Construct Cross Promo block with the foreground zoom effect
   * @param {Object} storyData (CMS Cross Promo block data)
   * @param {String} section
   * @returns {Object} properly formatted Cross Promo block data
   */
  private  _generateCrossPromoBlockForegroundZoom(storyData, section) {
    const itemsToAdd = [];

    if (get(storyData, section + '.sections.classes', '').indexOf('visa') !== -1) {
      return this._generateCrossPromoBlockForegroundZoomVisa(storyData, section);
    }
    if (get(storyData, section + '.media.backgroundDesktop', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundDesktop',
        classes: 'background hidden-xs visible-sm visible-md visible-lg ' +
          'fade-out-on-hover'
      });
    }
    if (get(storyData, section + '.media.backgroundDesktopHover', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundDesktopHover',
        classes: 'background hidden-xs hidden-sm visible-md visible-lg ' +
          'fade-in-on-hover'
      });
    }
    if (get(storyData, section + '.media.backgroundMobile', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.backgroundMobile',
        classes: 'background visible-xs hidden-sm hidden-md hidden-lg'
      });
    }
    if (get(storyData, section + '.media.icon', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.icon',
        classes: 'icon-img'
      });
    }
    if (get(storyData, section + '.sections.title', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.title',
        classes: 'title'
      });
    }
    if (get(storyData, section + '.sections.subtitle', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.subtitle',
        classes: 'subtitle'
      });
    }
    if (get(storyData, section + '.sections.linkText', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.linkText',
        classes: 'pseudo-link-text'
      });
    }
    if (get(storyData, section + '.media.media1', false)) {
      itemsToAdd.push({
        type: 'image',
        contentKey: section + '.media.media1',
        classes: 'foreground-img'
      });
    }
    if (get(storyData, section + '.sections.disclaimer', false)) {
      itemsToAdd.push({
        type: 'text',
        contentKey: section + '.sections.disclaimer',
        classes: 'disclaimer'
      });
    }

    return itemsToAdd;
  }

  /*
   * Construct VISA block (specific type of a Cross Promo block with the foreground zoom effect)
   * @param {Object} storyData (CMS Cross Promo block data)
   * @param {String} section
   * @returns {Object} properly formatted Cross Promo block data
   */
  private _generateCrossPromoBlockForegroundZoomVisa(storyData, section) {
    const itemsToAdd = [];

    itemsToAdd.push({
      type: 'image',
      contentKey: section + '.media.visaBackgroundDesktop',
      classes: 'background hidden-xs visible-sm visible-md visible-lg ' +
        'fade-out-on-hover'
    });
    itemsToAdd.push({
      type: 'image',
      contentKey: section + '.media.visaBackgroundDesktopHover',
      classes: 'background hidden-xs hidden-sm visible-md visible-lg ' +
        'fade-in-on-hover'
    });
    itemsToAdd.push({
      type: 'image',
      contentKey: section + '.media.visaBackgroundMobile',
      classes: 'background visible-xs hidden-sm hidden-md hidden-lg'
    });
    itemsToAdd.push({
      type: 'text',
      contentKey: section + '.sections.visaTitle',
      classes: 'visa-title'
    });
    itemsToAdd.push({
      type: 'text',
      contentKey: section + '.sections.visaCurrencySymbol',
      classes: 'visa-currency-symbol'
    });
    itemsToAdd.push({
      type: 'text',
      contentKey: section + '.sections.visaPrice',
      classes: 'visa-price'
    });
    itemsToAdd.push({
      type: 'text',
      contentKey: section + '.sections.visaSubtitle',
      classes: 'visa-subtitle'
    });
    itemsToAdd.push({
      type: 'text',
      contentKey: section + '.sections.visaShortDescription',
      classes: 'visa-short-description'
    });
    itemsToAdd.push({
      type: 'text',
      contentKey: section + '.sections.visaLinkText',
      classes: 'visa-pseudo-link-text'
    });
    itemsToAdd.push({
      type: 'image',
      contentKey: section + '.media.visaMediaLeft',
      classes: 'foreground-img visa-media-left'
    });
    itemsToAdd.push({
      type: 'image',
      contentKey: section + '.media.visaMediaCenter',
      classes: 'foreground-img visa-media-center'
    });
    itemsToAdd.push({
      type: 'image',
      contentKey: section + '.media.visaMediaRight',
      classes: 'foreground-img visa-media-right'
    });
    itemsToAdd.push({
      type: 'text',
      contentKey: section + '.sections.visaDisclaimer',
      classes: 'visa-disclaimer'
    });

    return itemsToAdd;
  }
}
