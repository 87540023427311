import { EventType } from '../interfaces/event-type';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    subject: Subject<EventType> = new Subject<EventType>();

    /**
     * Sends next subject item
     * @param event Info to be used in the observer
     */
    sendNextItem(event: EventType): void {
        this.subject.next(event);
    }

    /**
     * Gets subject
     * @param eventName Used to be sure that the correct event is triggered
     * @returns Observable which corresponds to the event id sent as param
     */
    getSubject(eventName: string): Observable<EventType> {
        return this.subject.pipe(
            filter((event: EventType) => event.name === eventName)
        );
    }
}
