export const TRAVEL_PARTY_CONSTANTS = {
    adultsLabelAria: 'stateroom.adultsLabelAria',
    adultslowerLimit: 1,
    adultsUpperLimit: 5,
    callUrl: '/help/phone',
    checkboxTimeout: 500,
    childrenLabelAria: 'stateroom.childrenLabelAria',
    childsUpperLimit: 4,
    cookie: {
        name: 'party_mix',
        reservationName: 'party_mix_reservation_'
    },
    infantAge: 6,
    infantLabel: 'Infant',
    maxAmountOfStateroom: 4,
    maxInfantAge: 2,
    maxInfantsPerAdult: 2,
    disneyPlusOfferId: 'DISNEY_PLUS;entityType=marketing-offer;destination=dcl',
    maxNonAdultAge: 17,
    maxOfGuestsPerStateroom: 5,
    primaryGuestMinAge: 21,
    monthLabel: 'MONTH',
    yearLabel: 'YEAR',
    travelPartyError: 'TP_Error',
    maxCapacityWarningKey: 'stateroom.maxCapacityWarningMessage',
    modifyMaxCapacityWarningKey: 'stateroom.modifyMaxCapacityWarning',
    analytics: {
        indicators: {
            adults: 'A',
            children: 'C',
            infants: 'I',
            staterooms: 'S'
        },
        actionTypes: {
            accessible: 'AccessibleAdd',
            accessibleRemove: 'AccessibleRemove',
            removeStateroom: 'StateroomRemove',
            addStateroom: 'StateroomAdd',
            child: 'Child',
            adult: 'Adult'
        }
    },
    shipADAPhase2: {
        toggle: 'isDclShipAdaPhase2',
        accessibleRoom: 'stateroomADA.accessibleRoom',
        PDFLabel: 'stateroomADA.PDFLabel',
        PDFLink: 'stateroomADA.PDFLink',
        PDFAria: 'stateroomADA.PDFAria',
        disclaimers: {
            info: 'stateroomADA.accessibleFeaturesDisclaimersInfo',
            mobility: {
                name: 'MOBILITY',
                description: 'stateroomADA.accessibleFeaturesDisclaimers.mobility'
            },
            communicative: {
                name: 'COMMUNICATIVE',
                description: 'stateroomADA.accessibleFeaturesDisclaimers.communicative'
            },
            both: {
                name: 'BOTH',
                description: 'stateroomADA.accessibleFeaturesDisclaimers.both'
            }
        }
    },
    defaultPartyMixAges: {
        adult: {
            ageUnit: 'YEAR',
            age: 21,
            isPrimaryGuest: false
        },
        child: {
            ageUnit: 'YEAR',
            age: 17
        },
        infant: {
            ageUnit: 'YEAR',
            age: 0
        }
    },
    warningMessageI18N: {
        accessibleRoom: 'stateroom.accessibleRoomWarningMessage',
        disneyPlusMaxGuest: 'stateroom.disneyPlusMaxGuestMessage',
        infantsPerAdult: 'stateroom.infantPerAdultExceededMessage',
        infantRestriction: 'stateroom.infantRestrictionMessage',
        maxCapacity: 'stateroom.maxCapacityWarningMessage',
        modifyMaxCapacity: 'stateroom.modifyMaxCapacityWarning',
        onlyOneAdult: 'stateroom.onlyOneAdultWarningMessage',
        primaryUnderAge: 'stateroom.primaryUnderAgeWarning',
    },
};
