import { Component, Input, OnInit } from '@angular/core';

import isEmpty from 'lodash-es/isEmpty';

/**
 * Creates a font-icon. For semantic icons, please add extra
 * markup to provide guidance to guests with visual disabilities.
 *
 * @example <font-icon name="all-activities" title="All Activities" size="m"></font-icon>
 * @param name string The icon's name. These can be found at [WDW]{@link https://disneyworld.disney.go.com/debug/web-font/}
 * @param size string The size of the icon. Values: xxs|xs|s|m|l|xl. Leave this param empty to inherit the font-size property.
 * @param title string The title of the icon. Used to show a tooltip upon hovering the icon.
 * @param hexvalue string The hexCharacter of the icon.
 */

@Component({
    selector: 'font-icon',
    templateUrl: './font-icon.component.html',
    styleUrls: ['./font-icon.component.scss']
})
export class FontIconComponent implements OnInit {
    styles: any;

    @Input() public color: string;
    @Input() public name: string;
    @Input() public size: string;
    @Input() public title: string;
    @Input() public hexvalue: string;
    @Input() public htmlentity = ''; // Needs to be initialized since it is HTML bound

    ngOnInit() {
        // Defaulting Inputs
        this.name = isEmpty(this.name) ? 'all-parks-and-resorts' : this.name;
        this.name = this.hexvalue || this.name;
        this.size = isEmpty(this.size) ? 'inherit' : this.size;
        this.title = isEmpty(this.title) ? null : this.title;

        if (this.color) {
            this.styles = {
                'color': this.color
            };
        }

        // Remove the name if we are passed an HTML entity
        if (this.htmlentity) {
            this.name = '';
        }
    }
}
