import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgModule } from '@angular/core';

import { CastUserRestService } from './rest/cast-user-rest.service';
import { CastUserSessionService } from './session/cast-user-session.service';
import { CastUserService } from './cast-user.service';
import { HandleErrorModule } from '../handle-errors/handle-errors.module';
import { WindowRef } from '../window-ref/window-ref.service';

@NgModule({
    imports: [
        CommonModule,
        HandleErrorModule
    ],
    providers: [
        CastUserRestService,
        CastUserService,
        CastUserSessionService,
        CookieService,
        WindowRef
    ]
})
export class CastUserModule { }
