import { Injectable } from '@angular/core';
import { DeviceDataService } from '@wdpr/ra-angular-device-detection';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    constructor(
        private deviceDataService: DeviceDataService
    ) {
        // Sets up the device detection configuration.
        this.deviceDataService.getConfig();
        this.deviceDataService.getCookie(deviceDataService.getConfig());
    }

    /**
     * Return if is android device.
     * @returns {boolean}
     */
    isAndroid(): boolean {
        return this.deviceDataService.isAndroid();
    }

    /**
     * Return if is iOS device.
     * @returns {boolean}
     */
    isIos(): boolean {
        return this.deviceDataService.isIOS();
    }

    /**
     * Return if is desktop.
     * @returns {boolean}
     */
    isDesktop(): boolean {
        return this.deviceDataService.isDesktop();
    }

    /**
     * Return if is mobile.
     * @returns {boolean}
     */
    isMobile(): boolean {
        return this.deviceDataService.isMobile();
    }

    /**
     * Return if is tablet.
     * @returns {boolean}
     */
    isTablet(): boolean {
        return this.deviceDataService.isTablet();
    }

    /**
     * This function adds classes to support device specific styles
     */
    addDeviceClasses(): void {
        const html = document.querySelector('html');

        // Android Devices
        if (this.isAndroid()) {
            html.classList.add('android');
        }

        // IOS Devices
        if (this.isIos()) {
            html.classList.add('ios');
        }

        // Desktop
        if (this.isDesktop()) {
            html.classList.add('desktop');
        }

        // Mobile
        if (this.isMobile()) {
            html.classList.add('mobile');
        }

        // Tablet
        if (this.isTablet()) {
            html.classList.add('tablet');
        }
    }
}
