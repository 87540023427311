import { AfterViewChecked, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDeepLink]'
})
export class DeepLinkDirective implements AfterViewChecked {
  el: HTMLElement;
  done = false;
  queryParamKey = 'story';
  renderedSelector = '.story';
  queryString;

  constructor(private element: ElementRef) {
    this.el = element.nativeElement;
  }

  /**
   * This can be called frequently during initialization so we
   * do deep conditional checking to reduce processing per call
   */
  ngAfterViewChecked(): void {
    if (!this.done) {
      this.queryString = this.queryString || document.location.search;
      const params: URLSearchParams = new URLSearchParams(this.queryString);
      const targetId: string | null = params.get(this.queryParamKey);

      // no need to query the dom if no query params exists, or query param value is empty
      if (!targetId) {
        this.done = true;
      } else {
        const rendered: boolean = !!this.el.querySelectorAll(this.renderedSelector).length;
        if (rendered) {
          const targetEl: HTMLElement | null = document.getElementById(targetId);
          if (targetEl) {
            this.done = true;
            /**
             * setTimeout to prevent syndicated nav sometimes
             * forcing a scroll back to top
             * TODO: possibly tweak this or add resiliency
             */
            setTimeout(() => targetEl.scrollIntoView(), 50);
          }
        }
      }
    }
  }

}
