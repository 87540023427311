import { InjectionToken, Provider } from '@angular/core';

import { CoreWidgetConfig } from './core-widget-config.interface';
import { WebchatWidgetConfig } from './webchat-widget-config.interface';

export interface GenesysChatConfig {
    baseUrl: string;
    main?: CoreWidgetConfig;
    webchat?: WebchatWidgetConfig;
}

export interface GenesysChatModuleConfig {
    configProvider: Provider;
}

export const GENESYS_CHAT_CONFIG = new InjectionToken<GenesysChatConfig>('GenesysChatConfig');
