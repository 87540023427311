export const LOCALE = {
    AKAMAI_COOKIE_PROD: 'localeCookie_jar',
    AKAMAI_COOKIE: 'localeCookie_jar_aka',
    CONTENT_LANGUAGE: 'contentLanguage',
    CONTENT_LOCALE: 'contentLocale',
    DEFAULT_LOCALE: 'en-us',
    LANGUAGE_SELECTION_COOKIE: 'languageSelection_jar_aka',
    LANGUAGE_SELECTION_PROD: 'languageSelection_jar',
    SPECIAL_FILTERS: 'special_filters'
};
