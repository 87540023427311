import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OptionItemComponent } from './components/option-item/option-item.component';
import { OptionsGroupsComponent } from './options-groups.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        OptionItemComponent,
        OptionsGroupsComponent
    ],
    exports: [
        OptionsGroupsComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class OptionsGroupsModule { }
