import { Injectable } from '@angular/core';

import { ActionTile } from '../interfaces/action-tile.interface';
import { AvailabilityBodyParam } from './../../interfaces/availability-body-param.interface';
import { ContextualFilterService } from '../../contextual-filter/services/contextual-filter.service';
import { CRUISE_TILES_CONSTANTS } from '../featured-cruise-tiles.constants';
import { CruiseTileAction } from '../interfaces/cruise-tile-action.interface';
import { FeaturedCruisesActionTileResponseEntity } from '../interfaces/featured-cruises-response-entity.interface';
import { LocaleService } from '../../locale/locale.service';
import { MediaHero } from '../interfaces/media-hero.interface';
import { QuickQuoteService } from '../../quick-quote/services/quick-quote.service';
import { TileData } from '../interfaces/tile-data.interface';
import { TileImage } from '../interfaces/image-tile.interface';

@Injectable({
    providedIn: 'root'
})
export class FeaturedCruiseService {
    constructor(
        private contextualFilterService: ContextualFilterService,
        private quickQuoteService: QuickQuoteService,
        private localeService: LocaleService
    ) {}

    /**
     * Determines if the selected feature needs to apply a filter
     * @param tileData
     * @param availProductsParams
     * @param applyFilters
     */
    selectFeaturedCruise(
        tileData: TileData,
        availProductsParams: AvailabilityBodyParam,
        applyFilters?: (filter: string[]) => void
    ): void {
        let filtersValues: string[] = [];
        let shouldApplyFilters = false;

        // Add filters from Tiles Actions to selected filters model
        tileData.actions.forEach((action: CruiseTileAction, index: number) => {
            if (!this.quickQuoteService.getSelectedFiltersToApply().includes(action.id)) {
                shouldApplyFilters = true;

                this.contextualFilterService.filterOptionItemChanged(
                    tileData.filterData[index],
                    availProductsParams.filters || [],
                    action
                );
            }
        });

        if (shouldApplyFilters) {
            // Update QQ filter model with new selected filters
            this.quickQuoteService.selectFilters(tileData.actions);

            // Get selected filters values
            filtersValues = this.quickQuoteService.getFiltersValues(
                this.quickQuoteService.getSelectedFiltersToApply()
            );

            applyFilters(filtersValues);
        }
    }

    /**
     * formatted Feature cruise price according the currency
     * @param rawTile FeaturedCruisesActionTileResponseEntity
     * Model of a cta object on the response on a successful call to endpoint
     * @returns formattedPrice - price formatted according to currency or null
     */
    formatFeaturedCruiseTilePrice(rawTile: FeaturedCruisesActionTileResponseEntity): string | null {
        const minimumPrice = rawTile.minimumPriceSummary;
        const getLocation = this.localeService.getLocale();
        const intlConfigDefault = {
            style: 'currency',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        };
        let formattedPrice = null;

        if (minimumPrice) {
            const { currency, total } = minimumPrice;

            formattedPrice = Intl.NumberFormat(getLocation, {
                ...intlConfigDefault,
                currency,
            }).format(total);
        }

        return formattedPrice;
    }

    /**
     * Parses a part of the response from post(`${this.webApiUrl}${ENDPOINTS.featuredCruisesTiles}`)
     * into a ActionTile for the upper layers of the software
     * @param rawTile FeaturedCruisesActionTileResponseEntity
     * Model of a cta object on the response on a successful call to endpoint
     * @param priceStartFromTranslationValue String translated string used to display price
     * @returns ActionTile Model of an action tile used on the upper layers of the software
     */
    adaptFeaturedCruiseTile(
        rawTile: FeaturedCruisesActionTileResponseEntity,
        priceStartFromTranslationValue: string
    ): ActionTile {
        const adaptedTileActions: CruiseTileAction[] = [];
        const formattedPrice = this.formatFeaturedCruiseTilePrice(rawTile);
        const description = formattedPrice
            ? `${priceStartFromTranslationValue} ${formattedPrice}`
            : '';
        const tileImage = this.filterTileImage(rawTile);
        const adaptedTile: ActionTile = {
            description,
            text: rawTile.text,
            tileImage,
            actions: adaptedTileActions
        };

        for (const rawAction of rawTile.actions) {
            if (
                rawAction &&
                rawAction.filterValue &&
                rawAction.filterId &&
                rawAction.type &&
                rawAction.urlFriendlyId
            ) {
                const adaptedTileAction: CruiseTileAction = {
                    filterValue: rawAction.filterValue,
                    id: rawAction.filterId,
                    name: rawAction.value || '',
                    type: rawAction.type,
                    urlFriendlyId: rawAction.urlFriendlyId
                };

                adaptedTileActions.push(adaptedTileAction);
            }
        }

        return adaptedTile;
    }

    /**
     * Validate FeaturedCruisesActionTileResponseEntity to return a valid TileImage
     * @param raw FeaturedCruisesActionTileResponseEntity
     * Model of a cta object on the response on a successful call to endpoint
     * @returns TileImage object used in the
     */
    filterTileImage(raw: FeaturedCruisesActionTileResponseEntity): TileImage {
        let tileImage = {} as TileImage;

        if (raw && raw.media) {
            tileImage = Object.entries(raw.media).reduce(
                this.createTileImage,
                {} as TileImage
            );
        }

        return tileImage;
    }

    /**
     * Clears all filters
     */
    clearFilters(): void {
        this.quickQuoteService.clearAllFilters();
    }

    /**
     * Create tileImage objects for ActionTile
     * @param accum TileImage
     * @param current String
     * @returns TileImage
     */
    private createTileImage(accum: TileImage, [key, mediaHero]: [string, MediaHero]): TileImage {
        if (CRUISE_TILES_CONSTANTS.imageTile.size.includes(key)) {
            accum[key] = {
                title: mediaHero.title || '',
                alt: mediaHero.alt || '',
                transcodeTemplate: mediaHero.transcodeTemplate || '',
                type: mediaHero.type || '',
                url: mediaHero.url || ''
            };
        }

        return accum;
    }
}
