import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingContentComponent } from './loading-content.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        LoadingSpinnerModule,
        TranslateModule
    ],
    declarations: [
        LoadingContentComponent
    ],
    exports: [
        LoadingContentComponent
    ]
})
export class LoadingContentModule { }
