import { datesFilterMapper } from './dates-filter.mapper';
import { imagesFilterMapper } from './images-filter.mapper';
import { moreFiltersMapper } from './more-filters.mapper';

export const filterMappers = {
    'date': datesFilterMapper,
    'city': imagesFilterMapper,
    'destination': imagesFilterMapper,
    'privateIsland': imagesFilterMapper,
    'more-filters': moreFiltersMapper
};
