import { Injectable } from '@angular/core';

function _navigator() {
    return navigator;
}

@Injectable()
export class NavigatorRef {
    get nativeNavigator() {
        return _navigator();
    }
}
