function getLangFromOrigin() {
  const localeRegExp = /^\/([a-z]{2}((_|-)[A-z]{2})?)(\/|$)/;
  let lang = 'en-us';
  const origin = window.location.origin;
  if (origin.endsWith('.eu')) {
    lang = 'en-eu';
  } else if (origin.endsWith('.co.uk')) {
    lang = 'en-gb';
  } else {
    const currentLocaleFromPath = window.location.pathname.match(localeRegExp)?.[0] || '';
    lang = currentLocaleFromPath ? currentLocaleFromPath.toLowerCase().replace(/\//g, '').replace(/_/g, '-') : lang;
  }

  return lang;
}
export default getLangFromOrigin;

