import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { DeviceDetectionModule } from '@wdpr/ra-angular-device-detection';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import { playerFactory } from '../lottie-web-factory';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { BaymaxHeaderModule } from '../baymax-header/baymax-header.module';
import { DclBaseModalComponent } from './modal/base-modal.component';
import { DclBaseModalV2Component } from './v2/base-modal-v2.component';
import { DclLinkModalComponent } from './link-modal/link-modal.component';
import { LoadingContentModule } from '../loading-content/loading-content.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { ModalService } from './services/modal.service';
import { TrackLinkModule } from '../track-link/track-link.module';
import { WindowRef } from '../window-ref/window-ref.service';
import { HeaderLogoModule } from '../header-logo/header-logo.module';

@NgModule({
    declarations: [
        DclBaseModalComponent,
        DclBaseModalV2Component,
        DclLinkModalComponent
    ],
    imports: [
        BaymaxHeaderModule,
        CommonModule,
        DeviceDetectionModule,
        HeaderLogoModule,
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule,
        LoadingContentModule,
        LoadingSpinnerModule,
        MatDialogModule,
        TrackLinkModule,
        TranslateModule
    ],
    providers: [
        ModalService,
        WindowRef
    ],
    exports: [
        DclBaseModalComponent,
        DclBaseModalV2Component,
        DclLinkModalComponent,
        MatDialogModule
    ],
    entryComponents: [
        DclBaseModalComponent,
        DclBaseModalV2Component,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class BaseModalModule { }
