import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviceService } from '../device/device.service';
import { StickyBarDirective } from './sticky-bar.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        StickyBarDirective
    ],
    providers: [
        DeviceService
    ],
    exports: [
        StickyBarDirective
    ]
})
export class StickyBarModule { }
