<p *ngIf="'text' === linkItem?.type" class="{{linkItem.classes}}" [innerHTML]="linkItem.text | safeHtml"></p>
<span *ngIf="'title' === linkItem?.type" class="{{linkItem.classes}}">
    <ng-container *ngFor="let titleText of linkItem.items">
        <p *ngIf="'text' === titleText.type" class="{{titleText.classes}}" [innerHTML]="titleText.text | safeHtml"></p>
    </ng-container>
</span>
<img *ngIf="this.renderImage"
    [class]="linkItem.classes"
    [src]="linkItem.src || ''"
    [alt]="linkItem.altText"
    storyImage="true"
>