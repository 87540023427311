import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { ConfigService } from '@app/core/config.service';

@Pipe({
  name: 'absTrimmer',
})
export class AbsTrimmerPipe implements PipeTransform {

  release = {
    dcl: {
      baseURL: 'https://disneycruise.disney.go.com'
    },
    dlr: {
      baseURL: 'https://disneyland.disney.go.com'
    },
    wdw: {
      baseURL: 'https://disneyworld.disney.go.com'
    },
    hkdl: {
      baseURL: 'https://www.hongkongdisneyland.com'
    }
  };

  baseUrl;
  localeRegex = /\/([a-z]{2}((_|-)[A-z]{2})?)(\/|$)/;

  constructor(private logger: LoggerService, private configService: ConfigService, ) { }

  // function(input, replaceProdUrl, appendLocale)
  transform(value: any, replaceProdUrl: Boolean, appendLocale: Boolean): any {
    // TODO: create service for siteId
    const siteId = this.configService.getValue('siteId');
    let locale;

    if (!this.baseUrl) {
      this.baseUrl = '';
      this.baseUrl += window.location.protocol + '//';
      this.baseUrl += window.location.hostname;

      if (window.location.port !== '80' && window.location.port !== '443') {
        this.baseUrl += ':' + window.location.port;
      }
    }

    // In case appendLocale is true and the baseUrl does not yet contain the locale, add it
    if (appendLocale && !this.baseUrl.match(this.localeRegex)) {
      locale = window.location.pathname.match(this.localeRegex);

      if (locale) {
        this.baseUrl += '/' + locale[1];
      }
    }

    let result = '';

    // Avoid failure when input is undefined
    if (typeof value === 'undefined') {
        return value;
    }

    if (replaceProdUrl === true) {
      // Specifically remove production URL and replace with environment URL
      if (this.release[siteId] && this.release[siteId].baseURL) {
        result = value.replace(new RegExp(this.urlToRegExpString(this.release[siteId].baseURL), 'g'), this.baseUrl);
      } else {
        this.logger.warn('absTrimmer filter called with an undefined siteId');
      }
    } else {
        if (locale && value[0] === '/') {
          result = `/${locale[1]}${value}`;
        } else {
          // Force to environment specific URL regardless of domain.
        result = value.replace(/http(s)?:\/\/(.*?)\//g, this.baseUrl + '/');
        }
    }

    // Default to homepage if empty and add a trailing slash
    if (result === '' || result === this.baseUrl) {
      result = this.baseUrl + '/';
    }

    return result;
  }

  urlToRegExpString(url) {
    return url.replace(/https/g, 'http(s)?').replace(/\//g, '\/').replace(/\./g, '\.');
  }

}
