import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { CAST_USER_CONSTANTS } from './../cast-user.constants';
import { CastMemberData } from './../../interfaces/cast-user.interface';
import { CheckOfferElibilityResponse } from './../../interfaces/check-offer-eligibility-response.interface';

@Injectable()
export class CastUserRestService {
    private baseUrl: string;
    private pavasEndpointsEnabled = false;

    constructor(
        private httpClient: HttpClient
    ) {}

    /**
     * Enable product availability va endpoints
     * @param enable enable/disable product availability va endpoints
     */
    enablePavasEndpoints(enable: boolean) {
        this.pavasEndpointsEnabled = enable;
    }

    /**
     * Set base URL from the Spa that uses this service
     * It is mandatory to configure this url in the appModule
     * @param url base url to be set
     */
    setBaseUrl(url: string) {
        this.baseUrl = url;
    }

    /**
     * Call endpoint to get cast data using baseUrl
     * @returns {Observable<CastMemberData>}
     */
    getCastData(): Observable<CastMemberData> {
        const endpoint = this.pavasEndpointsEnabled
            ? CAST_USER_CONSTANTS.ENDPOINTS.CAST_INFO_PAVAS
            : CAST_USER_CONSTANTS.ENDPOINTS.CAST_INFO;

        return this.httpClient.get<CastMemberData>(`${this.baseUrl}${endpoint}`);
    }

    /**
     * Call endpoint to check offer eligibility using baseUrl
     * @param marketingOfferId id of offer to be checked
     * @returns {Observable<CheckOfferElibilityResponse>}
     */
    checkOfferEligibility(marketingOfferId: string): Observable<CheckOfferElibilityResponse> {
        const region = CAST_USER_CONSTANTS.SPECIAL_OFFERS.INTL_REGION +
            CAST_USER_CONSTANTS.SPECIAL_OFFERS.US_REGION;

        const endpoint = (this.pavasEndpointsEnabled
                ? CAST_USER_CONSTANTS.ENDPOINTS.CHECK_OFFER_ELIGIBILITY_PAVAS
                : CAST_USER_CONSTANTS.ENDPOINTS.CHECK_OFFER_ELIGIBILITY
            ).replace('{marketingOfferId}', encodeURIComponent(marketingOfferId))
            .replace('{region}', region);

        return this.httpClient.get<CheckOfferElibilityResponse>(`${this.baseUrl}${endpoint}`);
    }
}
