import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisibleMqService {

  currentMediaChangedSource: Subject<any> = new Subject<any>();
  currentMediaChanged$ = this.currentMediaChangedSource.asObservable();

  currentMedia = {
    matches: false,
    media: '',
    className: ''
  };

  visibleMediaQueries = {
    'visible-xs': window.matchMedia('(max-width: 567px)'),
    'visible-sm': window.matchMedia('(min-width: 568px) and (max-width: 767px)'),
    'visible-md': window.matchMedia('(min-width: 768px) and (max-width: 1199px)'),
    'visible-lg': window.matchMedia('(min-width: 1200px)')
  };

  constructor() {
    for (const i in this.visibleMediaQueries) {
      if (this.visibleMediaQueries.hasOwnProperty(i)) {
          this.visibleMediaQueries[i].addListener(e => this.getVisibleMedia());
      }
    }

    this.getVisibleMedia();
  }

  getVisibleMedia = function () {
    this.currentMedia.matches = false;
    this.currentMedia.media = this.currentMedia.className = '';

    for (const i in this.visibleMediaQueries) {
        if (this.visibleMediaQueries.hasOwnProperty(i) && this.visibleMediaQueries[i].matches) {
            this.currentMedia.matches = true;
            this.currentMedia.media = this.visibleMediaQueries[i].media;
            this.currentMedia.className = i;
            break;
        }
    }

    this.currentMediaChangedSource.next(this.currentMedia);
  };
}
