import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ScrollEventType } from '../interfaces/sticky-bar.interface';

@Component({
    selector: 'dcl-baymax-header',
    templateUrl: './baymax-header.component.html',
    styleUrls: ['./baymax-header.component.scss']
})
export class BaymaxHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() title: string;
    @Input() isCloseBtn: boolean;
    @Input() isModal: boolean;
    @Input() parent: string;
    @Input() animationDelay: number;
    @Input() parentWithScroll: HTMLElement;
    @Input() activeStickyTransition: boolean;
    @Input() activeBtnFocus: boolean;
    @Output() iconEvent = new EventEmitter();
    @Output() emitScrollEvent = new EventEmitter<ScrollEventType>();

    private baymaxElement;
    private iconElement;

    constructor() { }

    ngOnInit() {
    }

    // Method to emit the scroll event from the sticky bar event
    emitFromStickyEvent(data: ScrollEventType) {
        this.emitScrollEvent.emit(data);
    }

    ngAfterViewInit() {
        // Header under modal
        this.baymaxElement = document.querySelector('.baymax-header.is-not-modal');

        if (this.baymaxElement) {
            this.iconElement = this.baymaxElement.querySelector('.dcl-link');

            // If there are two headers in the same page (one in an open modal and the other in the page)
            // set page header tabindex to -1, so there is no conflict with the one in the modal
            if (this.isModal) {
                this.baymaxElement.setAttribute('aria-hidden', 'true');
                this.iconElement.setAttribute('tabindex', '-1');
            } else if (!this.isCloseBtn) {
                this.iconElement.setAttribute('tabindex', '0');

                if (this.activeBtnFocus) {
                    setTimeout(() => {
                        this.iconElement.focus();
                    }, 0);
                }
            }
        }

    }

    ngOnDestroy() {
        // When modal is closed, the page header returns to the default settings
        if (this.baymaxElement && this.isModal) {
            this.baymaxElement.removeAttribute('aria-hidden');
            this.iconElement.setAttribute('tabindex', '0');
        }
    }

    onIconClick() {
        this.iconEvent.emit();
    }
}
