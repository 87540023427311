import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ReservationIdService {
    private reservationId: Number;

    constructor() { }

    setId(reservationId: string): void {
        this.reservationId = Number(reservationId);
    }

    getId(): Number {
        return this.reservationId;
    }
}
