import { Observable } from 'rxjs';
import { share, finalize } from 'rxjs/operators';

/**
 * @author
 * Federico Cantera
 *
 * Decorator for multicasts (shares) the original Observable,
 * While plain Observables are unicast (each subscribed Observer owns an independent execution of the Observable),
 * Subjects are multicast, this is an alias for "multicast(() => new Subject()), refCount()", also we save
 * in memory cache the observable and reuse it meanwhile is not resolved
 *
 *
 * @see https://rxjs.dev/api/index/function/share
 *
 * @example
 * This example could represent the request to "authentication/status" that can be called
 * from several places, all calls at the same time will use one request ( until gets resolved or rejected )
 * instead of several if you apply the "@Share()" decorator to the service method
 *
 * ----------------------------------------------------
 * # Example of use
 * ----------------------------------------------------
 *  // applying the share decorator to "share" same request until gets resolved or rejected
 *  class MyService {
 *      constructor(private http: HttpClient) { }
 *
 *      @Share()
 *      myMethod() {
 *          return this.http.get('/api/authentication/status');
 *      }
 *  }
 *
 *  // use the service severals times
 *  class MyComponent {
 *      constructor(private myService: MyService) { }
 *
 *      ngOnInit() {
 *           myService.myMethod().subscribe();
 *           myService.myMethod().subscribe();
 *           myService.myMethod().subscribe();
 *      }
 *   }
 *
 */
export function Share() {
    return (
        // represents the class containing the method we are decorating
        target: Object,

        // represents the method name
        propertyKey: string,

        // contain the method implementation
        descriptor: PropertyDescriptor
    ) => {
        if (!descriptor.value) {
            return descriptor;
        }

        const originalMethod = descriptor.value;
        const cachePropName = Symbol('cacheProp');
        let cache: Map<string, Observable<any>>;
        let key: string;
        let loadingInProcess: Observable<any>;
        let updatedCall: Observable<any>;

        descriptor.value = function (this: any) {
            const args = arguments;
            const context = this;

            // if not exists create the cache bag
            if (!context[cachePropName]) {
                context[cachePropName] = new Map();
            }

            // point to the cache bag for this request
            cache = context[cachePropName];

            // create an unique cache key based on "class name" + "method name" (ie: "MyService-myMethod")
            key = `${context.constructor.name}-${String(propertyKey)}`;

            updatedCall = (originalMethod.apply(context, args as any) as Observable<any>)
                .pipe(
                    share(),
                    // lets delete the cache instance once call finalize
                    finalize(() => cache.delete(key))
                );

            // try to get the cache if exists
            loadingInProcess = cache.get(key);

            // use the cache instance when possible
            if (loadingInProcess) {
                return loadingInProcess;
            }

            // set the cache for this call and return it to use it
            cache.set(key, updatedCall);

            return updatedCall;
        };

        return descriptor;
    };
}
