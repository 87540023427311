import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CssLoaderComponent } from '@app/shared/components/css-loader/css-loader.component';
import { SafeHtmlPipe } from '@app/shared/utils/pipes/safe-html.pipe';
import { ScriptLoaderComponent } from '@app/shared/components/script-loader/script-loader.component';
import { WindowRef } from '@app/shared/services/window-ref/window-ref.service';
import { FontIconComponent } from '@app/shared/components/font-icon/font-icon.component';
import { FontIconLoaderComponent } from '@app/shared/components/font-icon-loader/font-icon-loader.component';

const exportableComponents = [
    CssLoaderComponent,
    ScriptLoaderComponent,
    FontIconComponent,
    FontIconLoaderComponent
];

const exportablePipes = [
    SafeHtmlPipe,
];

const exportableProviders = [
    WindowRef,
];

const exportableDirectives = [
];

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [exportableComponents, exportablePipes, exportableDirectives],
    exports: [exportableComponents, exportablePipes, exportableDirectives],
    // entryComponents: exportableComponents,
    providers: exportableProviders,
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ComponentsModule {
}
