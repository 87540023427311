import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { TogglesInterface } from '../interfaces/toggles.interface';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(
        private httpClient: HttpClient
    )  {}

    /**
     * Get toggle configuration in admin svc
     * @param url - webapi url
     * @return AdminToggles
     */
    getConfiguration(url: string): Observable<TogglesInterface> {
        return this.httpClient.get<TogglesInterface>(url);
    }
}
