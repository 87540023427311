import { Pipe, PipeTransform } from '@angular/core';

import { PROD_URL } from './abs-trimmer.constants';
import { WindowRef } from '../window-ref/window-ref.service';

@Pipe({
    name: 'absTrimmer'
})
export class AbsTrimmerPipe implements PipeTransform {

    constructor(
        private windowRef: WindowRef
    ) { }

    transform(value: string, replaceProdUrl?: boolean): string {
        const urlDomain = this.windowRef.nativeWindow.location.origin;
        let url;
        let val = value && value.trim();

        if (!val) {
            return this.windowRef.nativeWindow.location.href;
        }

        val = val.replace(/^\//g, '');
        url = `${replaceProdUrl ? PROD_URL : urlDomain}/${val}`;

        return url;
    }
}
