<dcl-featured-cruise-tiles
    *ngIf="isActiveFeaturedCruiseTiles"
    appTrackSeen
    [attr.wdpr-analytics-track-seen]="trackSeenModelProps"
    [customImgPlaceholder]="customPlaceholder"
    [cruiseTiles]="cruisesTiles"
    [isHomePage]="true"
    [cruiseName]="buttonText"
    [trackSeenSendModel]="true"
    (onFeaturedCruiseSelected)="onFeaturedCruiseSelected($event)"
    (onExploreAllCruisesSelected)="onExploreAllCruisesSelected()">
</dcl-featured-cruise-tiles>
