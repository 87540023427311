import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { BreakPointChangeDetectionModule } from '../breakpoint-change-detection/breakpoint-change-detection.module';
import { CarouselModule } from '../carousel/carousel.module';
import { CruiseTileComponent } from './components/cruise-tile/cruise-tile.component';
import { FeaturedCruiseTilesComponent } from './featured-cruise-tiles.component';
import { HeroImageModule } from '../hero-image/hero-image.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        BreakPointChangeDetectionModule,
        CarouselModule,
        CommonModule,
        HeroImageModule,
        LoadingSpinnerModule,
        TranslateModule
    ],
    declarations: [
        CruiseTileComponent,
        FeaturedCruiseTilesComponent
    ],
    exports: [
        CruiseTileComponent,
        FeaturedCruiseTilesComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class FeaturedCruiseTilesModule {}
