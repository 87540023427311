import { Component } from '@angular/core';
import { StoryAbstractComponent } from '../shared/story-abstract.component';
import { get } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'pixie-awards',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent extends StoryAbstractComponent {
  constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
    super(sanitizer, cookieService);
  }
  getTemplateBlockGroups(storyData: any) {
    const itemsWrapped = this._generateAwardsBlockItems(storyData);

    const templateBlockGroups = [
      {
          classes: 'full-desktop',
          anchorName: 'awards-block-group-1-anchor',
          blocks: [
              {
                  analyticsTrackSeen: this.createAnalyticsTrackSeen(storyData, 1),
                  classes: 'full-desktop story-block-1 story-block-row ',
                  items: [
                      {
                          type: 'link',
                          classes: '',
                          href: storyData.descriptions.logoBlock.link,
                          target: get(storyData, 'descriptions.logoBlock.target', 'self'),
                          analyticsLinkId: this.getStoryAnalyticslinkId(storyData,
                              1,
                              storyData.descriptions.logoBlock.analyticsLinkId),
                          itemsWrapped
                      }
                  ]
              }
          ]
      }
    ];

    return templateBlockGroups;
  }

  /*
   * Construct Awards block items based on the available content
   * @param {Object} story data
   * @returns {Array} of generated images
   */
  _generateAwardsBlockItems(storyData) {
    const itemsWrapped = {
      background: [],
      foreground: []
    };

    for (let i = 1; i <= 5; i++) {
        if (storyData.descriptions.logoBlock.media['media' + i]) {
          const item = this.getImageTemplate(
            storyData.descriptions.logoBlock.media['media' + i],
            'background'
          );
          itemsWrapped.background.push(item);
        }
    }

    return itemsWrapped;
  }
}
