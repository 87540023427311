import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { get, isString } from 'lodash';

import { BASE_MODAL_CONSTANTS } from '../base-modal.constants';

@Injectable()
export class ModalService {

    constructor(
        private dialog: MatDialog,
        private translate: TranslateService
    ) { }

    /**
     * Open modal with entry config and data
     * @param data config and data associated with the modal
     */
    openModal(data): void {
        const panel = get(data, 'config.panel') || '';
        const panelClass = isString(panel) ? panel : [...panel];
        const modal = this.dialog.open(data.component, {
            panelClass: panelClass,
            backdropClass: data.config.backdrop,
            disableClose: !!data.config.disableClose,
            autoFocus: false,
            ariaLabel: (data.data.title && this.translate.instant(data.data.title)) || '',
            data: {
                ...data.data
            }
        });
        const wrapper = document.querySelector('.cdk-overlay-container');
        let afterOpened;

        // In order to support Angular 5 and 8
        if (modal['afterOpened']) {
            afterOpened = modal['afterOpened'].bind(modal);
        } else {
            afterOpened = modal['afterOpen'].bind(modal);
        }

        afterOpened().subscribe(() => {
            if (wrapper && data.data.animateClass) {
                wrapper.classList.add(data.data.animateClass);
            }
        });

        modal.afterClosed().subscribe(() => {
            // We remove either animate or no-animate class from the panel.
            if (wrapper && data.data.animateClass) {
                wrapper.classList.remove(data.data.animateClass);
            }

            if (data.data.scrollTop) {
                window.scrollTo(0, 0);
            }
        });
    }

    /**
     * prevents scroll function to be called in ios when it is not necessary.
     * This removes the scroll in ios, when the content doesn't need scroll
     * @param selector
     */
    removeIosScroll(selector?: string): void {
        const modalContainer = document.querySelector(selector || BASE_MODAL_CONSTANTS.CLASS_CONTAINER);
        const disableScroll = modalContainer['scrollHeight'] <= modalContainer['clientHeight'];

        if (disableScroll) {
            modalContainer.addEventListener('touchmove', event => {
                event.preventDefault();
            });
        }
    }

    /**
     * returns baymax header to modal container.
     * When the modal is opened in ios, the baymax header is added to body
     * so if the modal is closed in ios, the baymax is added to modal again
     * that way the modal closes with the baymax inside
     * @param parent baymax original container
     * @param child baymax header content
     */
    addBaymaxHeaderToModal(parent?: string, child?: string): void {
        const baymaxContainer = document.querySelector(parent || BASE_MODAL_CONSTANTS.BAYMAX_CLASS_CONTAINER);
        const baymax = document.querySelector(child || BASE_MODAL_CONSTANTS.BAYMAX_CLASS_CHILD);

        baymaxContainer.appendChild(baymax);
    }
}
