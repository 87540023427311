import { animate, style, trigger, transition } from '@angular/animations';

const spinnerContentInAnimation = trigger('spinnerContentIn', [
    transition(':enter', [
        style({
            visibility: 'hidden',
            overflow: 'hidden',
            maxHeight: 0
        }),
        animate('1ms 250ms', style({
            visibility: 'visible',
            maxHeight: 'none'
        }))
    ])
]);

export { spinnerContentInAnimation };
