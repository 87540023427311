import { _throw } from 'rxjs/observable/throw';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { values } from 'lodash';

import { CAST_USER_CONSTANTS } from '../../cast-user/cast-user.constants';
import { CastUserSessionService } from './../../cast-user/session/cast-user-session.service';
import { DclBaseModalComponent } from '../../base-modal/modal/base-modal.component';
import { ErrorOptions } from '../interfaces/error-options.interface';
import { HANDLE_ERRORS_CAST_CONSTANTS } from '../cast/handle-errors.cast.constants';
import { ModalService } from '../../base-modal/services/modal.service';
import { WindowRef } from '../../window-ref/window-ref.service';

@Injectable()
export class HandleErrorsCastService {
    private castErrorsValues: Array<String>;

    constructor(
        private castUserSessionService: CastUserSessionService,
        private httpClient: HttpClient,
        private modalService: ModalService,
        private router: Router,
        private cookieService: CookieService,
        private windowRef: WindowRef
    ) {
        this.castErrorsValues = values(HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES);
    }

    /**
     * Validate if the error is a cast type error
     * @param {string} errorCode
     * @returns {boolean}
     */
    isCastError(errorCode) {
        return this.castErrorsValues.indexOf(errorCode) !== -1;
    }

    /**
     * Handle cast error depending on status code
     * @param statusCode status code
     * @param errorCode error code that defines what kind error is coming
     * @param options options to handle the error
     * @returns throw an error with the given statusCode
     */
    handleCastError(statusCode: number, errorCode: string, options: ErrorOptions): Observable<string> {
        if (statusCode === HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_STATUS.UNAUTHORIZED) {
            return this.openCastModal(options);
        } else if (statusCode === HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_STATUS.FORBIDDEN) {
            return this.handleCastErrorCallback(errorCode, options);
        }

        return _throw(errorCode);
    }

    /**
     * Remove cast cached data and access token cookie
     */
    removeCastData(): void {
        this.cookieService.delete(CAST_USER_CONSTANTS.CAST_COOKIE, CAST_USER_CONSTANTS.PATH_COOKIE);
        this.windowRef.nativeWindow.sessionStorage.removeItem(CAST_USER_CONSTANTS.STORAGE_KEY);
    }

    /**
     * Open a modal that informs that MyID session has expired and ask for login again
     * @param options options to handle the error
     * @returns Observable attached to a modal
     */
    private openCastModal(options: ErrorOptions): Observable<string> {
        const subject = new Subject<string>();
        const buttons = [{
                theme: 'primary',
                text: HANDLE_ERRORS_CAST_CONSTANTS.SESSION_EXPIRED_MODAL.LOGIN_BUTTON_LABEL,
                action : () => {
                    this.windowRef.nativeWindow.location.href = (options.myIdUrl).replace(
                        '{redirectURL}',
                        this.windowRef.nativeWindow.location.href
                    );
                    subject.error(HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.ACCEPT_LOGIN);
                    subject.complete();
                }
            }, {
                theme: 'secondary',
                text: HANDLE_ERRORS_CAST_CONSTANTS.SESSION_EXPIRED_MODAL.CANCEL_BUTTON_LABEL,
                action : () => {
                    this.handleCastErrorCallback(HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.CANCEL_LOGIN, options)
                        .subscribe(() => {}, () => {
                            subject.error(HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.CANCEL_LOGIN);
                            subject.complete();
                        });
                }
            }];

            this.modalService.openModal({
                component: DclBaseModalComponent,
                config: {
                    panel: ['base-dialog', 'medium'],
                    backDrop: 'dcl-dialog-backdrop',
                    disableClose: true
                },
                data: {
                    title: HANDLE_ERRORS_CAST_CONSTANTS.SESSION_EXPIRED_MODAL.TITLE,
                    body: HANDLE_ERRORS_CAST_CONSTANTS.SESSION_EXPIRED_MODAL.BODY,
                    isTitleLogo: false,
                    isClose: false,
                    buttons,
                    animateClass: 'no-animate',
                    scrollTop: true
                }
            });

            return subject;
        }

    /**
     * Handle the error by removing promo code from session manager
     * @param errorCode error code that defines what kind error is coming
     * @param options options to handle the error
     * @returns throw an error with the given statusCode
     */
    private handleCastErrorCallback(errorCode: string, options: ErrorOptions): Observable<string> {
        const subject = new Subject<string>();

        this.httpClient.delete<null>(HANDLE_ERRORS_CAST_CONSTANTS.SESSION_MANAGER_URL).pipe(
            catchError(() => errorCode)
        ).subscribe(() => {
            this.handleErrorRedirect(errorCode, options);
            subject.error(errorCode);
            subject.complete();
        });

        return subject;
    }

    /**
     * Handle final treatment depending on the error code and its respective redirection
     * @param errorCode error code that define what kind error is coming
     * @param options options to handle the error
     */
    private handleErrorRedirect(errorCode: string, options: ErrorOptions): void {
        switch (errorCode) {
            case HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.CANCEL_LOGIN:
                this.removeCastData();
                this.windowRef.nativeWindow.location.reload();
                break;
            case HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.NO_CAST:
            case HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.FORBIDDEN:
                this.removeCastData();
                this.goTo(options.errorState, false, HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_STATUS.FORBIDDEN);
                break;
            case HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.NO_APPLIED_FOR_OFFER:
                this.castUserSessionService.removeOfferData();
                this.goTo(HANDLE_ERRORS_CAST_CONSTANTS.SPECIAL_OFFERS_URL, true);
                break;
            case HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.DATA_NOT_MATCHED:
                this.castUserSessionService.removeOfferData();
                this.goTo(HANDLE_ERRORS_CAST_CONSTANTS.SPECIAL_OFFERS_URL, true);
                break;
            case HANDLE_ERRORS_CAST_CONSTANTS.ERRORS_CODES.MISSING_DATA:
                this.castUserSessionService.removeOfferData();
                this.windowRef.nativeWindow.location.reload();
                break;
        }
    }

    /**
     * Navigate to another page/state
     * @param state url or state to redirect
     * @param redirect flag that defines if navigation should be done in the same spa
     * @param statusCode [optional] status code, only needed to go to error page
     */
    private goTo(state: string, redirect: boolean, statusCode?: number) {
        if (redirect) {
            this.windowRef.nativeWindow.location.href = state;
        } else {
            this.router.navigate([state], {
                queryParams: { statusCode },
                skipLocationChange: true
            });
        }
    }
}
