import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { GridComponent } from './components/grid/grid.component';
import { GridPickerComponent } from './grid-picker.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        GridComponent,
        GridPickerComponent
    ],
    exports: [
        GridPickerComponent
    ]
})
export class GridPickerModule { }
