const TRAVEL_AGENT_CONSTANTS = {
    AGENCY_ID_PREFIX: 'AgencyId_',
    COOKIES: {
        TA_INFO: 'dclTravelAgentInfo',
        AGENCY_ID: 'AgencyId',
        CONVERSATION_UUID: 'Conversation_UUID',
        TOKEN: 'pep_oauth_token',
        TOKEN_EXPIRATION: 'pep_oauth_token_expiration',
        TOKEN_START_TIME: 'pep_oauth_token_start_time',
        TOKEN_REFRESH: 'pep_oauth_refresh_token',
        TOKEN_REFRESH_PP: 'pep_oauth_refresh_token_pp',
        SESSION_TTL: 'Travel-Agent-Session-Time',
        SESSION_GUID: 'TA-Session-Guid',
        TICKET_DATA: 'TICKET_DATA_jar',
        TA_SWID: 'GuestSwid',
        TA_AUTH: 'Travel-Agent-Authorization',
        SWID: 'SWID',
        GUEST_INFO: 'guestInfo_jar',
        JWT_TOKEN: 'pep_jwt_token',
        REMEMBER_ME: 'rememberme',
        CART_ID_MAPPING: 'cartIdMapping',
        SESSION_TIMEOUT: 'SESSION_TIMEOUT',
        PHPSESSID: 'PHPSESSID',
        COOKIE_OPTIONS: {
            path: '/',
            domain: document.domain,
            secondLevelDomain: document.domain.split('.').slice(-2).join('.'),
            thirdLevelDomain: document.domain.split('.').slice(-3).join('.'),
            sameSite: <'Lax' | 'None' | 'Strict'>'Lax'
        },
        LOCALE_COOKIE: {
            cookieName: 'localeCookie_jar',
            cookieNameAka: 'localeCookie_jar_aka',
            expireDays: 7,
            precedence: 0,
            version: '2',
            defaultCountry: 'US',
            defaultCurrency: 'USD',
            currency: {
                USD: {
                    currencyCode: 'USD',
                    contentLocale: 'en_US',
                    preferredRegion: 'en_US'
                },
                GBP: {
                    currencyCode: 'GBP',
                    contentLocale: 'en_GB',
                    preferredRegion: 'en_GB'
                },
                EUR: {
                    currencyCode: 'EUR',
                    contentLocale: 'en_EU',
                    preferredRegion: 'en_EU'
                }
            }
        },
        INTERNATIONAL_COOKIE: {
            cookieName: 'localeCookie_jar',
            cookieNameAka: 'localeCookie_jar_aka',
            expireDays: 7,
            precedence: 0,
            version: '2',
            defaultCountry: 'US',
            defaultCurrency: 'USD',
            currency: {
                AR: { currencyCode: 'ARS', contentLocale: 'es_AR', preferredRegion: 'es_AR' },
                AU: { currencyCode: 'AUD', contentLocale: 'en_AU', preferredRegion: 'en_au' },
                BR: { currencyCode: 'BRL', contentLocale: 'pt_BR', preferredRegion: 'pt_br' },
                CA: { currencyCode: 'CAD', contentLocale: 'en_CA', preferredRegion: 'en_ca' },
                CL: { currencyCode: 'CLP', contentLocale: 'es_CL', preferredRegion: 'es_CL' },
                CO: { currencyCode: 'COP', contentLocale: 'es_CO', preferredRegion: 'es_CO' },
                EU: { currencyCode: 'EUR', contentLocale: 'en_EU', preferredRegion: 'en_EU' },
                GB: { currencyCode: 'GBP', contentLocale: 'en_GB', preferredRegion: 'en_GB' },
                MX: { currencyCode: 'MXN', contentLocale: 'es_MX', preferredRegion: 'es_MX' },
                NZ: { currencyCode: 'NZD', contentLocale: 'en_NZ', preferredRegion: 'en_nz' },
                PE: { currencyCode: 'PER', contentLocale: 'es_PE', preferredRegion: 'es_PE' },
                US: { currencyCode: 'USD', contentLocale: 'en_US', preferredRegion: 'en_US' },
            }
        }
    },
    SHOW_CONVERTION_COUNTRIES_PHASE1: ['CA', 'AU', 'NZ'],
    SHOW_CONVERTION_COUNTRIES_PHASE2: ['BR', 'CO', 'AR', 'CL', 'MX', 'PE'],
    SESSION_MIN: 20,
    SESSION_HOUR_SEC: 3600,
    DEFAULT_DAY_EXPIRATION_MIN: 1440,
    AFFILIATION_LABEL: 'Affiliation Number',
    STORAGE: {
        RECENT_RESERVATIONS: 'taRecentReservations',
        ACTIVE_SESSION: 'taSession',
        DTA_REFERRER: 'dtaReferrer',
        GUEST_CASTAWAY_INFO: 'taGuestCastawayInfo',
        ADRUM_CLIENTINFO: 'ADRUM_CLIENTINFO'
    },
    HEADERS: {
        AUTHORIZATION: 'Authorization'
    },
    ENDPOINTS: {
        AGENCY_TOKEN: 'auth-token/',
        AGENCY_LOOKUP: 'dcl-session-mgr-svc/agencies/lookup/',
        AGENCY_PASSPORT: 'dcl-session-mgr-svc/agencies/passport/',
        AGENCY_LOGOUT: 'dcl-session-mgr-svc/agencies/sessions/delete/',
        LOGOUT: '/travel-agent-logout/?rs=time-out'
    },
    TOKEN_PREFIX: 'BEARER',
    FILTERED_REQUESTS_TIMEOUT: 'config|en.json|authz|toggles|page-key|auth-token|lookup',
    DEFAULT_RESERVATION_ID: '11111111',
    LEVEL_KEYS: {
        SILVER: 'travelAgent.clubLevel.silver',
        GOLD: 'travelAgent.clubLevel.gold',
        GOLDEN_OAK: 'travelAgent.clubLevel.goldenOak',
        CLUB33: 'travelAgent.clubLevel.club33',
        DVC: 'travelAgent.clubLevel.dvc',
        PEARL: 'travelAgent.clubLevel.pearl',
        PLATINUM: 'travelAgent.clubLevel.platinum',
        PLAT_25: 'travelAgent.clubLevel.plat25',
        PLAT_20: 'travelAgent.clubLevel.plat20',
        PLAT_15: 'travelAgent.clubLevel.plat15',
        PLAT_10: 'travelAgent.clubLevel.plat10'
    },
    BRANDS_WITHOUT_ID_NUMBER: [
        'DVC',
        'CLUB33',
        'GOLDEN_OAK'
    ]
};

export { TRAVEL_AGENT_CONSTANTS };
