import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule } from '@ngx-translate/core';

import { AdvancedBookingBannerModule } from '../advanced-booking-banner/advanced-booking-banner.module';
import { BaymaxHeaderModule } from '../baymax-header/baymax-header.module';
import { DocumentRef } from '../document-ref/document-ref.service';
import { DclClickOutsideModule } from '../click-outside/click-outside.module';
import { GridPickerModule } from '../grid-picker/grid-picker.module';
import { ImagesPickerModule } from './../images-picker/images-picker.module';
import { NavigatorRef } from '../navigator-ref/navigator-ref.service';
import { NewItinerariesBannerService } from './services/new-itineraries.banner.service';
import { NewItinerariesV2BannerService } from './services/new-itineraries-v2.banner.service';
import { NewItinerariesV3BannerService } from './services/new-itineraries-v3.banner.service';
import { OptionsGroupsModule } from './../options-groups/options-groups.module';
import { QuickQuoteComponent } from './quick-quote.component';
import { QuickQuoteBarComponent } from './components/quick-quote-bar/quick-quote-bar.component';
import {
    QuickQuoteFilterWrapperComponent
} from './components/quick-quote-filter-wrapper/quick-quote-filter-wrapper.component';
import { QuickQuoteModalComponent } from './components/quick-quote-modal/quick-quote-modal.component';
import { TravelPartyModule } from './../travel-party/travel-party.module';
import { WindowRef } from '../window-ref/window-ref.service';
import { AbsTrimmerPipe } from '../abs-trimmer/abs-trimmer.pipe';

@NgModule({
    imports: [
        AdvancedBookingBannerModule,
        BaymaxHeaderModule,
        CommonModule,
        GridPickerModule,
        ImagesPickerModule,
        DclClickOutsideModule,
        OptionsGroupsModule,
        TranslateModule,
        TravelPartyModule
    ],
    declarations: [
        QuickQuoteComponent,
        QuickQuoteBarComponent,
        QuickQuoteFilterWrapperComponent,
        QuickQuoteModalComponent
    ],
    providers: [
        AbsTrimmerPipe,
        CookieService,
        DocumentRef,
        NavigatorRef,
        NewItinerariesBannerService,
        NewItinerariesV2BannerService,
        NewItinerariesV3BannerService,
        WindowRef
    ],
    exports: [
        QuickQuoteComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class QuickQuoteModule {}
