const ANALYTICS_CONSTANTS = {
    API_BASE: 'baseHref',
    // Can't disable autoPageViewTracking so we add an infinite timeout delay
    AUTO_PAGE_VIEW_TRACKING_TIMING: 9999999,
    ANALYTICS_PAGE_KEY_TIMING: 100,
    EVENTS: {
        SELF_SERVICE_INITIATED: 'selfServiceInitiated'
    },
    PRODUCT: {
        TYPE: 'cruise'
    },
    SERVER_PATH: '/analytics',
    TOGGLES: {
        DCL_INTL_DIRECT: 'dclInternationalDirect'
    },
    TRAVEL_AGENT: {
        LOGGED_IN_EVENT: 'loggedIn',
        LOGGED_IN_SESSION_STORAGE_ID: 'analytics-ta-logged-in-state',
        STORE: 'TravelAgent',
        STORETYPE: 'TravelAgent'
    }
};

export { ANALYTICS_CONSTANTS };
