const CART_CONSTANTS = {
    COOKIES: {
        SAILING_ID: 'sailingId',
        CART_ID_MAPPING: 'cartIdMapping',
        COOKIE_OPTIONS: {
            path: '/',
            domain: document.domain,
            sameSite: <'Lax' | 'None' | 'Strict'> 'Lax'
        }
    },
    ENDPOINTS: {
        DELETE_CART: 'cruise-booking-service/carts/{cartId}/voyage/{voyageId}'
    }
};

export { CART_CONSTANTS };
