import { Injectable } from '@angular/core';

import { ItemStorage } from '../interfaces/item-storage';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() { }

    /**
     * Set item into localStorage
     * @params key in localStorage
     * @params value to be saved in localStorage
     */
    setItemStorage(key: string, value: ItemStorage | ItemStorage[]): void {
        const valueToSave = this.formatItemStorage(value);

        localStorage.setItem(key, valueToSave);
    }

    /**
     * Remove item into localStorage
     * @params key in localStorage
     */
    removeItemStorage(key: string): void {
        localStorage.removeItem(key);
    }

    /**
     * Check if item has not been saved in localStorage
     * @params key in localStorage that we want to check its value
     * @params item to be checked if it is stored in localStorage
     * @returns if item was previously saved
     */
    checkItemSaved(key: string, item: ItemStorage): boolean {
        const currentValue = this.formatItemStorage(item);
        const previousValue = this.getItemStorage(key);

        return currentValue !== previousValue;
    }

    /**
     * Format item storage to string value
     * @params item to be formatted
     * @returns Item as a string value
     */
    formatItemStorage(item: ItemStorage | ItemStorage[]): string {
        let itemFormatted: string;

        try {
            itemFormatted = JSON.stringify(item);
        } catch (error) {
            itemFormatted = null;
        }

        return itemFormatted;
    }

    /**
     * Get item from local storage
     * @params key to be got
     * @params parseData [parseData=false] if data should be parsed
     * @returns Item as a string value
     */
    getItemStorage(key: string, parseData: boolean = false): string | ItemStorage | ItemStorage[] {
        let itemStorage = localStorage.getItem(key);

        if (parseData) {
            try {
                itemStorage = JSON.parse(itemStorage);
            } catch (error) {
                itemStorage = null;
            }
        }

        return itemStorage;
    }
}
