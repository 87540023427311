import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
    ChangeDetectorRef,
    Output,
    EventEmitter
} from '@angular/core';
import { flatMap, takeUntil, delay} from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { of } from 'rxjs/observable/of';
import { Subscription } from 'rxjs/Subscription';

import { Direction } from './enums/direction.enum';
import { EventTrigger } from './enums/event-trigger.enum';
import { POPOVER_CONSTANTS } from './popover.constants';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';
import { WindowRef } from '../window-ref/window-ref.service';

@Component({
    selector: 'dcl-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss']
})

export class PopoverComponent implements AfterViewInit, OnDestroy {
    @Input() direction: Direction;
    @Input() delay: number = 0;
    @Input() text: string;
    @Input() title: string;
    @Input() trigger: EventTrigger = EventTrigger.click;
    @Input() isCloseButton: boolean;
    @Output() onCloseIconClick = new EventEmitter<void>();
    @ViewChild('popoverElement', { static: false } as ViewChildProperties) popoverElement: ElementRef;

    leftOutside: boolean;
    mouseEventSubscriptions: Subscription;
    rightOutside: boolean;
    showPopover: boolean;

    constructor(
        private detector: ChangeDetectorRef,
        private elementRef: ElementRef,
        private windowService: WindowRef
    ) { }

    ngAfterViewInit(): void {
        if (this.trigger === EventTrigger.hover) {
            this.addHoverListeners();
        }
    }

    /**
     * This function creates a event mouse click or leave listener
     * and creates a subscription for wich one
     */
    addOnOuterClickEvent(): void {
        const clickEvent = fromEvent(this.windowService.nativeWindow, 'click');

        this.mouseEventSubscriptions = clickEvent.subscribe((event: MouseEvent) => {
            if (!this.isClickInside(event)) {
                this.onClose();
            }
        });
    }

    /**
     * This function creates enter and leave event listener and creates a subscription for each one
     * The flapmap & takeUntil functions are to avoid the mouseleave event hide the popover
     * when the mouseenter event is active.
     */
    addHoverListeners(): void {
        const show$ = fromEvent(this.elementRef.nativeElement, 'mouseenter');
        const hide$ = fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(
            flatMap((event: MouseEvent) => {
                return of(event).pipe(
                    delay(this.delay),
                    takeUntil(show$)
                );
            })
        );

        this.mouseEventSubscriptions = show$.subscribe(() => {
            this.onOpen();
        });

        this.mouseEventSubscriptions.add(hide$.subscribe(() => {
            this.onClose();
        }));
    }

    /**
     * Handles the key down event for the popover tab navigation and open/close events
     * with ENTER, ESCAPE, TAB or SHIFT + TAB keys
     */
    toggleVisibility(): void {
        this.showPopover ? this.onClose() : this.onOpen();
    }

    /**
     * Calls the function that handle the visibility (open) of popover
     */
    onOpen(): void {
        this.showPopover = true;
        this.checkOutside();

        if (this.trigger === EventTrigger.click) {
            this.addOnOuterClickEvent();
        }
    }

    /**
     * Calls the function that handle the visibility (close) of popover
     */
    onClose(): void {
        this.showPopover = false;
        this.detector.detectChanges();

        if (this.trigger === EventTrigger.click) {
            this.unsuscribeListener();
        }
    }

    /**
     * Retrieve if the click event
     * was inside or outside the component
     * @param event MouseEvent
     * @return true if the click was inside or false otherwise
     */
    isClickInside(event: MouseEvent): boolean {
        return this.elementRef.nativeElement.contains(event.target);
    }

    /**
     * Check if the popover is outside of the viewport
     */
    checkOutside(): void {
        this.detector.detectChanges();

        setTimeout(() => {
            if (this.popoverElement) {
                const bounding = this.popoverElement.nativeElement.getBoundingClientRect();
                const windowWidth = this.windowService.nativeWindow.innerWidth;

                if (this.showPopover && !this.leftOutside) {
                    this.rightOutside = (windowWidth - bounding.right) < 1;
                    this.leftOutside = bounding.left < 1;
                }
            }
        }, POPOVER_CONSTANTS.ANIMATION_DELAY);
    }

    /**
     * Delete the subscription depending the mouse event config
     */
    unsuscribeListener(): void {
        if (this.mouseEventSubscriptions) {
            this.mouseEventSubscriptions.unsubscribe();
        }
    }

    /**
     * Close via click in icon
     */
    onIconClick(): void {
        this.onClose();
        this.onCloseIconClick.emit();
    }

    /**
     * deleting the subscription when the component is destroyed
     */
    ngOnDestroy(): void {
        this.unsuscribeListener();
    }
}
