import { TravelAgentInfo } from '../interfaces/travel-agent.interface';

/**
 * Builder with the responsability to provide a flexible, structured and clean way
 * to create, assign and save the value of the travel agent information into the
 * `TICKET_DATA jar` cookie and send it successfully to our syndicate header
 */
 export class TravelAgentInfoBuilder {

    private travelAgentInfo: TravelAgentInfo;

    constructor() {
        this.travelAgentInfo = {
            id: '',
            agencyName: '',
            country: '',
            city: '',
            state: '',
            zipCode: '',
            addressLine1: '',
            addressLine2: '',
            phone: '',
            partnerId: '',
            partnerIdType: '',
            agentId: '',
            firstName: '',
            lastName: '',
            email: '',
            returnUrl: '',
            defaultCurrency: ''
        };
    }

    /**
     * Sets the value of the travel agent id
     * @param {string} id
     * @returns {TravelAgentInfoBuilder}
     */
    public setId(id: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.id = id;

        return this;
    }

    /**
     * Sets the value of the travel agent name
     * @param {string} name
     * @returns {TravelAgentInfoBuilder}
     */
    public setAgencyName(name: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.agencyName = name;

        return this;
    }

    /**
     * Sets the value of the travel agent country
     * @param {string} country
     * @returns {TravelAgentInfoBuilder}
     */
    public setCountry(country: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.country = country;

        return this;
    }

    /**
     * Sets the value of the travel agent city
     * @param {string} city
     * @returns {TravelAgentInfoBuilder}
     */
    public setCity(city: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.city = city;

        return this;
    }

    /**
     * Sets the value of the travel agent state
     * @param {string} state
     * @returns {TravelAgentInfoBuilder}
     */
    public setSate(state: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.state = state;

        return this;
    }

    /**
     * Sets the value of the travel agent zipCode
     * @param {string} zipCode
     * @returns {TravelAgentInfoBuilder}
     */
    public setZipCode(zipCode: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.zipCode = zipCode;

        return this;
    }

    /**
     * Sets the value of the travel agent addressLine1
     * @param {string} addressLine
     * @returns {TravelAgentInfoBuilder}
     */
    public setAddressLine1(addressLine: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.addressLine1 = addressLine;

        return this;
    }

    /**
     * Sets the value of the travel agent addressLline2
     * @param {string} addressLine2
     * @returns {TravelAgentInfoBuilder}
     */
    public setAddressLine2(addressLine: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.addressLine2 = addressLine;

        return this;
    }

    /**
     * Sets the value of the travel agent phone
     * @param {string} phone
     * @returns {TravelAgentInfoBuilder}
     */
    public setPhone(phone: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.phone = phone;

        return this;
    }

    /**
     * Sets the value of the travel agent partnerId
     * @param {string} partnerId
     * @returns {TravelAgentInfoBuilder}
     */
    public setPartnerId(partnerId: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.partnerId = partnerId;

        return this;
    }

    /**
     * Sets the value of the travel agent partnerIdType
     * @param {string} partnerIdType
     * @returns {TravelAgentInfoBuilder}
     */
    public setPartnerIdType(partnerIdType: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.partnerIdType = partnerIdType;

        return this;
    }

    /**
     * Sets the value of the travel agent agentId
     * @param {string} agentId
     * @returns {TravelAgentInfoBuilder}
     */
    public setAgentId(agentId: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.agentId = agentId;

        return this;
    }

    /**
     * Sets the value of the travel agent firstName
     * @param {string} firstName
     * @returns {TravelAgentInfoBuilder}
     */
    public setFirstName(firstName: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.firstName = firstName;

        return this;
    }

    /**
     * Sets the value of the travel agent lastName
     * @param {string} lastName
     * @returns {TravelAgentInfoBuilder}
     */
    public setLastName(lastName: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.lastName = lastName;

        return this;
    }

    /**
     * Sets the value of the travel agent email
     * @param {string} email
     * @returns {TravelAgentInfoBuilder}
     */
    public setEmail(email: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.email = email;

        return  this;
    }

    /**
     * Sets the value of the travel agent url
     * @param {string} url
     * @returns {TravelAgentInfoBuilder}
     */
    public setReturnUrl(url: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.returnUrl = url;

        return this;
    }

    /**
     * Sets the value of the travel agent defaultCurrency
     * @param {string} currency
     * @returns {TravelAgentInfoBuilder}
     */
    public setDefaultCurrency(currency: string): TravelAgentInfoBuilder {
        this.travelAgentInfo.defaultCurrency = currency;

        return this;
    }

    /**
     * Returns the builded travel agent info object
     * @returns {TravelAgentInfo}
     */
    public build(): TravelAgentInfo {
        return this.travelAgentInfo;
    }

    /**
     * Returns the builded travel agent info object as JSON format
     * @returns {string}
     */
    public buildAsJSON(): string {
        return JSON.stringify(this.travelAgentInfo);
    }

}
