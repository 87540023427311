import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
export abstract class ExpirableStorage {

  protected storageType: 'session' | 'local';

  getItem(key: string): string | null {
    let itemValue = window[`${this.storageType}Storage`].getItem(key);

    if (itemValue === null) {
      return itemValue;
    }

    try {
      itemValue = JSON.parse(itemValue);
      // duck typing
      if (!itemValue.expireDateISO && !itemValue.value) { throw new Error(''); }
    } catch (e) {
      throw new Error(`Not an expirable ${this.storageType}Storage item.`);
    }

    const expireDate = new Date(itemValue.expireDateISO);
    if (expireDate < new Date()) {
      this.removeItem(key);

      return null;
    }

    return itemValue.value;
  }

  removeItem(key: string): void {
    window[`${this.storageType}Storage`].removeItem(key);
  }

  setItem(key: string, value: string, expireDateISO: string): void {
    const expireDate = new Date(expireDateISO);
    if (this.isValidDate(expireDate)) {
      const expirableValue = JSON.stringify({ expireDateISO, value });
      window[`${this.storageType}Storage`].setItem(key, expirableValue);
    } else {
      throw new Error(`${expireDateISO} is an invalid date string`);
    }
  }

  protected isValidDate(d: any) {
    return d instanceof Date && !isNaN(Number(d));
  }

}
