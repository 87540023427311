import { SelectedFilter } from './../interfaces/selected-filter.interface';

/**
 * Add an item ordered by filter name in the array provided
 * @param itemFilterChanged
 * @param array where the item will be added
 * @param data object to retreive filter name by  filter id
 */
const addByFilterName = (itemFilterSelected: SelectedFilter, array: SelectedFilter[], data: {}): void => {
    let added = false;

    if (array.length === 0) {
        array.push(itemFilterSelected);
        added = true;
    } else {
        const changedName = data[itemFilterSelected.id].name;

        for (let i = 0; i < array.length; i++) {
            const iteratorName = data[array[i].id].name;

            if ( changedName < iteratorName) {
                array.splice(i, 0, itemFilterSelected);
                added = true;
                break;
            }
        }
    }

    if (!added) {
        array.push(itemFilterSelected);
    }
};

/**
 * Add a night item ordered by it corresponding amount of nights in the array provided
 * @param itemFilterChanged
 * @param array where the item will be added
 */
const addByFilterNights = (itemFilterSelected: SelectedFilter, array: SelectedFilter[]): void => {
    let added = false;

    if (array.length === 0) {
        array.push(itemFilterSelected);
        added = true;
    } else {
        const changedNights = Number(itemFilterSelected.id.split(';')[0].split('-')[0]);

        for (let i = 0; i < array.length; i++) {
            const iteratorNights =  Number(array[i].id.split(';')[0].split('-')[0]);

            if ( changedNights < iteratorNights) {
                array.splice(i, 0, itemFilterSelected);
                added = true;
                break;
            }
        }
    }

    if (!added) {
        array.push(itemFilterSelected);
    }
};

export default {
    addByFilterName,
    addByFilterNights
};
