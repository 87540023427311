import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config.service';

/**
 * Ported over from universa-layout
 */

@Injectable({
  providedIn: 'root'
})
export class CdnService {

  constructor(private configService: ConfigService) { }

  getCDNUrl(url) {
    const cdnList = this.configService.config['cdnBaseUrls'];
    const nameMapping = null;

    if (!url) { return ''; }
    if (!cdnList || !cdnList.length) { return url; }
    // do not process full URLs
    if (/^https?:\/\//.test(url)) { return url; }

    url = this.stripLeadingSlash(url);
    const cdnIndex = (url.length) % (cdnList.length);
    const cdnPrefix = this.stripTrailingSlash(cdnList[cdnIndex]);

    // apply mapping
    const mappingFor = nameMapping || {};
    const mappedUrl = mappingFor[url] || url;

    url = cdnPrefix + '/' + mappedUrl;

    return url;
  }

  stripTrailingSlash(url) {
      if (url.charAt(url.length - 1) === '/') {
          url = url.substr(0, url.length - 1);
      }

      return url;
  }

  stripLeadingSlash(url) {
      if (url.charAt(0) === '/') {
          url = url.substr(1);
      }

      return url;
  }

}
