import { Injectable } from '@angular/core';
import { ExpirableStorage } from './expirable-storage.abstract';

@Injectable({
  providedIn: 'root'
})
export class ExpirableSessionStorageService extends ExpirableStorage {
  constructor() {
    super();
    this.storageType = 'session';
  }
}
