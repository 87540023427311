import { Injectable } from '@angular/core';

function _document() {
    return document;
}

@Injectable()
export class DocumentRef {
    get nativeDocument() {
        return _document();
    }
}
