/**
 * Note we need a separate function as it's required by the AOT compiler.
 *
 * @wiki https://confluence.disney.com/pages/viewpage.action?pageId=867890211
 * @see https://github.com/ngx-lottie/ngx-lottie#usage
 * @returns
 */
import player from 'lottie-web';

export function playerFactory() {
    return player;
}
