import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgModule } from '@angular/core';

import { TravelAgentService } from './travel-agent.service';
import { TravelAgentRestService } from './rest/travel-agent-rest.service';
import { TASessionTimeOutService } from './session-timeout/session-timeout.service';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        CookieService,
        TravelAgentService,
        TravelAgentRestService,
        TASessionTimeOutService
    ]
})
export class TravelAgentModule { }
