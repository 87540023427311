import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BaymaxHeaderComponent } from './baymax-header.component';
import { StickyBarModule } from '../sticky-bar/sticky-bar.module';

@NgModule({
    imports: [
        CommonModule,
        StickyBarModule,
        TranslateModule
    ],
    declarations: [
        BaymaxHeaderComponent
    ],
    exports: [
        BaymaxHeaderComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class BaymaxHeaderModule { }
