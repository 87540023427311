import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from '@app/services/locale/locale.service';
import { ConfigService } from '@core/config.service';
import interpolate from '@app/shared/utils/string/interpolate';

@Injectable({
  providedIn: 'root'
})
export class ParkHoursService {
  private url = '/wdpr-web-dcl-homepage/park-hours/${brand}/${locale}/${date}';
  private locale = 'en-us';

  constructor(
    private httpClient: HttpClient,
    private localeService: LocaleService,
    private configService: ConfigService,
  ) {
  }

  private addZeroPad(value) {
    return value.toString().padStart(2, '0');
  }

  getParkHours(): Promise<any> {
    this.localeService.getLocale().then((locale: string) => {
        this.locale = locale;
    });
    const brand = this.configService.getValue('siteId');
    const today = new Date();
    const date = today.getFullYear() + '-' + this.addZeroPad(today.getMonth() + 1) + '-' + this.addZeroPad(today.getDate());
    const parkHoursUrl = interpolate(this.url, {brand, locale: this.locale, date});

    const promise = new Promise((resolve, reject) => { 
      this.httpClient.get<any>(parkHoursUrl).toPromise().then((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });

    return promise;
  }
}
