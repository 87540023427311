import { Injectable } from '@angular/core';
import { WindowRef } from '../window-ref/window-ref.service';

@Injectable()
export class URLService {
    constructor(private windowRef: WindowRef) { }

    getUrlParams(): URLSearchParams {
        return new URLSearchParams(this.windowRef.nativeWindow.location.href);
    }
}
