<div id="homepage-top">
  <div id="quick-quote-container">
    <wdpr-lodging-quickquote [config]="utilsConfig"
      [analyticsTrackingEvent]="analyticsTrackingEvent"></wdpr-lodging-quickquote>
  </div>
  <pixie-hero
    [id]="id"
    [title]="title"
    [ctas]="ctas"
    [class]="siteId"
  ></pixie-hero>
  <park-hours [analyticsTrackingEvent]="analyticsTrackingEvent"></park-hours>
  <div class="stories-rounded-spacer"></div>
  <pixie-florida-affiliation></pixie-florida-affiliation>
  <pixie-fast-florida-affiliation></pixie-fast-florida-affiliation>
</div>
<pixie-stories appDeepLink [analyticsTrackingEvent]="analyticsTrackingEvent"></pixie-stories>
