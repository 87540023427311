import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ADA2AccessibleRoomComponent } from './components/ADA2-accessible-room/ADA2-accessible-room.component';
import { StateroomComponent } from './components/stateroom/stateroom.component';
import { TravelPartyComponent } from './travel-party.component';
import { URLService } from '../url/url.service';
import { WarningMessagingModule } from './../warning-messaging/warning-messaging.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        WarningMessagingModule
    ],
    declarations: [
        ADA2AccessibleRoomComponent,
        StateroomComponent,
        TravelPartyComponent
    ],
    providers: [
        URLService
    ],
    exports: [
        TravelPartyComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class TravelPartyModule { }
