export const HANDLE_ERRORS_CONSTANTS = {
    CHARACTER_ERRORS: [
        400,
        401,
        403,
        404,
        500,
        503
    ],
    DEFAULT_ERROR_STATE: 500
};
