<ng-container *ngIf="showComponent">
  <div id="florida-targeted-affiliation">
    <ng-container
      *ngIf="(isFloridaResidentProfile || isFloridaResidentAffl) && !hasClickedButton && !isConfirmedFlResident">
      <div>
        <span class="targeted-affiliation-group">
          <span class="targeted-affiliation-icon">
            <img class="icon" aria-hidden="true" [src]="iconUrl" />
          </span>
          <span id="flres" class="targeted-affiliation-text">
            <p>{{targetAffiliationContent.text}}</p>
          </span>
        </span>
        <span class="targeted-affiliation-group">
          <div role="group" aria-labelledby="flres">
            <ng-container *ngFor="let button of confirmationButtons | keyvalue">
              <span class="targeted-affiliation-button">
                <button (click)="onButtonClick(button.key)"
                  class="snowball-button snowball-button-theme-white color-sky-blue last" target="_self"
                  name="&amp;lid=targeted_affil_module_button-{{button.value}}">
                  {{button.value}}
                </button>
              </span>
            </ng-container>
          </div>
        </span>
      </div>
    </ng-container>
    <p id="confirmationText" [class.faded-text]="!isConfirmedFlResident">
      {{targetAffiliationContent.confirmationText}}
    </p>
  </div>
</ng-container>
