import { animate, keyframes, style, transition, trigger } from '@angular/animations';

const spinnerExitOutAnimation = trigger('spinnerExitOut', [
    transition(':leave', [
        animate('250ms', keyframes([
            style({
                transform: 'scale(1.2)',
                opacity: 1,
                offset: 0
            }),
            style({
                transform: 'scale(1)',
                opacity: 1,
                offset: 0.15
            }),
            style({
                transform: 'scale(0.2)',
                opacity: 0,
                offset: 1
            })
        ]))
    ])
]);

export { spinnerExitOutAnimation };
