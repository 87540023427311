import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AffiliationsService } from '@app/services/affiliations/affiliations.service';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@core/config.service';
import { Link } from '@core/interfaces/link';
import { get } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';
import getLangFromOrigin from '@app/shared/utils/string/getLangFromOrigin';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'pixie-fast-florida-affiliation',
  templateUrl: './fast-florida-affiliation.component.html',
  styleUrls: ['./fast-florida-affiliation.component.scss']
})

export class FastFloridaAffiliationComponent implements OnInit {
  affiliationsJar = 'AFFILIATIONS_jar';
  profileAffiliations;
  siteId;
  universalContent;
  isFloridaResidentProfile: boolean = false;
  isFloridaResidentAffl: boolean = false;
  hasClickedButton: boolean = false;
  targetAffiliationContent = {
    buttons: { 'FL_RESIDENT': 'Yes', 'STD_GST': 'No'},
    buttonPress: () => {},
    text: "Are you a Florida Resident?",
    icon: "icon"
  };
  environment;
  iconUrl;
  confirmationButtons = [];
  authToken: string;

  constructor(
    private affiliationsService: AffiliationsService,
    private analyticsService: HomepageAnalyticsService,
    private resourceBundleService: ResourceBundleService,
    private cookieService: CookieService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    const SWID = this.cookieService.get('SWID');
    const localStorageAffiliations = localStorage.getItem('AFFILIATIONS_jar') ? JSON.parse(localStorage.getItem('AFFILIATIONS_jar')[this.affiliationsJar]) : false;
    const sessionAffiliations = window.sessionStorage[this.affiliationsJar];
    if (!sessionAffiliations && localStorageAffiliations) {
      window.sessionStorage[this.affiliationsJar] = localStorageAffiliations;
    }
    const siteId = this.configService.getValue('siteId');
    this.environment = this.configService.getValue('environment');
    const iconUrlPrefix = this.environment === 'prod' ? '' : 'stage.';
    this.iconUrl = `https://${iconUrlPrefix}cdn1.parksmedia.wdprapps.disney.com/media/layout/icons/pep/florida-resident.svg`;
    this.resourceBundleService.get('universalContent').then(content => {
      if (content) {
        this.universalContent = get(content, 'universalContent.targetAffiliations');
        this.targetAffiliationContent = get(content, 'universalContent.targetAffiliations.module', {});
        const confirmationButtonObject = get(content, 'universalContent.targetAffiliations.module.buttons', {});
        this.confirmationButtons = Object.values(confirmationButtonObject);
      } else {
        console.log('Error fetching fl universal content');
      }
    }, (e) => console.error('Error in ngOnInit Fl Affiliation:  ', e));


    //sample swid with FL res 22D1475A-4F84-4BFB-8D86-219B494CF5A2
    //sample swid with no FL res 3D6179F4-6E78-42E3-8C6B-B0903EDB0FE4
    // const SWID = '3D6179F4-6E78-42E3-8C6B-B0903EDB0FE4';
    // Check if user has FL_RESIDENT in session storage first
    if (sessionAffiliations) {
      const affiliations = JSON.parse(sessionAffiliations);
      const storedAffiliations = affiliations[siteId]?.storedAffiliations || [];

      // If FL_RESIDENT is found in session storage, update flags and stop further processing
      if (storedAffiliations.indexOf('FL_RESIDENT') > -1) {
        this.isFloridaResidentProfile = true;
        this.isFloridaResidentAffl = true;
        this.hasClickedButton = true;
        console.log('FL_RESIDENT found in session storage, skipping further checks.');

        return; // Exit early to avoid re-rendering or unnecessary API calls
      }
    }

    // If not in session storage, continue with API call or other checks
    try {
      const langFromOrigin = getLangFromOrigin();
      if (langFromOrigin === 'en-eu' || langFromOrigin === 'en-gb') {
        this.isFloridaResidentProfile = false;
        this.isFloridaResidentAffl = false;

        return;
      }

      // For logged in users check their profile affiliations for FL_RES
      if (SWID) {
        document.addEventListener('navigation', async(event: any) => {
          if (event.detail.event === 'oneId-init' &&
              !!window.PEP?.PROFILE_AUTH
          ) {
            window.PEP.PROFILE_AUTH.getGuest()
              .then(async guestObj => {
                this.authToken = guestObj.token.access_token;
                const headers = new HttpHeaders({
                  'Authorization': `Bearer ${this.authToken}`,
                  'Content-Type': 'application/json'
                });
                try {
                  const profileAffiliations = await this.httpClient.get<ProfileAffiliation>(`/features/gam/${SWID}`, { headers }).toPromise();
                  const geographicAffiliations = profileAffiliations?.affiliations?.filter((aff) => {
                    return aff.category === 'GEOGRAPHIC' && aff.subType === 'FL_RESIDENT';
                  });
                  // If FL_RESIDENT is found in the profile, update flags and session storage
                  if (geographicAffiliations.length > 0) {
                    this.isFloridaResidentProfile = true;
                    this.updateSessionStorage('FL_RESIDENT', siteId);
                  }
                } catch(error) {
                  console.error('Error getting profile affiliations: ', error);
                }
              });
          }

        });
      }

      // Check other affiliation sources as needed
      const affiliation = await this.affiliationsService.getAffiliations();
      this.isFloridaResidentAffl = affiliation[siteId]?.storedAffiliations.indexOf('FL_RESIDENT') > -1;

      // Further session and cookie checks
      const geoCookie = this.cookieService.get('GEOLOCATION_jar');
      let geolocationCookie = geoCookie ? JSON.parse(geoCookie) : {};
      if (!SWID && geolocationCookie['region'] === 'florida') {
        this.isFloridaResidentProfile = true;
        this.isFloridaResidentAffl = true;
      }

      console.log('Targeted Affiliation Log End of Init: ', this.isFloridaResidentProfile, this.isFloridaResidentAffl, this.hasClickedButton);
    } catch (error) {
      console.error('Error during ngOnInit florida affiliation check', error);
    }
    this.cdr.detectChanges();
  }

  // Helper function to update session storage
  updateSessionStorage(affiliationType: string, siteId: string): void {
    const sessionAffiliations = window.sessionStorage[this.affiliationsJar] || '{}';
    let affiliations = JSON.parse(sessionAffiliations);

    if (!affiliations[siteId]) {
      affiliations[siteId] = { storedAffiliations: [] };
    }

    if (affiliations[siteId].storedAffiliations.indexOf(affiliationType) === -1) {
      affiliations[siteId].storedAffiliations.push(affiliationType);
    }

    // Convert affiliations to a string format to store in sessionStorage and localStorage
    const affiliationsString = JSON.stringify(affiliations);
    // Update session storage
    window.sessionStorage[this.affiliationsJar] = affiliationsString;
    // Update localStorage
    window.localStorage[this.affiliationsJar] = affiliationsString;
  }


  onButtonClick(val) {
    const sessionAffiliations = window.sessionStorage[this.affiliationsJar] || '{}';
    let affiliations = JSON.parse(sessionAffiliations);
    const siteId = this.configService.getValue('siteId');

    // Ensure there's an entry for the siteId in affiliations
    if (!affiliations[siteId]) {
      affiliations[siteId] = { storedAffiliations: [] };
    }

    // Based on the user's input (yes/no), update affiliations and flags
    if (val === 'yes') {
      this.addFloridaResidentAffiliation(affiliations, siteId);
    } else if (val === 'no') {
      this.removeFloridaResidentAffiliation(affiliations, siteId);
    }

    // Convert affiliations to a string format to store in sessionStorage and localStorage
    const affiliationsString = JSON.stringify(affiliations);
    // Update session storage
    window.sessionStorage[this.affiliationsJar] = affiliationsString;
    // Update localStorage
    window.localStorage[this.affiliationsJar] = affiliationsString;

    // Update flags
    this.isFloridaResidentProfile = (val === 'yes');
    this.isFloridaResidentAffl = (val === 'yes');
    this.hasClickedButton = true;
  }

  // Helper function to add FL_RESIDENT affiliation
  addFloridaResidentAffiliation(affiliations: any, siteId: string): void {
    if (affiliations[siteId].storedAffiliations.indexOf('FL_RESIDENT') === -1) {
      affiliations[siteId].storedAffiliations.push('FL_RESIDENT');
    }
  }

  // Helper function to remove FL_RESIDENT affiliation
  removeFloridaResidentAffiliation(affiliations: any, siteId: string): void {
    const index = affiliations[siteId].storedAffiliations.indexOf('FL_RESIDENT');
    if (index > -1) {
      affiliations[siteId].storedAffiliations.splice(index, 1);
    }
    window.location.reload();
  }
}
interface ProfileAffiliation {
  affiliations: GeographicAffiliation[],
  links: Link
}
interface GeographicAffiliation {
  category: string;
  subType: string;
}
declare const window: any;
// Global PEP Object
declare let PEP: any;
