import { Component, OnInit, Renderer2, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'script-loader',
    template: ''
})
export class ScriptLoaderComponent implements OnInit {
    @Input() src: string;

    constructor(
        private renderer: Renderer2,
        private element: ElementRef
    ) { }

    ngOnInit() {
        if (this.src) {
            this.loadScript(this.src);
        }
    }

    loadScript(src: string): void {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        this.renderer.appendChild(this.element.nativeElement, script);
    }

}
