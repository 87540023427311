import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { VisibleMqService } from '@app/services/visible-mq/visible-mq.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pixie-story-link-item',
  templateUrl: './story-link-item.component.html',
  styleUrls: ['./story-link-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoryLinkItemComponent implements OnInit {

  @Input() linkItem: StoryLinkItemType = {
    type: '',
  };
  renderImage: boolean;

  constructor(private mqService: VisibleMqService) { }

  ngOnInit() {
    if (this.linkItem.type === 'image') {
        if (this.linkItem.classes.match(/(^|\s)(visible|hidden)-\S*/g)) {
        this.mqService.currentMediaChanged$.pipe(debounceTime(50)).subscribe(currMedia => this.mqHandler(currMedia.className));
        this.mqHandler(this.mqService.currentMedia.className);
      } else {
        this.renderImage = true;
      }
    }
  }

  mqHandler(mqClassName) {
    this.renderImage = this.linkItem.classes.indexOf(mqClassName) > -1;
  }
}

interface StoryLinkItemType {
  type: string;
  classes?: string;
}
