import { Component } from '@angular/core';
import { ImageTemplateType, StoryAbstractComponent } from '../shared/story-abstract.component';
import { get } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'pixie-fast-destination',
  templateUrl: '../shared/story.component.html',
  styleUrls: ['./fast-destination.component.scss']
})
export class FastDestinationComponent extends StoryAbstractComponent {
  constructor(sanitizer: DomSanitizer, cookieService: CookieService) {
    super(sanitizer, cookieService);
  }
  getTemplateBlockGroups(storyData) {
    const storyDataDescriptions = get(storyData, 'descriptions', {});
    const numberOfBlocks = Object.keys(storyDataDescriptions).length;

    const component = document.getElementsByTagName('pixie-fast-destination')[0];
    if (component) {
        component["style"].setProperty("--hero-background-color", storyData.descriptions.heroCard.sections.titleColor);
        component["style"].setProperty("--story-block-1-background-color", storyData.descriptions.squareCard1.sections.titleColor);
        component["style"].setProperty("--story-block-2-background-color", storyData.descriptions.squareCard2.sections.titleColor);
        component["style"].setProperty("--story-block-3-background-color", storyData.descriptions.squareCard3.sections.titleColor);
    }

    const blockGroup1 = {
        'classes': 'full-desktop',
        'anchorName': 'fast-destination-hero-block-group-1-anchor',
        'blocks': []
    };
    const blockGroup2 = {
        'classes': 'full-desktop',
        'anchorName': 'fast-destination-hero-block-group-2-anchor',
        'blocks': [],
    };
    // 4-destination contains [hero, 1, 2, 3]
    const is4Destination = numberOfBlocks === 4;
    const lastIndex = is4Destination ? numberOfBlocks - 1 : numberOfBlocks;
    const firstIndex = is4Destination ? 0 : 1;
    for (let i = lastIndex; i >= firstIndex; i--) {
        // keys defined at wdpr-web-homepage - story-main-container.directive.js
        let key = ''; // heroCard, squareCard1, squareCard2
        let classes: string;
        let blockToAdd;
        let backgroundImageTemplates: ImageTemplateType[];
        let data;

        if (is4Destination && i === 0) {
            key = 'heroCard';
            classes = 'full-desktop hero-card ' + this.getCSSSectionClasses(get(storyData, 'descriptions.heroCard', false));
            data =  get(storyData, `descriptions.${key}`, {});
            backgroundImageTemplates = [
                this.getImageTemplate(
                    data.media.backgroundDesktop,
                    'background ' +
                        'hidden-xs hidden-sm visible-md visible-lg'
                ),
                this.getImageTemplate(
                    data.media.backgroundMobile,
                    'background visible-xs visible-sm hidden-md hidden-lg'
                ),
            ];
        } else {
            key = `squareCard${i}`;
            const classFraction = numberOfBlocks === 5 && i < 3 ? 'half' : 'one-third';
            classes = `${classFraction}-desktop story-block-${i} story-block-row `
                + this.getCSSSectionClasses(get(storyData, `descriptions.${key}`));
            data =  get(storyData, `descriptions.${key}`, {});
            backgroundImageTemplates = [
                this.getImageTemplate(
                    data.media.backgroundDesktop,
                    'background hidden-xs visible-sm hidden-md hidden-lg'
                ),
                this.getImageTemplate(
                    data.media.backgroundMobile,
                    'background ' +
                        'visible-xs hidden-sm visible-md visible-lg'
                )
            ];
        }

        blockToAdd = {
            'analyticsTrackSeen' : this.createAnalyticsTrackSeen(storyData, i,
                data.analyticsLinkId),
            'classes': classes,
            'items': data.media.cinemagraphMedia1 ? [
                    this.getPixiePlayerTemplate(data)
                ] : [
                {
                    'type': 'link',
                    'classes': '',
                    'href': data.link,
                    'target': get(data, 'target', '_self'),
                    'analyticsLinkId': this.getStoryAnalyticslinkId(storyData,
                        i,
                        data.analyticsLinkId),
                    itemsWrapped: {
                        background: backgroundImageTemplates,
                        foreground: [
                            this.getTitleTemplate(data),
                        ]
                    }
                }
            ]
        };

        if (blockGroup2.blocks.length < 3) {
            blockGroup2.blocks.unshift(blockToAdd);
        } else {
            blockGroup1.blocks.unshift(blockToAdd);
        }
    }

    return [blockGroup1, blockGroup2];
  }
}
